<veolia-modal #validateSolutionModal>
  <div veolia-modal-title>
    <h4 nz-typography>
      {{ 'Solution validation review for ' | translate }}
      <strong>{{ solution?.solutionName }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ solution?.uid! }}</em></span
      >
    </h4>
  </div>
  <div veolia-modal-content>
    <div>
      <div *ngIf="solution?.type === 'SaaS'">
        <h3 translate>
          {{ 'Solution/Request informations' | translate }}
        </h3>
        <nz-card
          nzTitle="{{ 'Main questions' | translate }}"
          nzType="inner"
          class="spacing-v16">
          <p>
            A. The solution is provided (developed) by the Vendor:
            <strong>{{ solution?.Q0_A }}</strong>
          </p>
          <p>
            B. The solution is to be hosted in Veolia Public Cloud (Amazon Web
            Services or Google Cloud Platform):
            <strong>{{ solution?.Q0_B }}</strong>
          </p>
          <p>
            C. The solution offers a mobile app, a chrome extension or a Google
            add-on:
            <strong>{{ solution?.Q0_C }}</strong>
          </p>
        </nz-card>
        <nz-card
          nzTitle="{{ 'Primary informations' | translate }}"
          nzType="inner"
          class="spacing-v16">
          <p>
            {{ "Initiator's full name" | translate }}:
            <strong>
              {{ solution?.fullNameInitiatorVeolia }}
            </strong>
          </p>
          <p>
            {{ "Initiator's email" | translate }}:
            <strong>
              {{ solution?.emailInitiatorVeolia }}
            </strong>
          </p>
          <p>
            {{ 'Solution owner' | translate }}:
            <strong>{{ solution?.solutionOwner }}</strong>
          </p>
          <p>
            {{ 'Business Unit' | translate }}:
            <strong>
              {{ solution?.businessUnitOfSolution }}
            </strong>
          </p>
        </nz-card>
        <nz-card
          nzType="inner"
          nzTitle="{{ 'Informations about the request' | translate }}"
          class="spacing-v16">
          <p *ngIf="solution?.requestDate">
            {{ 'Request date' | translate }}:
            <strong *ngIf="solution?.requestDate?.toDate">
              {{ solution?.requestDate?.toDate() | date: 'longDate' }}
            </strong>
            <strong *ngIf="!solution?.requestDate?.toDate">
              {{ solution?.requestDate }}
            </strong>
          </p>
          <p>
            {{ 'Global status of request/solution' | translate }}:
            <strong>{{ solution?.status }}</strong>
          </p>
        </nz-card>
        <nz-card nzTitle="{{ 'Main details' | translate }}" class="spacing-v16">
          <p>
            {{ 'Solution type' | translate }}:
            <strong>{{ solution?.type }}</strong>
          </p>
          <p>
            {{ 'RFP/Project' | translate }}:
            <strong>{{ solution?.rfpName }}</strong>
          </p>
          <p>
            {{
              'What domain / business capability does the solution depend on ?'
                | translate
            }}
            :
            <strong>
              {{ solution?.domainOfSolution }}
            </strong>
          </p>
          <p>
            {{
              'Can you describe the need ? What is the purpose of the solution ?'
                | translate
            }}:
            <strong>
              {{ solution?.description }}
            </strong>
          </p>
          <p>
            {{ 'Who will access/use the solution ?' | translate }}:
            <strong>{{ solution?.accessQuestion }}</strong>
          </p>
          <p>
            {{
              'Will the solution have to integrate within Google Workspace? (Drive, GMail, Doc, Sheet...) ?'
                | translate
            }}:
            <strong>
              {{ solution?.integrateQuestion }}
            </strong>
          </p>
          <p>
            {{
              'Does your solution offer (directly or indirectly) Generative AI features ?'
                | translate
            }}:
            <strong>
              {{ solution?.genaiQuestion }}
            </strong>
          </p>
          <p>
            {{
              'Do you know if the solution will have to interconnect with 3rd party applications ?'
                | translate
            }}:
            <strong>
              {{ solution?.interconnectQuestion }}
            </strong>
          </p>
          <p>
            {{
              'Please give more details about interconnection with 3rd party applications'
                | translate
            }}:
            <strong>
              {{ solution?.interconnectQuestion2 }}
            </strong>
          </p>
          <p>
            {{
              'Select the following statements if they apply to your need'
                | translate
            }}:
            <strong>
              {{ solution?.communicationQuestion }}
            </strong>
          </p>
          <p>
            {{
              'Expected date of completion of documents (completion by vendor)'
                | translate
            }}:
            <strong *ngIf="solution?.expectedCompletionDate?.toDate">
              {{
                solution?.expectedCompletionDate?.toDate() | date: 'longDate'
              }}
            </strong>
            <strong *ngIf="!solution?.expectedCompletionDate?.toDate">
              {{ solution?.expectedCompletionDate }}
            </strong>
          </p>
          <p>
            {{
              'Expected evaluation date (Please allow 3 weeks once the documents are completed)'
                | translate
            }}:
            <strong *ngIf="solution?.expectedEvaluationDate?.toDate">
              {{
                solution?.expectedEvaluationDate?.toDate() | date: 'longDate'
              }}
            </strong>
            <strong *ngIf="!solution?.expectedEvaluationDate?.toDate">
              {{ solution?.expectedEvaluationDate }}
            </strong>
          </p>
          <p>
            {{ 'Expected date of on-line release' | translate }}:
            <strong *ngIf="solution?.expectedOnlineReleaseDate?.toDate">
              {{
                solution?.expectedOnlineReleaseDate?.toDate() | date: 'longDate'
              }}
            </strong>
            <strong *ngIf="!solution?.expectedOnlineReleaseDate?.toDate">
              {{ solution?.expectedOnlineReleaseDate }}
            </strong>
          </p>
          <p>
            {{
              'Number of solution to assess in the scope of RFP' | translate
            }}:
            <strong>{{ solution?.nbSolution }}</strong>
          </p>
        </nz-card>
        <nz-card nzTitle="{{ 'The solution' | translate }}" class="spacing-v16">
          <p>
            {{ 'Name of the solution to be evaluated' | translate }}:
            <strong>{{ solution?.solutionName }}</strong>
          </p>
          <p>
            {{ 'Vendor brand name' | translate }}:
            <strong>{{ solution?.editorBrand }}</strong>
          </p>
          <p>
            {{ 'Solution is already in use / DLA renewal ?' | translate }}:
            <strong>{{ solution?.inPlace }}</strong>
          </p>
          <p>
            {{
              'If the solution is already in use, please provide its APM number (if available)'
                | translate
            }}:
            <strong>
              {{ solution?.apmNumber }}
            </strong>
          </p>
          <p>
            {{ 'Vendor website URL (SaaS solution)' | translate }}:
            <a
              [href]="solution?.link"
              alt="Commercial website"
              target="_blank"
              >{{ solution?.link }}</a
            >
          </p>
        </nz-card>
        <nz-card nzTitle="Main contact" class="spacing-v16">
          <p>
            {{ 'Vendor email' | translate }}:
            <strong>{{ solution?.mainEmailVendor }}</strong>
          </p>
          <p>
            {{ 'Vendor lastname' | translate }}:
            <strong>
              {{ solution?.mainLastnameVendor }}
            </strong>
          </p>
          <p>
            {{ 'Vendor firstname' | translate }}:
            <strong>
              {{ solution?.mainFirstnameVendor }}
            </strong>
          </p>
          <p>
            {{ 'Vendor company' | translate }}:
            <strong>
              {{ solution?.mainCompanyVendor }}
            </strong>
          </p>
          <p>
            {{ 'Vendor website URL (SaaS solution)' | translate }}:
            <a
              [href]="solution?.linkCom"
              alt="Commercial website"
              target="_blank"
              >{{ solution?.linkCom }}</a
            >
          </p>
        </nz-card>
      </div>

      <div class="row row-center">
        <button
          type="submit"
          nz-button
          nzType="default"
          (click)="approveRequest()">
          Approve request
        </button>
        <button
          type="submit"
          nz-button
          nzType="default"
          (click)="launchCancelRequestAction()">
          Abort request
        </button>
      </div>
    </div>
  </div>
</veolia-modal>

<veolia-modal #cancelSolModal>
  <div veolia-modal-title>
    <h4 nz-typography>
      {{ 'Cancel the solution ' | translate }}
      <strong>{{ solution?.solutionName }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ solution?.uid! }}</em></span
      >
    </h4>
  </div>
  <div veolia-modal-content>
    <form
      nz-form
      [nzLayout]="'vertical'"
      [formGroup]="cancelSolutionForm!"
      (ngSubmit)="submitCancelSolutionForm()">
      <div class="form">
        <nz-form-item>
          <nz-form-label nzRequired>Comment</nz-form-label>
          <nz-form-control
            nzErrorTip="Please add a comment to cancel the solution">
            <input
              nz-input
              class="light-gray"
              formControlName="cancellationComment" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div>
        <div class="row row-center">
          <button
            [disabled]="!cancelSolutionForm.valid"
            type="submit"
            nz-button
            nzType="primary">
            Abort request
          </button>
        </div>
      </div>
    </form>
  </div>
</veolia-modal>