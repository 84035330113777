import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { Log, DatedLogs } from 'src/app/core/models/log';
import { LogService } from 'src/app/core/services/log.service';
import { formatStrDate } from 'src/app/core/utils/date';
import { parse, compareDesc } from 'date-fns';

@Component({
  selector: 'app-form-logs',
  templateUrl: './form-logs.component.html',
  styleUrls: ['./form-logs.component.scss'],
})
export class FormLogsComponent implements OnInit, OnChanges {
  @Input() actionName = '';
  @Input() formName = '';
  @Input() solutionId = '';
  isLoading = false;
  isLoaded = false;
  logs: DatedLogs[] = [];

  constructor(private logService: LogService) {}

  ngOnInit(): void {
    this.fetchLogs(this.solutionId, this.formName, this.actionName);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formName'].firstChange !== true) {
      this.logs = [];
      this.isLoaded = false;
      this.fetchLogs(this.solutionId, this.formName, this.actionName);
    }
  }

  fetchLogs(solutionId: string, formName: string, actionName: string) {
    this.isLoading = true;
    this.logService
      .getFormLogs(solutionId, formName, actionName)
      .then(data => {
        const sortedDates = Object.keys(data as string[]).sort((a, b) => {
          return compareDesc(
            parse(a, 'dd-MM-yyyy', new Date()),
            parse(b, 'dd-MM-yyyy', new Date())
          );
        });

        sortedDates.forEach(sortedDate => {
          const sortedDateKeys = Object.keys(
            (data as Record<string, Record<string, Log>>)[sortedDate]
          );
          const actionLogs: Log[] = [];
          sortedDateKeys.forEach(sortedDateKey => {
            actionLogs.push(
              (data as Record<string, Record<string, Log>>)[sortedDate][
                sortedDateKey
              ]
            );
          });
          this.logs.push({
            date: sortedDate,
            logs: actionLogs,
          });
        });
        this.isLoading = false;
        this.isLoaded = true;
      })
      .catch(() => {
        this.logs = [];
        this.isLoading = false;
        this.isLoaded = true;
      });
  }

  formatDate(date: string | Timestamp) {
    return formatStrDate(date.toString());
  }

  removeRadioSuffix(input: string): string {
    return input.replace(/_RADIO$/, '');
  }
}
