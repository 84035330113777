import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  VeoliaAvatarComponent,
  VeoliaAvatarGroupComponent,
} from '@veolia.com/vds-angular-components/avatar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { CommonModule } from '@angular/common';
import { Auth } from '@angular/fire/auth';
import {
  DocumentPresenceService,
  UserPresence,
} from './document-presence.service';

export interface PresentUser extends UserPresence {
  color: string;
  initials: string;
}

@Component({
  selector: 'app-document-presence',
  templateUrl: './document-presence.component.html',
  styleUrls: ['./document-presence.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NzDropDownModule,
    NzToolTipModule,
    NzAvatarModule,
    VeoliaAvatarGroupComponent,
    VeoliaAvatarComponent,
  ],
})
export class DocumentPresenceComponent implements OnChanges, OnDestroy, OnInit {
  @Input() documentId?: string;
  @Input() formName?: string;

  presentUsers: PresentUser[] = [];
  twoFirstPresent: PresentUser[] = [];
  remainingPresent: PresentUser[] = [];

  constructor(
    private auth: Auth,
    private presence: DocumentPresenceService
  ) {}

  ngOnDestroy(): void {
    if (this.formName) {
      this.presence.deletePresence(this.formName);
    }
  }

  ngOnInit(): void {
    this.presence.presentUsers$.subscribe(value => {
      const presentUsers = Object.values(value)
        .map(e => ({
          ...e,
          initials: this.getInitials(e.displayName),
        }))
        .filter(el => el.userId !== this.auth.currentUser?.uid);
      this.presentUsers = [...presentUsers];
      this.twoFirstPresent = [...presentUsers.slice(0, 2)];
      this.remainingPresent = [...presentUsers.slice(2)];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formName'].currentValue !== undefined) {
      this.presence.unlistenPresence();
      this.presence.initializeDocumentPresence(this.formName, this.documentId);
      this.presence.listenPresence();

      if (
        changes['formName'].previousValue !== undefined &&
        changes['formName'].previousValue !== this.formName
      ) {
        this.presence.deletePresence(changes['formName'].previousValue);
      }
    }
  }

  private getInitials(name: string): string {
    return name
      .split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase();
  }
}
