import { Timestamp } from '@angular/fire/firestore';

function formatDate(date: Timestamp | string) {
  if (typeof date === 'string' || date === undefined || date === null) {
    return date;
  }

  const day = date.toDate().getDate();
  const month = date.toDate().getMonth() + 1; // Month is zero-based, so adding 1

  return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${date.toDate().getFullYear()}`;
}

function formatStrDate(date: string): string {
  const dateObj = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    month: 'long', // Full month name
    day: 'numeric', // Day of the month
    year: 'numeric', // Full year
    hour: 'numeric', // Hour (12-hour format)
    minute: '2-digit', // Minutes
    hour12: true, // Whether to use 12-hour format (AM/PM)
  };

  // Format the date
  const formattedDateString = dateObj.toLocaleString('en-US', options);
  return formattedDateString;
}

export { formatDate, formatStrDate };
