import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  signal,
} from '@angular/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { FileApi } from 'src/app/core/apis/file.api';
import { FormName } from 'src/app/core/enums/form-name';
import { FileItem } from 'src/app/core/models/file-item';
import { Solution } from 'src/app/core/models/solution';

@Component({
  selector: 'app-file-summary',
  templateUrl: './file-summary.component.html',
  styleUrls: ['./file-summary.component.scss'],
})
export class FileSummaryComponent implements OnInit, OnChanges {
  @Input() isVisible = false;
  @Input() solution?: Solution;
  @Input() formName?: FormName;
  @Input() files?: Record<string, FileItem[]>;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modal') modal!: VeoliaModalComponent;

  fields = signal<string[]>([]);

  opened = 0;

  constructor(
    private fileApi: FileApi,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  ngOnInit(): void {
    if (this.files) {
      this.fields.set(Object.keys(this.files));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files'] && !changes['files'].isFirstChange()) {
      if (this.files) {
        this.fields.set(Object.keys(this.files));
      }
    }
  }

  open() {
    this.modal.open();
  }

  generatePDF() {
    if (this.files && Object.keys(this.files).length > 0) {
      if (this.solution?.uid && this.formName) {
        this.fileApi
          .exportFormFilesPDF(this.solution?.uid, {
            formName: this.formName,
            formNameUrl: this.toCamelCase(this.formName),
          })
          .subscribe({
            next: response => {
              const blob = new Blob([response.body], {
                type: 'application/pdf',
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              let fileName: string | undefined = '';

              if (this.formName === 'Article 28 Form') {
                fileName = 'Additional Forms';
              } else {
                fileName = this.formName;
              }

              link.download = `Files for ${fileName}-${this.solution?.solutionName}.pdf`;
              link.click();
              window.URL.revokeObjectURL(url);
            },
            error: error => {
              console.error('Error downloading PDF:', error);
            },
          });
      }
    } else {
      this.veoliaMessageService.create(
        {
          title: 'Error',
          icon: 'error',
          content: 'Nothing to download',
        },
        {
          duration: 8000,
        }
      );
    }
  }

  downloadAllFiles() {
    if (this.solution?.uid && this.formName) {
      this.fileApi
        .exportAllFilesPDF(this.solution?.uid, this.formName)
        .subscribe({
          next: response => {
            const blob = new Blob([response.body], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            let fileName: string | undefined = '';

            if (this.formName === 'Article 28 Form') {
              fileName = 'Additional Forms';
            } else {
              fileName = this.formName;
            }

            link.download = `Files for ${fileName}-${this.solution?.solutionName}.zip`;
            link.click();
            window.URL.revokeObjectURL(url);
          },
          error: error => {
            console.error('Error downloading PDF:', error);
          },
        });
    }
  }

  toCamelCase(str?: string) {
    if (str === undefined) {
      return '';
    }
    // Remove leading and trailing whitespace and split into words
    const words = str.trim().split(/\s+/);

    // Convert the first word to lowercase
    const firstWord = words[0].toLowerCase();

    // Convert the first letter of subsequent words to uppercase
    const restOfWords = words
      .slice(1)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    // Join all parts
    return firstWord + restOfWords.join('');
  }
}
