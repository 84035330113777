<!--INFO button in all tables + 'about this request' in all forms + logs/id page-->
<veolia-modal #infoModal>
  <div veolia-modal-title>
    <h4 nz-typography>
      {{ 'Solution and forms overview for ' | translate }}
      <strong>{{ solution?.solutionName }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ solution?.uid! }}</em></span
      >
    </h4>
  </div>
  <div veolia-modal-content>
    <veolia-button
      size="default"
      variant="primary"
      htmlType="button"
      (click)="generatePDF()">
      Download PDF
    </veolia-button>
    <div>
      <div class="page-break" style="page-break-inside: avoid">
        <nz-card
          nzType="inner"
          nzTitle="{{ 'User access' | translate }}"
          class="spacing-v16">
          <p>
            Solution Owner: <strong>{{ solution?.solutionOwner! }}</strong>
          </p>
          <nz-divider></nz-divider>
          <p>
            {{ 'Email of Veolia users with access' | translate }}
          </p>
          <p class="row">
            <veolia-chip
              *ngFor="let email of solution?.emailVeoliaAuthorized"
              [mode]="'default'">
              {{ email }}
            </veolia-chip>
          </p>
          <nz-divider></nz-divider>
          <p>
            {{ 'Email of external users with access' | translate }}
          </p>
          <p class="row">
            <veolia-chip
              *ngFor="let email of solution?.emailVendorAuthorized"
              [mode]="'default'">
              {{ email }}
            </veolia-chip>
          </p>
          <nz-divider></nz-divider>
          <p>
            {{ 'Email of evaluators added with access' | translate }}
          </p>
          <p class="row">
            <veolia-chip
              *ngFor="let email of solution?.emailEvaluatorAuthorized"
              [mode]="'default'">
              {{ email }}
            </veolia-chip>
          </p>
          <nz-divider></nz-divider>
          <p>
            {{ 'Email of evaluators BU with access' | translate }} -
            {{ solution?.businessUnitOfSolution }}
          </p>
          <p class="row">
            <veolia-chip
              *ngFor="let email of emailEvaluatorsBu"
              [mode]="'default'">
              {{ email }}
            </veolia-chip>
          </p>
        </nz-card>
      </div>
      <nz-divider></nz-divider>
      <div *ngIf="solution?.type === 'SaaS'">
        <h3 nz-typography translate>{{ 'Forms informations' | translate }}</h3>
        <nz-card
          nzTitle="{{ 'Design form' | translate }}"
          nzType="inner"
          class="spacing-v16">
          <div>
            <p>
              Version json-FR: <strong>{{ designForm?.jsonFR }}</strong>
            </p>
            <p>
              Version json-US: <strong>{{ designForm?.jsonUS }}</strong>
            </p>
            <p>
              {{ 'Form created on' | translate }}:
              <strong *ngIf="!designForm?.createdAt">
                {{ 'No user has yet accessed the form' }}
              </strong>
              <strong *ngIf="designForm?.createdAt">
                {{
                  designForm?.createdAt?.toDate() | date: 'MMMM d, y, h:mm a'
                }}
              </strong>
            </p>
            <p>
              {{ 'Status of the current form' | translate }}:
              <strong *ngIf="!designForm?.designStatus">
                {{ 'The vendor did not begin to complete the form' }}
              </strong>
              <strong
                style="display: inline-flex"
                *ngIf="designForm?.designStatus">
                {{ designForm?.designStatus }}
                <veolia-link
                  title="More details"
                  size="small"
                  icon="zoom_in"
                  (click)="redirectToSummary(solution?.uid)">
                </veolia-link>
              </strong>
            </p>
            <p>
              {{ 'First update of the form' | translate }}:
              <strong *ngIf="!designForm?.firstUpdate">
                {{ 'The vendor did not begin to complete the form' }}
              </strong>
              <strong *ngIf="designForm?.firstUpdate">
                {{
                  designForm?.firstUpdate?.toDate() | date: 'MMMM d, y, h:mm a'
                }}
              </strong>
            </p>
            <p>
              {{ 'First update of the form by' | translate }}:
              <strong>
                {{
                  designForm?.firstUpdateBy ||
                    'The vendor did not begin to complete the form'
                }}
              </strong>
            </p>
            <p>
              {{ 'Last update of the form' | translate }}:
              <strong *ngIf="!designForm?.lastUpdate">
                {{ 'The vendor did not begin to complete the form' }}
              </strong>
              <strong *ngIf="designForm?.lastUpdate">
                {{
                  designForm?.lastUpdate?.toDate() | date: 'MMMM d, y, h:mm a'
                }}
              </strong>
            </p>
            <p>
              {{ 'Last update of the form by' | translate }}:
              <strong>
                {{
                  designForm?.lastUpdateBy ||
                    'The vendor did not begin to complete the form'
                }}
              </strong>
            </p>
          </div>
        </nz-card>
        <nz-card
          nzTitle="{{ 'Legal form' | translate }}"
          nzType="inner"
          class="spacing-v16">
          <div>
            <p>
              Version json-FR: <strong>{{ legalForm?.jsonFR }}</strong>
            </p>
            <p>
              Version json-US: <strong>{{ legalForm?.jsonUS }}</strong>
            </p>
            <p>
              {{ 'Form created on' | translate }}:
              <strong *ngIf="!legalForm?.createdAt">
                {{ 'No user has yet accessed the form' }}
              </strong>
              <strong *ngIf="legalForm?.createdAt">
                {{ legalForm?.createdAt?.toDate() | date: 'MMMM d, y, h:mm a' }}
              </strong>
            </p>
            <p>
              {{ 'Status of the current form' | translate }}:
              <strong *ngIf="!legalForm?.legalStatus">
                {{ 'The vendor did not begin to complete the form' }}
              </strong>
              <strong
                style="display: inline-flex"
                *ngIf="legalForm?.legalStatus">
                {{ legalForm?.legalStatus }}
                <veolia-link
                  title="More details"
                  size="small"
                  icon="zoom_in"
                  (click)="redirectToSummary(solution?.uid)">
                </veolia-link>
              </strong>
            </p>
            <p>
              {{ 'First update of the form' | translate }}:
              <strong *ngIf="!legalForm?.firstUpdate">
                {{ 'The vendor did not begin to complete the form' }}
              </strong>
              <strong *ngIf="legalForm?.firstUpdate">
                {{
                  legalForm?.firstUpdate?.toDate() | date: 'MMMM d, y, h:mm a'
                }}
              </strong>
            </p>
            <p>
              {{ 'First update of the form by' | translate }}:
              <strong>
                {{
                  legalForm?.firstUpdateBy ||
                    'The vendor did not begin to complete the form'
                }}
              </strong>
            </p>
            <p>
              {{ 'Last update of the form' | translate }}:
              <strong *ngIf="!legalForm?.lastUpdate">
                {{ 'The vendor did not begin to complete the form' }}
              </strong>
              <strong *ngIf="legalForm?.lastUpdate">
                {{
                  legalForm?.lastUpdate?.toDate() | date: 'MMMM d, y, h:mm a'
                }}
              </strong>
            </p>
            <p>
              {{ 'Last update of the form by' | translate }}:
              <strong>
                {{
                  legalForm?.lastUpdateBy ||
                    'The vendor did not begin to complete the form'
                }}
              </strong>
            </p>
          </div>
        </nz-card>
        <nz-card
          nzTitle="{{ 'Article 32 form' | translate }}"
          nzType="inner"
          class="spacing-v16">
          <div>
            <p>
              Version json-FR: <strong>{{ article32Form?.jsonFR }}</strong>
            </p>
            <p>
              Version json-US: <strong>{{ article32Form?.jsonUS }}</strong>
            </p>
            <p>
              {{ 'Form created on' | translate }}:
              <strong *ngIf="!article32Form?.createdAt">
                {{ 'No user has yet accessed the form' }}
              </strong>
              <strong *ngIf="article32Form?.createdAt">
                {{
                  article32Form?.createdAt?.toDate() | date: 'MMMM d, y, h:mm a'
                }}
              </strong>
            </p>
            <p>
              {{ 'Status of the current form' | translate }}:
              <strong *ngIf="!article32Form?.article32Status">
                {{ 'The vendor did not begin to complete the form' }}
              </strong>
              <strong
                style="display: inline-flex"
                *ngIf="article32Form?.article32Status">
                {{ article32Form?.article32Status }}
                <veolia-link
                  title="More details"
                  size="small"
                  icon="zoom_in"
                  (click)="redirectToSummary(solution?.uid)">
                </veolia-link>
              </strong>
            </p>
            <p>
              {{ 'First update of the form' | translate }}:
              <strong *ngIf="!article32Form?.firstUpdate">
                {{ 'The vendor did not begin to complete the form' }}
              </strong>
              <strong *ngIf="article32Form?.firstUpdate">
                {{
                  article32Form?.firstUpdate?.toDate()
                    | date: 'MMMM d, y, h:mm a'
                }}
              </strong>
            </p>
            <p>
              {{ 'First update of the form by' | translate }}:
              <strong>
                {{
                  article32Form?.firstUpdateBy ||
                    'The vendor did not begin to complete the form'
                }}
              </strong>
            </p>
            <p>
              {{ 'Last update of the form' | translate }}:
              <strong *ngIf="!article32Form?.lastUpdate">
                {{ 'The vendor did not begin to complete the form' }}
              </strong>
              <strong *ngIf="article32Form?.lastUpdate">
                {{
                  article32Form?.lastUpdate?.toDate()
                    | date: 'MMMM d, y, h:mm a'
                }}
              </strong>
            </p>
            <p>
              {{ 'Last update of the form by' | translate }}:
              <strong>
                {{
                  article32Form?.lastUpdateBy ||
                    'The vendor did not begin to complete the form'
                }}
              </strong>
            </p>
          </div>
        </nz-card>
        <nz-divider></nz-divider>
        <h3 nz-typography translate>
          {{ 'Solution/Request informations' | translate }}
        </h3>
        <nz-card
          nzTitle="{{ 'Primary informations' | translate }}"
          nzType="inner"
          class="spacing-v16">
          <p>
            {{ "Initiator's full name" | translate }}:
            <strong>
              {{ solution?.fullNameInitiatorVeolia }}
            </strong>
          </p>
          <p>
            {{ "Initiator's email" | translate }}:
            <strong>
              {{ solution?.emailInitiatorVeolia }}
            </strong>
          </p>
          <p>
            {{ 'Solution owner' | translate }}:
            <strong>{{ solution?.solutionOwner }}</strong>
          </p>
          <p>
            {{ 'Business Unit' | translate }}:
            <strong>
              {{ solution?.businessUnitOfSolution }}
            </strong>
          </p>
        </nz-card>
        <nz-card
          nzType="inner"
          nzTitle="{{ 'Informations about the request' | translate }}"
          class="spacing-v16">
          <p>
            {{ 'Request date' | translate }}:
            <strong *ngIf="solution?.requestDate?.toDate">
              {{ solution?.requestDate?.toDate() | date: 'longDate' }}
            </strong>
          </p>
          <p>
            {{ 'Last update' | translate }}:
            <strong *ngIf="!solution?.lastUpdate?.toDate">
              {{ 'No forms completed yet' }}
            </strong>
            <strong *ngIf="solution?.lastUpdate?.toDate">
              {{ solution?.lastUpdate!.toDate() | date: 'medium' }}</strong
            >
          </p>
          <p>
            {{ 'Global status of request/solution' | translate }}:
            <strong>{{ solution?.status }}</strong>
          </p>
          <p>
            {{ 'Solution fully evaluated ?' | translate }}:
            <strong *ngIf="solution?.fullyEvaluated === false">No</strong>
            <strong *ngIf="solution?.fullyEvaluated === true"
              >Yes on
              {{
                solution?.finalEvaluationDate?.toDate() | date: 'medium'
              }}</strong
            >
          </p>
          <p>
            {{ 'Solution canceled ?' | translate }}:
            <strong *ngIf="!solution?.cancellationDate">No</strong>
            <strong *ngIf="solution?.cancellationDate"
              >Yes on
              {{ solution?.cancellationDate?.toDate() | date: 'medium' }} with
              comment: {{ solution?.cancellationComment }}</strong
            >
          </p>
          <p>
            {{ 'Solution has been restored after cancellation ?' | translate }}:
            <strong *ngIf="!solution?.restoreDate">No</strong>
            <strong *ngIf="solution?.restoreDate"
              >Yes on
              {{ solution?.restoreDate?.toDate() | date: 'medium' }} with
              comment: {{ solution?.restoreComment }}</strong
            >
          </p>
        </nz-card>
        <nz-card nzTitle="{{ 'Main details' | translate }}" class="spacing-v16">
          <p>
            {{ 'Solution type' | translate }}:
            <strong>{{ solution?.type }}</strong>
          </p>
          <p>
            {{ 'RFP/Project' | translate }}:
            <strong>{{ solution?.rfpName }}</strong>
          </p>
          <p>
            {{
              'What domain / business capability does the solution depend on ?'
                | translate
            }}
            :
            <strong>
              {{ solution?.domainOfSolution }}
            </strong>
          </p>
          <p>
            {{
              'Can you describe the need ? What is the purpose of the solution ?'
                | translate
            }}:
            <strong>
              {{ solution?.description }}
            </strong>
          </p>
          <p>
            {{ 'Who will access/use the solution ?' | translate }}:
            <strong>{{ solution?.accessQuestion }}</strong>
          </p>
          <p>
            {{
              'Will the solution have to integrate within Google Workspace? (Drive, GMail, Doc, Sheet...) ?'
                | translate
            }}:
            <strong>
              {{ solution?.integrateQuestion }}
            </strong>
          </p>
          <p>
            {{
              'Does your solution offer (directly or indirectly) Generative AI features ?'
                | translate
            }}:
            <strong>
              {{ solution?.genaiQuestion }}
            </strong>
          </p>
          <p>
            {{
              'Do you know if the solution will have to interconnect with 3rd party applications ?'
                | translate
            }}:
            <strong>
              {{ solution?.interconnectQuestion }}
            </strong>
          </p>
          <p>
            {{
              'Please give more details about interconnection with 3rd party applications'
                | translate
            }}:
            <strong>
              {{ solution?.interconnectQuestion2 }}
            </strong>
          </p>
          <p>
            {{
              'Select the following statements if they apply to your need'
                | translate
            }}:
            <strong>
              {{ solution?.communicationQuestion }}
            </strong>
          </p>
          <p>
            {{
              'Expected date of completion of documents (completion by vendor)'
                | translate
            }}:
            <strong *ngIf="solution?.expectedCompletionDate?.toDate">
              {{
                solution?.expectedCompletionDate?.toDate() | date: 'longDate'
              }}
            </strong>
            <strong *ngIf="!solution?.expectedCompletionDate?.toDate">
              {{ solution?.expectedCompletionDate }}
            </strong>
          </p>
          <p>
            {{
              'Expected evaluation date (Please allow 3 weeks once the documents are completed)'
                | translate
            }}:
            <strong *ngIf="solution?.expectedEvaluationDate?.toDate">
              {{
                solution?.expectedEvaluationDate?.toDate() | date: 'longDate'
              }}
            </strong>
            <strong *ngIf="!solution?.expectedEvaluationDate?.toDate">
              {{ solution?.expectedEvaluationDate }}
            </strong>
          </p>
          <p>
            {{ 'Expected date of on-line release' | translate }}:
            <strong *ngIf="solution?.expectedOnlineReleaseDate?.toDate">
              {{
                solution?.expectedOnlineReleaseDate?.toDate() | date: 'longDate'
              }}
            </strong>
            <strong *ngIf="!solution?.expectedOnlineReleaseDate?.toDate">
              {{ solution?.expectedOnlineReleaseDate }}
            </strong>
          </p>
          <p>
            {{
              'Number of solution to assess in the scope of RFP' | translate
            }}:
            <strong>{{ solution?.nbSolution }}</strong>
          </p>
        </nz-card>
        <nz-card nzTitle="{{ 'The solution' | translate }}" class="spacing-v16">
          <p>
            {{ 'Name of the solution to be evaluated' | translate }}:
            <strong>{{ solution?.solutionName }}</strong>
          </p>
          <p>
            {{ 'Vendor brand name' | translate }}:
            <strong>{{ solution?.editorBrand }}</strong>
          </p>
          <p>
            {{ 'Solution is already in use / DLA renewal ?' | translate }}:
            <strong>{{ solution?.inPlace }}</strong>
          </p>
          <p>
            {{
              'If the solution is already in use, please provide its APM number (if available)'
                | translate
            }}:
            <strong>
              {{ solution?.apmNumber }}
            </strong>
          </p>
          <p>
            {{ 'Vendor website URL (SaaS solution)' | translate }}:
            <a
              [href]="solution?.link"
              alt="Commercial website"
              target="_blank"
              >{{ solution?.link }}</a
            >
          </p>
        </nz-card>
        <nz-card nzTitle="Main contact" class="spacing-v16">
          <p>
            {{ 'Vendor email' | translate }}:
            <strong>{{ solution?.mainEmailVendor }}</strong>
          </p>
          <p>
            {{ 'Vendor lastname' | translate }}:
            <strong>
              {{ solution?.mainLastnameVendor }}
            </strong>
          </p>
          <p>
            {{ 'Vendor firstname' | translate }}:
            <strong>
              {{ solution?.mainFirstnameVendor }}
            </strong>
          </p>
          <p>
            {{ 'Vendor company' | translate }}:
            <strong>
              {{ solution?.mainCompanyVendor }}
            </strong>
          </p>
          <p>
            {{ 'Vendor website URL (of the commercial website)' | translate }}:
            <a
              [href]="solution?.linkCom"
              alt="Commercial website"
              target="_blank"
              >{{ solution?.linkCom }}</a
            >
          </p>
        </nz-card>
      </div>
    </div>
  </div>
</veolia-modal>

<!--'about this request' in summary/id page-->
<veolia-modal #infoModal2>
  <div veolia-modal-title>
    <h4 nz-typography>
      {{ 'Solution overview for ' | translate }}
      <strong>{{ solution?.solutionName }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ solution?.uid! }}</em></span
      >
    </h4>
  </div>
  <div veolia-modal-content>
    <veolia-button
      size="default"
      variant="primary"
      htmlType="button"
      (click)="generatePDF()">
      Download PDF
    </veolia-button>
    <div class="page-break" style="page-break-inside: avoid">
      <nz-card
        nzType="inner"
        nzTitle="{{ 'User access' | translate }}"
        class="spacing-v16">
        <p>
          Solution Owner: <strong>{{ solution?.solutionOwner! }}</strong>
        </p>
        <nz-divider></nz-divider>
        <p>
          {{ 'Email of Veolia users with access' | translate }}
        </p>
        <p class="row">
          <veolia-chip
            *ngFor="let email of solution?.emailVeoliaAuthorized"
            [mode]="'default'">
            {{ email }}
          </veolia-chip>
        </p>
        <nz-divider></nz-divider>
        <p>
          {{ 'Email of external users with access' | translate }}
        </p>
        <p class="row">
          <veolia-chip
            *ngFor="let email of solution?.emailVendorAuthorized"
            [mode]="'default'">
            {{ email }}
          </veolia-chip>
        </p>
        <nz-divider></nz-divider>
        <p>
          {{ 'Email of evaluators added with access' | translate }}
        </p>
        <p class="row">
          <veolia-chip
            *ngFor="let email of solution?.emailEvaluatorAuthorized"
            [mode]="'default'">
            {{ email }}
          </veolia-chip>
        </p>
        <nz-divider></nz-divider>
        <p>
          {{ 'Email of evaluators BU with access' | translate }}
        </p>
        <p class="row">
          <veolia-chip
            *ngFor="let email of emailEvaluatorsBu"
            [mode]="'default'">
            {{ email }}
          </veolia-chip>
        </p>
      </nz-card>
    </div>
    <nz-divider></nz-divider>
    <div *ngIf="solution?.type === 'SaaS'">
      <h3 nz-typography translate>
        {{ 'Solution/Request informations' | translate }}
      </h3>
      <nz-card
        nzTitle="{{ 'Primary informations' | translate }}"
        nzType="inner"
        class="spacing-v16">
        <p>
          {{ "Initiator's full name" | translate }}:
          <strong>
            {{ solution?.fullNameInitiatorVeolia }}
          </strong>
        </p>
        <p>
          {{ "Initiator's email" | translate }}:
          <strong>
            {{ solution?.emailInitiatorVeolia }}
          </strong>
        </p>
        <p>
          {{ 'Solution owner' | translate }}:
          <strong>{{ solution?.solutionOwner }}</strong>
        </p>
        <p>
          {{ 'Business Unit' | translate }}:
          <strong>
            {{ solution?.businessUnitOfSolution }}
          </strong>
        </p>
      </nz-card>
      <nz-card
        nzType="inner"
        nzTitle="{{ 'Informations about the request' | translate }}"
        class="spacing-v16">
        <p>
          {{ 'Request date' | translate }}:
          <strong *ngIf="solution?.requestDate?.toDate">
            {{ solution?.requestDate?.toDate() | date: 'longDate' }}
          </strong>
        </p>
        <p>
          {{ 'Last update' | translate }}:
          <strong *ngIf="!solution?.lastUpdate?.toDate">
            {{ 'No forms completed yet' }}
          </strong>
          <strong *ngIf="solution?.lastUpdate?.toDate">
            {{ solution?.lastUpdate!.toDate() | date: 'medium' }}</strong
          >
        </p>
        <p>
          {{ 'Global status of request/solution' | translate }}:
          <strong>{{ solution?.status }}</strong>
        </p>
        <p>
          {{ 'Solution fully evaluated ?' | translate }}:
          <strong *ngIf="solution?.fullyEvaluated === false">No</strong>
          <strong *ngIf="solution?.fullyEvaluated === true"
            >Yes on
            {{
              solution?.finalEvaluationDate?.toDate() | date: 'medium'
            }}</strong
          >
        </p>
        <p>
          {{ 'Solution canceled ?' | translate }}:
          <strong *ngIf="!solution?.cancellationDate">No</strong>
          <strong *ngIf="solution?.cancellationDate"
            >Yes on
            {{ solution?.cancellationDate?.toDate() | date: 'medium' }} with
            comment: {{ solution?.cancellationComment }}</strong
          >
        </p>
        <p>
          {{ 'Solution has been restored after cancellation ?' | translate }}:
          <strong *ngIf="!solution?.restoreDate">No</strong>
          <strong *ngIf="solution?.restoreDate"
            >Yes on {{ solution?.restoreDate?.toDate() | date: 'medium' }} with
            comment: {{ solution?.restoreComment }}</strong
          >
        </p>
      </nz-card>
      <nz-card nzTitle="{{ 'Main details' | translate }}" class="spacing-v16">
        <p>
          {{ 'Solution type' | translate }}:
          <strong>{{ solution?.type }}</strong>
        </p>
        <p>
          {{ 'RFP/Project' | translate }}:
          <strong>{{ solution?.rfpName }}</strong>
        </p>
        <p>
          {{
            'What domain / business capability does the solution depend on ?'
              | translate
          }}
          :
          <strong>
            {{ solution?.domainOfSolution }}
          </strong>
        </p>
        <p>
          {{
            'Can you describe the need ? What is the purpose of the solution ?'
              | translate
          }}:
          <strong>
            {{ solution?.description }}
          </strong>
        </p>
        <p>
          {{ 'Who will access/use the solution ?' | translate }}:
          <strong>{{ solution?.accessQuestion }}</strong>
        </p>
        <p>
          {{
            'Will the solution have to integrate within Google Workspace? (Drive, GMail, Doc, Sheet...) ?'
              | translate
          }}:
          <strong>
            {{ solution?.integrateQuestion }}
          </strong>
        </p>
        <p>
          {{
            'Does your solution offer (directly or indirectly) Generative AI features ?'
              | translate
          }}:
          <strong>
            {{ solution?.genaiQuestion }}
          </strong>
        </p>
        <p>
          {{
            'Do you know if the solution will have to interconnect with 3rd party applications ?'
              | translate
          }}:
          <strong>
            {{ solution?.interconnectQuestion }}
          </strong>
        </p>
        <p>
          {{
            'Please give more details about interconnection with 3rd party applications'
              | translate
          }}:
          <strong>
            {{ solution?.interconnectQuestion2 }}
          </strong>
        </p>
        <p>
          {{
            'Select the following statements if they apply to your need'
              | translate
          }}:
          <strong>
            {{ solution?.communicationQuestion }}
          </strong>
        </p>
        <p>
          {{
            'Expected date of completion of documents (completion by vendor)'
              | translate
          }}:
          <strong *ngIf="solution?.expectedCompletionDate?.toDate">
            {{ solution?.expectedCompletionDate?.toDate() | date: 'longDate' }}
          </strong>
          <strong *ngIf="!solution?.expectedCompletionDate?.toDate">
            {{ solution?.expectedCompletionDate }}
          </strong>
        </p>
        <p>
          {{
            'Expected evaluation date (Please allow 3 weeks once the documents are completed)'
              | translate
          }}:
          <strong *ngIf="solution?.expectedEvaluationDate?.toDate">
            {{ solution?.expectedEvaluationDate?.toDate() | date: 'longDate' }}
          </strong>
          <strong *ngIf="!solution?.expectedEvaluationDate?.toDate">
            {{ solution?.expectedEvaluationDate }}
          </strong>
        </p>
        <p>
          {{ 'Expected date of on-line release' | translate }}:
          <strong *ngIf="solution?.expectedOnlineReleaseDate?.toDate">
            {{
              solution?.expectedOnlineReleaseDate?.toDate() | date: 'longDate'
            }}
          </strong>
          <strong *ngIf="!solution?.expectedOnlineReleaseDate?.toDate">
            {{ solution?.expectedOnlineReleaseDate }}
          </strong>
        </p>
        <p>
          {{ 'Number of solution to assess in the scope of RFP' | translate }}:
          <strong>{{ solution?.nbSolution }}</strong>
        </p>
      </nz-card>
      <nz-card nzTitle="{{ 'The solution' | translate }}" class="spacing-v16">
        <p>
          {{ 'Name of the solution to be evaluated' | translate }}:
          <strong>{{ solution?.solutionName }}</strong>
        </p>
        <p>
          {{ 'Vendor brand name' | translate }}:
          <strong>{{ solution?.editorBrand }}</strong>
        </p>
        <p>
          {{ 'Solution is already in use / DLA renewal ?' | translate }}:
          <strong>{{ solution?.inPlace }}</strong>
        </p>
        <p>
          {{
            'If the solution is already in use, please provide its APM number (if available)'
              | translate
          }}:
          <strong>
            {{ solution?.apmNumber }}
          </strong>
        </p>
        <p>
          {{ 'Vendor website URL (SaaS solution)' | translate }}:
          <a [href]="solution?.link" alt="Commercial website" target="_blank">{{
            solution?.link
          }}</a>
        </p>
      </nz-card>
      <nz-card nzTitle="Main contact" class="spacing-v16">
        <p>
          {{ 'Vendor email' | translate }}:
          <strong>{{ solution?.mainEmailVendor }}</strong>
        </p>
        <p>
          {{ 'Vendor lastname' | translate }}:
          <strong>
            {{ solution?.mainLastnameVendor }}
          </strong>
        </p>
        <p>
          {{ 'Vendor firstname' | translate }}:
          <strong>
            {{ solution?.mainFirstnameVendor }}
          </strong>
        </p>
        <p>
          {{ 'Vendor company' | translate }}:
          <strong>
            {{ solution?.mainCompanyVendor }}
          </strong>
        </p>
        <p>
          {{ 'Vendor website URL (of the commercial website)' | translate }}:
          <a
            [href]="solution?.linkCom"
            alt="Commercial website"
            target="_blank"
            >{{ solution?.linkCom }}</a
          >
        </p>
      </nz-card>
    </div>
  </div>
</veolia-modal>
