import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { Timestamp, serverTimestamp } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { VeoliaModalService } from '@veolia.com/vds-angular-components/modal';
import {
  VeoliaTableColumnComponent,
  VeoliaTableSortOrder,
} from '@veolia.com/vds-angular-components/table';
import {
  NzTableQueryParams,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { SecurityScoreCardApi } from 'src/app/core/apis/security-score-card.api';
import { Solution } from 'src/app/core/models/solution';
import { AuthService } from 'src/app/core/services/auth.service';
import { SecurityScoreCardService } from 'src/app/core/services/security-score-card.service';
import { SolutionService } from 'src/app/core/services/solution.service';
import { getTagColor } from 'src/app/core/utils/colors';
import { formatDate } from 'src/app/core/utils/date';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { tapResponse } from '@ngrx/component-store';
import { HttpErrorResponse } from '@angular/common/http';
import SolutionApi from 'src/app/core/apis/solution.api';

@Component({
  selector: 'app-solutions-table',
  templateUrl: './solutions-table.component.html',
  styleUrls: ['./solutions-table.component.scss'],
})
export class SolutionsTableComponent extends DataTableComponent {
  @Input() solutions: Solution[] = [];
  @Input() solutionType = 0;
  @Input() showId = true;
  @Input() actions: string[] = [];
  solutionName = '';
  solutionId = '';

  @Output() refresh: EventEmitter<void> = new EventEmitter();

  #service = inject(VeoliaModalService);
  @ViewChild('cancelSolModal') cancelSolModal!: VeoliaModalComponent;
  @ViewChild('restoreSolModal') restoreSolModal!: VeoliaModalComponent;

  cancelSolutionForm: FormGroup<{
    cancellationComment: FormControl<string | null>;
  }> = this.fb.group({
    cancellationComment: ['', [Validators.required]],
  });

  restoreSolutionForm: FormGroup<{
    restoreComment: FormControl<string | null>;
  }> = this.fb.group({
    restoreComment: ['', [Validators.required]],
  });

  constructor(
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    public override solutionService: SolutionService,
    public sscApi: SecurityScoreCardApi,
    public sscService: SecurityScoreCardService,
    public messageService: VeoliaMessageService,
    private fb: FormBuilder,
    private solutionApi: SolutionApi,
    private veoliaMessageService: VeoliaMessageService
  ) {
    super(authService, router, translateService, solutionService);
  }

  getTagColor(status: string) {
    return getTagColor(status);
  }

  getFormattedDate(date: string | Timestamp) {
    return formatDate(date);
  }

  hasAction(action: string) {
    return this.actions.includes(action);
  }

  launchCancelRequestAction(solution: Solution) {
    this.solutionName = solution.solutionName;
    this.solutionId = solution.uid;
    this.cancelSolModal.open();
  }

  submitCancelSolutionForm() {
    if (this.cancelSolutionForm!.valid) {
      this.solutionService
        .cancelSolution(
          this.solutionId,
          this.cancelSolutionForm!.value.cancellationComment!
        )
        .then(() => {
          this.refresh?.emit();

          //Ici envoie de mail au SO + DLA + evaluatorsBU que la demande a été cancel
          this.solutionApi
            .notifySolutionCanceled(this.solutionId)
            .pipe(
              tapResponse(
                res => {
                  console.log('response from backend routes.py', res);
                },
                (err: HttpErrorResponse) => {
                  console.log('error', err);
                }
              )
            )
            .subscribe();
          this.veoliaMessageService.create(
            {
              title: 'Solution canceled !',
              icon: 'disabled_by_default',
              content:
                'Request ' +
                this.solutionName +
                ' has been canceled successfuly',
            },
            {
              duration: 10000,
            }
          );
          this.cancelSolModal.close();
        });
    } else {
      console.log('Form is invalid');
    }
  }

  launchUncancelRequestAction(solution: Solution) {
    this.solutionName = solution.solutionName;
    this.solutionId = solution.uid;
    this.restoreSolModal.open();
  }

  submitRestoreSolutionForm() {
    if (this.restoreSolutionForm!.valid) {
      this.solutionService
        .restoreSolution(
          this.solutionId,
          this.restoreSolutionForm!.value.restoreComment!
        )
        .then(() => {
          this.refresh?.emit();

          //Ici envoie de mail au SO + DLA + evaluatorsBU que la demande a été restaurée
          this.solutionApi
            .notifySolutionRestored(this.solutionId)
            .pipe(
              tapResponse(
                res => {
                  console.log('response from backend routes.py', res);
                },
                (err: HttpErrorResponse) => {
                  console.log('error', err);
                }
              )
            )
            .subscribe();
          this.veoliaMessageService.create(
            {
              title: 'Solution restored !',
              icon: 'restore_page',
              content:
                'Request ' +
                this.solutionName +
                ' has been restored successfuly',
            },
            {
              duration: 10000,
            }
          );
          this.restoreSolModal.close();
        });
    } else {
      console.log('Form is invalid');
    }
  }

  refreshSsc(solution: Solution) {
    this.sscApi.addPortofolio(solution?.uid, solution?.linkCom).subscribe({
      next: () => {
        this.sscApi.getScore(solution?.uid, solution?.linkCom).subscribe({
          next: data => {
            this.sscService
              .get(solution?.uid)
              .then(doc => {
                if (doc.exists()) {
                  this.sscService
                    .update(solution?.uid, {
                      ...data,
                      lastUpdateDate: Timestamp.now(),
                    })
                    .then(() => {
                      this.messageService.create(
                        {
                          title: 'Success',
                          content: 'Security score card refreshed !',
                          icon: 'check',
                        },
                        {
                          duration: 3000,
                        }
                      );
                      this.sscApi
                        .removePortofolio(solution?.uid, solution?.linkCom)
                        .subscribe();
                      this.refresh?.emit();
                    })
                    .catch(err => console.log(err));
                } else {
                  this.sscService
                    .create(
                      {
                        ...data,
                        solutionName: solution?.solutionName,
                        idSolution: solution?.uid,
                        firstAddDate: Timestamp.now(),
                      },
                      solution?.uid
                    )
                    .then(() => {
                      this.messageService.create(
                        {
                          title: 'Success',
                          content: 'Security score card created !',
                          icon: 'check',
                        },
                        {
                          duration: 3000,
                        }
                      );
                      this.sscApi
                        .removePortofolio(solution?.uid, solution?.linkCom)
                        .subscribe();
                      this.refresh?.emit();
                    })
                    .catch(err => console.log(err));
                }
              })
              .catch(error => {
                console.log(error);
              });
          },
          error: () => {
            this.messageService.create(
              {
                title: 'Error',
                content: 'Oops! Can not refresh security score card',
                icon: 'error',
              },
              {
                duration: 3000,
              }
            );
          },
        });
      },
      error: () => {
        this.messageService.create(
          {
            title: 'Error',
            content: 'Oops! Can not add to portofolio security score card',
            icon: 'error',
          },
          {
            duration: 3000,
          }
        );
      },
    });
  }
}
