import { Component, OnInit } from '@angular/core';
import { AdminHomePageStore } from './admin-home-page.store';
import { SolutionService } from '../../../../core/services/solution.service';
import { UserService } from '../../../../core/services/user.service';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { Router } from '@angular/router';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-admin-home-page',
  templateUrl: './admin-home-page.component.html',
  styleUrls: ['./admin-home-page.component.scss'],
  providers: [SolutionService, AdminHomePageStore],
})
export class AdminHomePageComponent implements OnInit {
  vm$ = this.adminHomePageStore.vm$;

  //SaaS
  allSolutionsCounterSaaS?: number = 0;
  allSolutionsAssessedCounterSaaS?: number;
  allSolutionsPendingCounterSaaS?: number;
  allSolutionsNewCounterSaaS?: number;
  allSolutionsInProgressCounterSaaS?: number;
  allSolutionsCanceledCounterSaaS?: number;

  //Users
  allVeoliaUsersCounter?: number;
  allVendorUsersCounter?: number;

  //BusinessUnits
  allBusinessUnitsCounter?: number = 0;

  // charts
  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };
  public solutionPieChartLabels = [
    'Assessed',
    'Pending',
    'New',
    'In progress',
    'Canceled',
  ];

  public userPieChartLabels = ['Vendor', 'Veolia'];

  public buPieChartLabels: string[] = [];

  public solutionPieChartDatasets: { data: number[] }[] = [
    {
      data: [],
    },
  ];
  public userPieChartDatasets: { data: number[] }[] = [
    {
      data: [],
    },
  ];

  public buPieChartDatasets: { data: number[] }[] = [
    {
      data: [],
    },
  ];

  public pieChartLegend = true;
  public pieChartPlugins = [];

  constructor(
    private adminHomePageStore: AdminHomePageStore,
    private router: Router,
    private solutionService: SolutionService,
    private userService: UserService,
    private businessUnitService: BusinessUnitService
  ) {}

  ngOnInit() {
    //SaaS//
    this.solutionService
      .getCountOfAllSolutionsByType('SaaS')
      .then(res => {
        this.allSolutionsCounterSaaS = res;
      })
      .catch(error => {
        this.allSolutionsCounterSaaS = undefined;
        console.log(error);
      });
    this.solutionService
      .getCountOfAllSolutionsAssessedByType('SaaS')
      .then(res => {
        this.allSolutionsAssessedCounterSaaS = res;
        this.updateSolutionsChart(res, 0);
      })
      .catch(error => {
        this.allSolutionsAssessedCounterSaaS = undefined;
        console.log(error);
      });
    this.solutionService
      .getCountOfAllSolutionsPendingByType('SaaS')
      .then(res => {
        this.allSolutionsPendingCounterSaaS = res;
        this.updateSolutionsChart(res, 1);
      })
      .catch(error => {
        this.allSolutionsPendingCounterSaaS = undefined;
        console.log(error);
      });
    this.solutionService
      .getCountOfAllSolutionsNewByType('SaaS')
      .then(res => {
        this.allSolutionsNewCounterSaaS = res;
        this.updateSolutionsChart(res, 2);
      })
      .catch(error => {
        this.allSolutionsNewCounterSaaS = undefined;
        console.log(error);
      });
    this.solutionService
      .getCountOfAllSolutionsInProgressByType('SaaS')
      .then(res => {
        this.allSolutionsInProgressCounterSaaS = res;
        this.updateSolutionsChart(res, 3);
      })
      .catch(error => {
        this.allSolutionsInProgressCounterSaaS = undefined;
        console.log(error);
      });
    this.solutionService
      .getCountOfAllSolutionsCanceledByType('SaaS')
      .then(res => {
        this.allSolutionsCanceledCounterSaaS = res;
        this.updateSolutionsChart(res, 4);
      })
      .catch(error => {
        this.allSolutionsCanceledCounterSaaS = undefined;
        console.log(error);
      });

    //Users
    this.userService
      .getCountOfAllVeoliaUsers()
      .then(res => {
        this.allVeoliaUsersCounter = res;
      })
      .catch(error => {
        this.allVeoliaUsersCounter = undefined;
        console.log(error);
      });
    this.userService
      .getCountOfAllVendorUsers()
      .then(res => {
        this.allVendorUsersCounter = res;
        this.updateUsersChart(res, 0);
      })
      .catch(error => {
        this.allVendorUsersCounter = undefined;
        console.log(error);
      });

    //Business units
    this.businessUnitService
      .getCountOfAllBusinessUnits()
      .then(res => {
        this.allBusinessUnitsCounter = res;
        this.updateUsersChart(res, 1);
      })
      .catch(error => {
        this.allBusinessUnitsCounter = undefined;
        console.log(error);
      });

    this.businessUnitService.getBusinessUnitsolutionCount().then(data => {
      const groupedData = this.groupByCount(
        data.docs,
        'businessUnitOfSolution'
      );
      const top5 = groupedData.sort((a, b) => b.count - a.count).slice(0, 5);
      const others = groupedData.sort((a, b) => b.count - a.count).slice(5);
      const businessUnits: any[] = top5.map(el => el.businessUnit);
      const businessUnitCounts = top5.map(el => el.count);
      const otherCounts = others.reduce((acc, a) => acc + a.count, 0);
      this.buPieChartLabels = [...businessUnits, 'Others'];
      this.buPieChartDatasets[0].data = [...businessUnitCounts, otherCounts];4
    });
  }

  groupByCount(arr: any[], key: string): any[] {
    const grouped = arr.reduce((result, currentValue) => {
      const doc = currentValue.data();
      const groupKey = doc[key];
      result[groupKey] = (result[groupKey] || 0) + 1;
      return result;
    }, {});

    return Object.entries(grouped).map(([businessUnit, count]) => ({
      businessUnit,
      count,
    }));
  }

  updateSolutionsChart(newValue: number, index: number) {
    const newData = [...this.solutionPieChartDatasets[0].data];
    newData[index] = newValue;

    this.solutionPieChartDatasets = [
      {
        data: newData,
      },
    ];
  }

  updateUsersChart(newValue: number, index: number) {
    const newData = [...this.userPieChartDatasets[0].data];
    newData[index] = newValue;

    this.userPieChartDatasets = [
      {
        data: newData,
      },
    ];
  }

  onSolutionTypeChange(type: number) {
    this.adminHomePageStore.setSolutionType(type);
  }

  redirectToAdmin() {
    this.router.navigate(['/admin']);
  }

  redirectToSolutionsAdmin() {
    this.router.navigate(['/admin/solutions']);
  }

  redirectToBusinessUnitsAdmin() {
    this.router.navigate(['/admin/business-units']);
  }

  redirectToUsersAdmin() {
    this.router.navigate(['/admin/users']);
  }

  redirectToPromptsAdmin() {
    this.router.navigate(['/admin/prompts']);
  }
}
