import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PresenceService } from './core/services/presence.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { AuthService } from './core/services/auth.service';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'frontend-v2';
  countdown?: number;
  lastPing?: Date;
  idleState = 'NOT_STARTED';
  showConfirm = false;

  @ViewChild('modal', { static: true }) modal!: VeoliaModalComponent;

  constructor(
    private auth: Auth,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private idle: Idle,
    private presenceService: PresenceService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit() {
    onAuthStateChanged(this.auth, user => {
      if (user) {
        this.initIdle();

        this.presenceService.setPresence();
        this.presenceService.toToggleModal.subscribe(value => {
          if (value) {
            this.modal.open();
          } else {
            this.modal.close();
          }
        });

        this.presenceService.toCloseTab.subscribe(value => {
          if (value) {
            window.location.href = 'https://www.veolia.com';
          }
        });
      } else {
        this.disableIdle();
      }
    });
  }

  initIdle() {
    // set idle parameters
    this.idle.setIdle(60);
    this.idle.setTimeout(3600);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE';
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';
      this.countdown = undefined;
      this.cd.detectChanges();
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      this.authService.SignOut();
    });
    this.idle.onTimeoutWarning.subscribe(seconds => (this.countdown = seconds));

    this.resetIdle();
  }

  setActiveTab() {
    this.presenceService.setTabActive();
  }

  resetIdle() {
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
    this.countdown = undefined;
    this.lastPing = undefined;
  }

  disableIdle() {
    this.idle.stop();
    this.idleState = 'NOT_IDLE';
    this.countdown = undefined;
    this.lastPing = undefined;
  }
}
