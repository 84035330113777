const convertDictToList = (dict: any) => {
  return Object.keys(dict).map(key => {
    return { id: key, ...dict[key] };
  });
};

function getValuesForKey(
  dictList: Record<string, any>[],
  key: string
): string[] {
  return dictList
    .map(dict => dict[key])
    .filter(value => value !== undefined)
    .map(value => String(value))
    .filter(value => value !== 'undefined');
}

const hasAtLeastOneDefinedValue = (
  keys: (string | number | (string | number)[] | undefined)[] | undefined,
  dict: Record<string, any>
): boolean => {
  if (!keys) return false;

  const flattenedKeys = keys.flatMap(key =>
    Array.isArray(key) ? key : key !== undefined ? [key] : []
  );

  return flattenedKeys.some(key => {
    if (key === undefined) return false;

    const value = dict[key];

    if (value === undefined || value === null) {
      return false;
    }

    if (typeof value === 'string') {
      return value.trim() !== '';
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object') {
      return Object.keys(value).length > 0;
    }

    // For numbers, booleans, etc.
    return true;
  });
};

function groupByReduce<T, K extends keyof any>(
  list: T[],
  keyGetter: (item: T) => K
): Record<K, T[]> {
  return list.reduce(
    (map: Record<K, T[]>, item: T) => {
      const key = keyGetter(item);
      if (!map[key]) {
        map[key] = [];
      }
      map[key].push(item);
      return map;
    },
    {} as Record<K, T[]>
  );
}

export {
  convertDictToList,
  getValuesForKey,
  groupByReduce,
  hasAtLeastOneDefinedValue,
};
