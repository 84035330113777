export const yesOrNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const yesOrNoSoonOptions = [
  ...yesOrNoOptions,
  { value: 'Soon', label: 'Soon' },
];

export const yesOrNoUnknowOptions = [
  ...yesOrNoOptions,
  { value: 'Unknown', label: 'Unknown' },
];

export const yesOrNoPlannedOptions = [
  ...yesOrNoOptions,
  { value: 'Planned', label: 'Planned' },
];

export const yesOrNoThisIsPlannedOptions = [
  ...yesOrNoOptions,
  { value: 'Planned', label: 'This is planned' },
];

export const yesOrNoCentralizedPlannedOptions = [
  ...yesOrNoOptions,
  { value: 'No it is centralized', label: 'No it is centralized' },
  { value: 'Planned', label: 'Planned' },
];

export const yesOrNoCentralizedThisPlannedOptions = [
  ...yesOrNoOptions,
  { value: 'Centralized', label: 'No it is centralized' },
  { value: 'Planned', label: 'This is planned' },
];

export const yesOrNoNAOptions = [
  ...yesOrNoOptions,
  { value: 'N/A', label: 'N/A' },
];

export const implementationOptions = [
  { label: 'IDP initiated', value: 'IDP' },
  { label: 'SP initiated', value: 'SP' },
  { label: 'Both', value: 'Both' },
  { label: 'Unknown', value: 'Unknown' },
];

export const reportFormats = [
  { label: 'PDF', value: 'PDF' },
  { label: 'CSV', value: 'CSV' },
  { label: 'RTF', value: 'RTF' },
  { label: 'XML', value: 'XML' },
  { label: 'MS office', value: 'MS office' },
  { label: 'Others', value: 'Others' },
];

export const dataFormats = [
  { label: 'JSON', value: 'JSON' },
  { label: 'XML', value: 'XML' },
];

export const authenticationMethods = [
  { label: 'OAuth 2.0', value: 'OAuth 2.0' },
  { label: 'JWT', value: 'JWT' },
  {
    label: 'Others (Basic Auth, OpenID Connect, SAML v2, TLS)',
    value: 'Others (Basic Auth, OpenID Connect, SAML v2, TLS)',
  },
];

export const authenticationProtocols = [
  { label: 'SAML v2', value: 'SAML v2' },
  { label: 'OpenID Connect', value: 'OpenID Connect' },
];

export const monoOrMultiTenant = [
  {
    value: 'Multi-tenant',
    label: 'The SaaS is multi tenant (same tenant for all customers)',
  },
  {
    value: 'Mono tenant',
    label: 'The SaaS is mono-tenant (a single tenant for each customer)',
  },
  {
    value: 'The SaaS is both mono & multi-tenant',
    label: 'The SaaS is both mono & multi-tenant',
  },
  {
    value: 'The SaaS can be implemented in the 3 previous ways.',
    label: 'The SaaS can be implemented in the 3 previous ways.',
  },
];

export const architecturesAndRegions = [
  {
    value: 'The architecture is mono AZ (same region) by default',
    label: 'The architecture is mono AZ (same region) by default',
  },
  {
    value: 'The architecture is multi AZ (same region) by default',
    label: 'The architecture is multi AZ (same region) by default',
  },
  {
    value: 'The architecture is multi region (all actives) by default',
    label: 'The architecture is multi region (all actives) by default',
  },
  {
    value: 'There is a Disaster Recovery in an other Region by default',
    label:
      'There is a Disaster Recovery in an other Region by default or optionally',
  },
  {
    label:
      'There is a Disaster Recovery in another AZ (same region) by default or optionally',
    value:
      'There is a Disaster Recovery in another AZ (same region) by default or optionally',
  },
  {
    value: 'Architecture may vary depending on the subscribed SLA',
    label: 'Architecture may vary depending on the subscribed SLA',
  },
];

export const plannedMobileOptions = [
  { value: 'With Android mobile App', label: 'With Android mobile App' },
  { value: 'With IOS mobile App', label: 'With IOS mobile App' },
  {
    value: 'With a responsive interface',
    label: 'With a responsive interface',
  },
];

export const complianceOptions = [
  { label: 'SPF', value: 'SPF' },
  { label: 'DKIM', value: 'DKIM' },
  { label: 'DMARC', value: 'DMARC' },
  { label: 'BIMI (optional)', value: 'BIMI (optional)' },
];

export const languageOptions = [
  { label: 'French', value: 'French' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'English', value: 'English' },
  { label: 'German', value: 'German' },
  { label: 'Other', value: 'Other' },
];

export const listArt32 = [
  { label: 'Not implemented', value: 'Not implemented' },
  {
    label: 'Partially implemented or implementedon a minority of the scope',
    value: 'Partially implemented or implementedon a minority of the scope',
  },
  {
    label: 'Implemented on a majority of the scope',
    value: 'Implemented on a majority of the scope',
  },
  {
    label: 'Fully implemented on the whole scope',
    value: 'Fully implemented on the whole scope',
  },
  { label: 'Not relevant', value: 'Not relevant' },
];

export const dlaImplementation = [
  { label: 'DLA implemented', value: 'DLA implemented' },
  { label: 'DLA not implemented', value: 'DLA not implemented' },
];

export const yesOrNoPlanned2Options = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No but planned', value: 'No but planned' },
  { label: 'No not planned', value: 'No not planned' },
];

export const backupFrequencyOptions = [
  { label: 'continuous', value: 'continuous' },
  { label: '<=1h', value: '<=1h' },
  { label: '<=12h', value: '<=12h' },
  { label: 'daily', value: 'daily' },
  { label: 'weekly', value: 'weekly' },
  { label: 'other', value: 'other' },
];

export const backupStoragePlacesOptions = [
  {
    label: 'Backups are stored with production (same AZ)',
    value: 'Backups are stored with production (same AZ)',
  },
  {
    label: 'Backups are stored separately from production (other AZ)',
    value: 'Backups are stored separately from production (other AZ)',
  },
  {
    label: 'Backups are stored separately from production (other DC/Region)',
    value: 'Backups are stored separately from production (other DC/Region)',
  },
];

export const backupStorageEncryptedOptions = [
  {
    label: 'Yes, backups are encrypted',
    value: 'Yes, backups are encrypted',
  },
  {
    label: 'No, backups are not encrypted',
    value: 'No, backups are not encrypted',
  },
  {
    label: 'Backups are partially encrypted',
    value: 'Backups are partially encrypted',
  },
];

export const backupStorageEncryptedLevelOptions = [
  { label: 'AES128', value: 'AES128' },
  { label: 'AES256', value: 'AES256' },
  { label: 'Other', value: 'Other' },
];

export const html5Options = [
  {
    label:
      'a website is accessible to Veolia users, partners (front, backoffice, dashboard...)',
    value:
      'a website is accessible to Veolia users, partners (front, backoffice, dashboard...)',
  },
  {
    label: 'a thick client is available',
    value: 'a thick client is available',
  },
  {
    label: 'a mobile app is available',
    value: 'a mobile app is available',
  },
  {
    label: 'an API is exposed to Veolia',
    value: 'an API is exposed to Veolia',
  },
  {
    label: 'none of the above apply',
    value: 'none of the above apply',
  },
];

export const html5PluginListOptions = [
  { label: 'Flash', value: 'Flash' },
  { label: 'Silverlight', value: 'Silverlight' },
  { label: 'Java (for web browser) ', value: 'Java (for web browser)' },
  { label: 'ActiveX', value: 'ActiveX' },
  { label: 'Other', value: 'Other' },
];

export const browserOptions = [
  { label: 'Chrome', value: 'Chrome' },
  { label: 'Edge', value: 'Edge' },
  { label: 'Firefox', value: 'Firefox' },
  { label: 'Safari', value: 'Safari' },
  {
    label: 'Other Major Modern Browsers',
    value: 'Other Major Modern Browsers',
  },
];

export const osOptions = [
  { label: 'Windows', value: 'Windows' },
  { label: 'MacOS', value: 'MacOS' },
  { label: 'Chrome OS', value: 'Chrome OS' },
  { label: 'Linux', value: 'Linux' },
  { label: 'iOS', value: 'iOS' },
  { label: 'Android', value: 'Android' },
];

export const vpnOptions = [
  { label: 'No VPN is required', value: 'Yes' },
  { label: 'a VPN is required', value: 'No' },
  { label: 'a VPN will soon no longer be required', value: 'Planned' },
];

export const apiCapabilitiesOptions = [
  { label: 'File transfer capability', value: 'File transfer capability' },
  {
    label: 'A web interface with download and/or upload capabilities',
    value: 'A web interface with download and/or upload capabilities;',
  },
  { label: 'REST API', value: 'REST API' },
  { label: 'Other ways', value: 'Other ways' },
];

export const aboutFileTransferOptions = [
  { label: 'SFTP or FTPS is available', value: 'SFTP or FTPS is available' },
  { label: 'CFT is available', value: 'CFT is available' },
  {
    label: 'A thick client is required',
    value: 'A thick client is required',
  },
  {
    label: 'another protocol is available',
    value: 'another protocol is available',
  },
];

export const restConfigOptions = [
  {
    label: 'Our REST API are public (not authenticated)',
    value: 'Our REST API are public (not authenticated)',
  },
  {
    label: 'Our REST API are private (authenticated)',
    value: 'Our REST API are private (authenticated)',
  },
  {
    label: 'Our REST API are for internal use only',
    value: 'Our REST API are for internal use only',
  },
  {
    label: 'Our API are directly reachable from the internet',
    value: 'Our API are directly reachable from the internet',
  },
];

export const crudOptions = [
  { label: 'Create data', value: 'Create data' },
  { label: 'Read data', value: 'Read data' },
  { label: 'Update data', value: 'Update data' },
  { label: 'Delete data', value: 'Delete data' },
  {
    label: "We don't have REST API or there are for internal use only",
    value: "We don't have REST API or there are for internal use only",
  },
];

export const restOptionsAvailability = [
  { value: 'Yes', label: 'All Data & functionalities are exposed' },
  { value: 'No', label: 'Data & functionalities are partially exposed' },
  {
    value: 'Planned',
    label: 'Partial today but a full exposure is in the roadmap',
  },
];

export const veoliaDataRetrievalOptions = [
  { value: 'Yes', label: 'Yes, it is possible at anytime' },
  { value: 'No', label: "No, it's not possible at anytime" },
  {
    value: 'Planned',
    label: "No, it's not possible at anytime but it is planned",
  },
  { value: 'NoStorage', label: "We don't store any Veolia DATA" },
];

export const agnosticProxyOptions = [
  {
    label:
      'Yes, a particular configuration is required if the user is behind a proxy',
    value:
      'Yes, a particular configuration is required if the user is behind a proxy',
  },
  {
    label: 'No particular configuration is required.',
    value: 'No particular configuration is required.',
  },
  {
    label: 'There is no access to Veolia employees',
    value: 'There is no access to Veolia employees',
  },
];

export const userAccessOptions = [
  { label: 'A standard portal', value: 'A standard portal' },
  { label: 'An admin portal', value: 'An admin portal' },
  { label: 'a mobile App', value: 'a mobile App' },
  { label: 'Other', value: 'Other' },
  {
    label: 'There is no access to Veolia employees',
    value: 'There is no access to Veolia employees',
  },
];

export const ssoActivationsLevelOptions = [
  { label: 'Yes, for all roles & access', value: 'Yes' },
  { label: 'No, partially', value: 'No' },
  { label: 'No but it is planned', value: 'Planned' },
  { label: 'There is no SSO authentication', value: 'NoAuth' },
];

export const ssoExclusivityOptions = [
  { label: 'Yes, SSO is exclusive', value: 'Yes' },
  { label: 'No, SSO is not exclusive', value: 'No' },
  { label: 'SSO exclusivity is planned', value: 'Planned' },
];

export const SSO2FAMethodsOptions = [
  { value: 'Authenticator', label: 'Authenticator' },
  { value: 'Vocal', label: 'Vocal' },
  { value: 'SMS', label: 'SMS' },
  { value: 'email', label: 'email' },
  { value: 'Other', label: 'Other' },
];

export const maintenanceFrequencyOptions = [
  { value: 'Daily', label: 'Daily' },
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'When needed', label: 'When needed' },
];

export const maintenanceDowntimesOptions = [
  ...yesOrNoOptions,
  { value: 'Occasionally', label: 'Occasionally' },
];

// CHOICES: 1. Corrective, 2. Preventive, 3. Risk-based , 4. Condition-based, 5. other
export const maintenanceTypesOptions = [
  { value: 'Corrective', label: 'Corrective' },
  { value: 'Preventive', label: 'Preventive' },
  { value: 'Risk-based', label: 'Risk-based' },
  { value: 'Condition-based', label: 'Condition-based' },
  { value: 'Other', label: 'Other' },
];

export const timezonesOptions = [
  { value: 'Follow the sun', label: 'Follow the sun' },
  { value: '0-3 am (CET)', label: '0-3 am (CET)' },
  { value: '3 am-6 am (CET)', label: '3 am-6 am (CET)' },
  { value: '6-9 am (CET)', label: '6-9 am (CET)' },
  { value: '9 am-12 pm (CET)', label: '9 am-12 pm (CET)' },
  { value: '12-15 pm (CET)', label: '12-15 pm (CET)' },
  { value: '15-18 pm (CET)', label: '15-18 pm (CET)' },
  { value: '18-21 pm (CET)', label: '18-21 pm (CET)' },
  {
    value: 'Public holidays are not covered',
    label: 'Public holidays are not covered',
  },
  { value: 'Only Working days', label: 'Only Working days' },
];

// 1. Once a year, 2. Every semester, 3. Every quarter, 4. Every month, 5. Twice a month, 6. Every week, 7. Daily, 8. Never :(
export const upgradesHowManyOptions = [
  { value: 'Once a year', label: 'Once a year' },
  { value: 'Every semester', label: 'Every semester' },
  { value: 'Every quarter', label: 'Every quarter' },
  { value: 'Every month', label: 'Every month' },
  { value: 'Twice a month', label: 'Twice a month' },
  { value: 'Every week', label: 'Every week' },
  { value: 'Daily', label: 'Daily' },
  { value: 'Never', label: 'Never' },
];

export const tlsOptions = [
  { label: 'TLS 1.3', value: 'TLS 1.3' },
  { label: 'TLS 1.2', value: 'TLS 1.2' },
  { label: 'TLS 1.1', value: 'TLS 1.1' },
  { label: 'TLS 1.0', value: 'TLS 1.0' },
  { label: 'SSL v2 or SSL v3', value: 'SSL v2 or SSL v3' },
];

export const cipherOptions = [
  {
    label: 'null encryption (not recommended)',
    value: 'null encryption (not recommended)',
  },
  { label: 'MD5 (obsolete)', value: 'MD5 (obsolete)' },
  { label: 'RC4 (obsolete)', value: 'RC4 (obsolete)' },
  { label: 'DHE (obsolete)', value: 'DHE (obsolete)' },
  { label: 'CBC (obsolete)', value: 'CBC (obsolete)' },
  { label: 'DES3 (obsolete)', value: 'DES3 (obsolete)}' },
  {
    label: 'Only non vulnerable algorithms (recommended)',
    value: 'Only non vulnerable algorithms (recommended)',
  },
];

export const cookieOptions = [
  ...yesOrNoThisIsPlannedOptions,
  { label: 'Some of them are secured', value: 'Some of them are secured' },
  { label: "We don't use cookies", value: "We don't use cookies" },
];

export const securityStorageDescriptionOptions = [
  {
    label: 'Veolia business data (analytics, customers, sales number...)',
    value: 'Veolia business data (analytics, customers, sales number...)',
  },
  {
    label: 'Veolia personal data (name, email, age...)',
    value: 'Veolia personal data (name, email, age...)',
  },
  {
    label: 'There is no Veolia data stored',
    value: 'There is no Veolia data stored',
  },
];

export const storageEncryptionOptions = [
  { label: 'Yes, by default', value: 'Yes, by default' },
  { label: 'Yes, upon request', value: 'Yes, upon request' },
  { label: 'No', value: 'No' },
  { label: 'Not all data are encrypted', value: 'Not all data are encrypted' },
  {
    label: 'No, but data encryption is in our roadmap',
    value: 'Planned',
  },
];
export const securityStorageEncryptionLevels = [
  { label: 'AES128', value: 'AES128' },
  { label: 'AES256', value: 'AES256' },
  {
    label: 'Other, partial encryption...',
    value: 'Other, partial encryption...',
  },
];

export const emailDomainOptions = [
  { label: 'A veolia.com domain', value: 'A veolia.com domain' },
  { label: 'SaaS solution own domain', value: 'SaaS solution own domain' },
  { label: 'both are possible', value: 'both are possible' },
];

export const userTracabilityOptions = [
  {
    label: 'Directly on the website (with admin rights)',
    value: 'Directly on the website (with admin rights)',
  },
  { label: 'upon request', value: 'upon request' },
  { label: 'in another way', value: 'in another way' },
];

export const GWorkspaceQuestionOptions = [
  {
    label: 'Yes, it is mandatory to fully use the solution',
    value: 'Yes, it is mandatory to fully use the solution',
  },
  {
    label: "Yes, but it's an optional feature",
    value: "Yes, but it's an optional feature",
  },
  {
    label: "No, but it's planned in the near future",
    value: "No, but it's planned in the near future",
  },
  { label: 'No, it is not applicable', value: 'No, it is not applicable' },
];

export const GWorkspaceStatementsOptions = [
  { label: 'Admin permission', value: 'Admin permission' },
  {
    label: 'Full Google Drive integration',
    value: 'Full Google Drive integration',
  },
  {
    label: 'Full Google Doc integration',
    value: 'Full Google Doc integration',
  },
  {
    label: 'Full Google Sheet integration',
    value: 'Full Google Sheet integration',
  },
  {
    label: 'Full Google Slides Integration',
    value: 'Full Google Slides Integration',
  },
  {
    label: 'Full Google Calendar integration',
    value: 'Full Google Calendar integration',
  },
  {
    label: 'Full Google Mail integration',
    value: 'Full Google Mail integration',
  },
  {
    label: 'Contact/Directory integration',
    value: 'Contact/Directory integration',
  },
  { label: 'Google Meet integration', value: 'Google Meet integration' },
  { label: 'Google Chat', value: 'Google Chat' },
  { label: 'Google Vault', value: 'Google Vault' },
  { label: 'Other', value: 'Other' },
];

export const GWorkspaceAddonOptions = [
  { label: "Yes, It's mandatory", value: "Yes, It's mandatory" },
  {
    label: "Yes, but it's not mandatory",
    value: "Yes, but it's not mandatory",
  },
  {
    label: 'No add-on or chrome extension',
    value: 'No add-on or chrome extension',
  },
];

export const GWorkspaceAuthAccessLevelHighOptions = [
  {
    label: 'Service account with admin permission',
    value: 'Service account with admin permission',
  },
  {
    label: 'Google Workspace Marketplace admin installation',
    value: 'Google Workspace Marketplace admin installation',
  },
  { label: 'Technical admin account', value: 'Technical admin account' },
  { label: 'Domain Wide Delegation', value: 'Domain Wide Delegation' },
  { label: 'Other', value: 'Other' },
];
