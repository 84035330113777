import { BaseService } from './base.service';
import { BusinessUnit } from '../models/business-unit';
import {
  DocumentData,
  DocumentSnapshot,
  Firestore,
  QuerySnapshot,
  collection,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
  collectionData,
} from '@angular/fire/firestore';
import { Observable, catchError, from, map, switchMap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SolutionService } from './solution.service';
import { Auth } from '@angular/fire/auth';
import { nameof } from '../utils/nameof';
import { Solution } from '../models/solution';
import { Entity } from '../models/bases';

@Injectable()
export class BusinessUnitService extends BaseService<BusinessUnit> {
  override get collectionName(): string {
    return 'businessUnit';
  }

  constructor(
    public override _firestore: Firestore,
    public override _auth: Auth,
    private solutionService: SolutionService
  ) {
    super(_firestore, _auth);
  }

  async getCountBusinessUnits() {
    return (
      await getDocs(query(collection(this._firestore, this.collectionName)))
    ).size;
  }

  getBusinessUnits(lastDoc?: BusinessUnit, pageSize = 10, isForward = true) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          orderBy('nomBu'),
          isForward ? startAfter(lastDoc || 0) : endBefore(lastDoc || 0),
          isForward ? limit(pageSize) : limitToLast(pageSize)
        )
      )
    );
  }

  searchBusinessUnits(uid?: string, search = '') {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          orderBy('nomBu')
        )
      )
    );
  }

  getEvaluatorList(businessUnit: string) {
    return getDocs(
      query(
        collection(this._firestore, this.collectionName),
        where('nomBu', '==', businessUnit)
      )
    );
  }

  override observeAll() {
    return collectionData(
      query(collection(this._firestore, this.collectionName), orderBy('nomBu')),
      {
        idField: nameof<Entity>('uid'),
      }
    ) as Observable<BusinessUnit[]>;
  }

  //Counter
  //Admin home page
  async getCountOfAllBusinessUnits() {
    return (
      await getDocs(query(collection(this._firestore, this.collectionName)))
    ).size;
  }

  //Admin user management
  getBusinessUnitsList() {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          orderBy('nomBu')
        )
      )
    );
  }

  //Evaluator page
  getEvaluatorUserBusinessUnits() {
    // console.log("IN 1")
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('evaluators', 'array-contains', this._auth.currentUser?.email)
        )
      )
    );
  }

  getByName(name: string) {
    return getDocs(
      query(
        collection(this._firestore, this.collectionName),
        where('nomBu', '==', name)
      )
    );
  }

  getBySolutionId(solutionId: string): Observable<DocumentData | null> {
    return from(this.solutionService.get(solutionId)).pipe(
      switchMap((doc: DocumentSnapshot<DocumentData>) => {
        if (!doc.exists()) {
          throw new Error('Document does not exist');
        }
        const solution = doc.data() as Solution;
        return from(this.getByName(solution.businessUnitOfSolution));
      }),
      map((querySnapshot: QuerySnapshot<DocumentData>) => {
        if (querySnapshot.empty) {
          throw new Error('Document does not exist');
        }
        // Assuming there's only one business unit with this name
        return {
          uid: querySnapshot.docs[0].id,
          id: querySnapshot.docs[0].id,
          ...querySnapshot.docs[0].data(),
        };
      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  async getBusinessUnitsolutionCount() {
    return await getDocs(query(collection(this._firestore, 'solutions')));
  }
}
