<veolia-button
  [size]="'default'"
  [variant]="'secondary'"
  [icon]="'flash_on'"
  (click)="modal.open()">
  GenAI settings
</veolia-button>
<veolia-modal #modal>
  <div veolia-modal-title>GenAI settings</div>
  <div veolia-modal-content>
    <div>
      <div class="page-break" style="page-break-inside: avoid">
        <form nz-form [formGroup]="genaiSettingsForm" (ngSubmit)="submitForm()">
          <em>{{ 'llmdefinition' | translate }} </em>
          <nz-card
            nzType="inner"
            nzTitle="{{ 'Gen AI' | translate }}"
            class="spacing-v16">
            <nz-form-item>
              <nz-form-label nzRequired>Enable GenAI feature</nz-form-label>
              <nz-form-control
                nzSpan="12"
                nzErrorTip="Please enabled or disable GenAI">
                <nz-switch formControlName="enabled"></nz-switch>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label>LLM list</nz-form-label>
              <nz-form-control
                nzErrorTip="Please select a Large Language Model">
                <nz-select
                  (nzScrollToBottom)="getGenaiSettingsData()"
                  nzPlaceHolder="Select a LLM"
                  formControlName="currentLLM">
                  <nz-option
                    *ngFor="let llm of llmList"
                    [nzValue]="llm"
                    [nzLabel]="getLLMLabel(llm)">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <p>
              Last update:
              {{ getFormattedDate(this.lastUpdate) || 'no data' }}
            </p>
            <p>
              Last update by:
              {{ this.lastUpdateBy || 'no data' }}
            </p>
          </nz-card>
          <nz-form-item>
            <nz-form-control>
              <button nz-button nzType="primary">Save change</button>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
    </div>
  </div>
</veolia-modal>
