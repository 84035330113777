<app-layout-page-header>
  <veolia-page-header variant="light">
    <div veolia-page-header-title style="display: flex">
      <veolia-icon
        class="backbtn"
        (click)="backtoAdminHome()"
        title="Back to Admin Home"
        [size]="24"
        [name]="'arrow_back'"></veolia-icon>
      Prompts
    </div>
  </veolia-page-header>
</app-layout-page-header>
<div class="layout-body">
  <div class="layout-body-content table_container">
    <div *ngIf="vm$ | async as vm">
      <veolia-segmented
        [options]="[
          { label: 'Design Form', value: 0 },
          { label: 'Legal Form', value: 1 },
          { label: 'Article 32 Form', value: 2 },
          { label: 'Additional Forms', value: 3 }
        ]"
        [variant]="'primary'"
        [selectedIndex]="vm.currentIndex"
        [size]="'default'"
        (handleIndexChange)="handleIndexChange($event)" />
      <div class="right-actions">
        <search-input
          [isSearching]="vm.isSearching"
          (onChange)="onSearch($event)"></search-input>
        <veolia-button
          [size]="'default'"
          [variant]="'secondary'"
          [icon]="'add'"
          (click)="addPrompt()">
          Create a prompt
        </veolia-button>
      </div>
    </div>
    <div class="content" *ngIf="vm$ | async as vm">
      <div
        *ngIf="vm.isLoading"
        nz-row
        [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <div nz-col class="gutter-row" [nzSpan]="6">
          <nz-skeleton [nzActive]="true"></nz-skeleton>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="6">
          <nz-skeleton [nzActive]="true"></nz-skeleton>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="6">
          <nz-skeleton [nzActive]="true"></nz-skeleton>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="6">
          <nz-skeleton [nzActive]="true"></nz-skeleton>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="6">
          <nz-skeleton [nzActive]="true"></nz-skeleton>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="6">
          <nz-skeleton [nzActive]="true"></nz-skeleton>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="6">
          <nz-skeleton [nzActive]="true"></nz-skeleton>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="6">
          <nz-skeleton [nzActive]="true"></nz-skeleton>
        </div>
      </div>
      <div class="group" *ngFor="let group of vm.prompts">
        <h4 nz-typography>{{ group.chapter | translate }}</h4>
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
          <div
            *ngFor="let prompt of group.prompts"
            nz-col
            class="gutter-row"
            [nzSpan]="8">
            <nz-card [nzActions]="[actionSetting, actionEdit, actionEllipsis]">
              <veolia-tag
                [label]="prompt.questionKey"
                [variant]="'light'"
                [color]="'information'" />
              <p>
                <strong>Question:</strong><br />
                {{ prompt.questionKey | translate }}
              </p>
              <p>
                <strong>Prompt:</strong><br />
                {{ prompt.content }}
              </p>
            </nz-card>
            <ng-template #actionSetting>
              <veolia-icon
                [name]="'edit'"
                (click)="editPrompt(prompt)"></veolia-icon>
            </ng-template>
            <ng-template #actionEdit>
              <veolia-icon
                [color]="prompt.isActive ? 'green' : 'grey'"
                [name]="prompt.isActive ? 'toggle_on' : 'toggle_off'"
                (click)="togglePrompt(prompt)"></veolia-icon>
            </ng-template>
            <ng-template #actionEllipsis>
              <veolia-icon
                [name]="'delete'"
                nz-popconfirm
                nzPopconfirmTitle="Are you sure delete this prompt?"
                nzPopconfirmPlacement="bottom"
                (nzOnConfirm)="deletePrompt(prompt)">
              </veolia-icon>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<veolia-modal #modal [closable]="true">
  <div veolia-modal-title>Prompt Form</div>
  <div veolia-modal-subtitle>Create or edit prompt</div>

  <div veolia-modal-content>
    <form
      nz-form
      [nzLayout]="'vertical'"
      [formGroup]="promptForm"
      (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-label>Form name</nz-form-label>
        <nz-form-control nzErrorTip="Please select a form">
          <veolia-select
            formControlName="formName"
            [options]="formNameOptions"
            [placeholder]="'Select a form'"
            [required]="true" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Chapter</nz-form-label>
        <nz-form-control nzErrorTip="Please select a chapter">
          <veolia-select
            formControlName="formChapter"
            [options]="filteredFormChapterOptions"
            [placeholder]="'Select a chapter'"
            [required]="true" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Question key</nz-form-label>
        <nz-form-control nzErrorTip="Please input a question key">
          <veolia-input
            formControlName="questionKey"
            [placeholder]="'Type a question key'"
            [required]="true" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Prompt content</nz-form-label>
        <nz-form-control nzErrorTip="Please input a prompt">
          <veolia-textarea
            formControlName="content"
            [placeholder]="'Type a prompt'"
            [required]="true" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>

  <div veolia-modal-footer>
    <veolia-button (click)="resetForm()" variant="secondary"
      >Cancel</veolia-button
    >
    <veolia-button (click)="submitForm()"> Save </veolia-button>
  </div>
</veolia-modal>
