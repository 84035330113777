import { NgModule } from '@angular/core';
import { FormBlockButtonComponent } from './components/form-block-button/form-block-button.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormBlockComponent } from './components/form-block/form-block.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormNZCheckboxComponent } from './components/form-nz-checkbox/form-nz-checkbox.type';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormNZDatepickerComponent } from './components/form-nz-datepicker/form-nz-datepicker.type';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FormlyFieldInput } from './components/form-nz-input/form-nz-input.type';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FormlyFieldTextArea } from './components/form-nz-textarea/form-nz-textarea.type';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { FormlyFieldSelect } from './components/form-nz-select/form-nz-select.type';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import {
  VeoliaRadioComponent,
  VeoliaRadioGroupComponent,
} from '@veolia.com/vds-angular-components/radio';
import { VeoliaDividerComponent } from '@veolia.com/vds-angular-components/divider';

import { FormChapterComponent } from './components/form-chapter/form-chapter.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormSubChapterComponent } from './components/form-sub-chapter/form-sub-chapter.component';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import { VeoliaLinkComponent } from '@veolia.com/vds-angular-components/link';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { VeoliaSwitchComponent } from '@veolia.com/vds-angular-components/switch';
import {
  VeoliaInputComponent,
  VeoliaTextAreaComponent,
} from '@veolia.com/vds-angular-components/input';
import { VeoliaSelectComponent } from '@veolia.com/vds-angular-components/select';
import { VeoliaAlertComponent } from '@veolia.com/vds-angular-components/alert';
import { FormActionCommentComponent } from './components/form-action-comment/form-action-comment';
import { FormLabelComponent } from './components/form-label/form-label.component';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { FormActionsFilesComponent } from './components/form-actions-files/form-actions-files.component';
import { FormActionStatusComponent } from './components/form-action-status/form-action-status.component';
import { CommentService } from 'src/app/core/services/comment.service';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { DragDirective } from 'src/app/core/directives/drag.directive';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { DropzoneDirective } from 'src/app/core/directives/dropzone.directive';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { FormFileUploadComponent } from './components/form-file-upload/form-file-upload.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { FormStatusTypeComponent } from './components/form-status/form-status-type.component';
import { FormDividerComponent } from './components/form-divider/form-divider.component';
import { ChapterCompletionStatusService } from 'src/app/core/services/chapter-completion-status.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { FormNZTimepickerComponent } from './components/form-nz-timepicker/form-nz-timepicker.component';
import { FormNZRadioGroupComponent } from './components/form-nz-radio-group/form-nz-radio-group.component';
import { FormToolbarComponent } from './components/form-toolbar/form-toolbar.component';
import { EvaluationSummaryModule } from '../evaluation-summary/evaluation-summary.module';
import { CommentSummaryModule } from '../comment-summary/comment-summary.module';
import { FileSummaryModule } from '../file-summary/file-summary.module';
import { SolutionInfoSummaryModule } from '../solution-info-summary/solution-info-summary.module';
import { TruncatePipe } from 'src/app/core/pipes/truncate.pipe';
import { FormAssessmentComponent } from './components/form-assessment/form-assessment.component';
import { FormDisclaimerComponent } from './components/form-disclaimer/form-disclaimer.component';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { FormlyFieldPasswordInputComponent } from './components/form-nz-password/form-nz-password.component';
import { FormAssessmentWrapperComponent } from './components/form-assessment-wrapper/form-assessment-wrapper.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FormWrapperDeprecatedFieldComponent } from './components/form-wrapper-deprecated-field/form-wrapped-deprecated-field.wrapper';
import { SimpleSpinnerModule } from '../simple-spinner/simple-spinner.module';
import { NzCardModule } from 'ng-zorro-antd/card';
import { VeoliaChipComponent } from '@veolia.com/vds-angular-components/chips';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormGenaiComponent } from './components/form-genai/form-genai.component';
import { FormWrapperGenaiSuggestionFieldComponent } from './components/form-genai-suggestion/form-wrapped-genai-suggestion-field.wrapper';

export function minLengthValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `Should have atleast ${field.props?.minLength} characters`;
}

export function maxLengthValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `This value should be less than ${field.props?.maxLength} characters`;
}

export function minValidationMessage(error: any, field: FormlyFieldConfig) {
  return `This value should be more than ${field.props?.min}`;
}

export function maxValidationMessage(error: any, field: FormlyFieldConfig) {
  return `This value should be less than ${field.props?.max}`;
}

@NgModule({
  declarations: [
    FormBlockButtonComponent,
    FormBlockComponent,
    FormNZCheckboxComponent,
    FormNZDatepickerComponent,
    FormlyFieldInput,
    FormlyFieldSelect,
    FormlyFieldTextArea,
    FormChapterComponent,
    FormSubChapterComponent,
    FormActionsComponent,
    FormActionCommentComponent,
    FormLabelComponent,
    FormActionsFilesComponent,
    FormActionStatusComponent,
    DragDirective,
    DropzoneDirective,
    FormFileUploadComponent,
    FormStatusTypeComponent,
    FormDividerComponent,
    FormNZTimepickerComponent,
    FormNZRadioGroupComponent,
    FormToolbarComponent,
    FormDisclaimerComponent,
    FormlyFieldPasswordInputComponent,
    FormAssessmentWrapperComponent,
  ],
  imports: [
    CommonModule,
    CommentSummaryModule,
    EvaluationSummaryModule,
    FileSummaryModule,
    SolutionInfoSummaryModule,
    FormsModule,
    FormlySelectModule,
    NzCardModule,
    NzIconModule,
    VeoliaChipComponent,
    SimpleSpinnerModule,
    FormlyNzFormFieldModule,
    FormlyModule.forChild({
      validationMessages: [
        { name: 'minLength', message: minLengthValidationMessage },
        { name: 'maxLength', message: maxLengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
      ],
      wrappers: [
        { name: 'form-divider', component: FormDividerComponent },
        {
          name: 'form-field-assessment-wrapper',
          component: FormAssessmentWrapperComponent,
        },
        {
          name: 'form-deprecated-field',
          component: FormWrapperDeprecatedFieldComponent,
        },
        {
          name: 'form-genai-suggestion-field',
          component: FormWrapperGenaiSuggestionFieldComponent,
        },
      ],
      types: [
        {
          name: 'nz-checkbox',
          component: FormNZCheckboxComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'nz-datepicker',
          component: FormNZDatepickerComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'nz-timepicker',
          component: FormNZTimepickerComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'nz-input',
          component: FormlyFieldInput,
          wrappers: ['form-field'],
        },
        {
          name: 'nz-select',
          component: FormlyFieldSelect,
          wrappers: ['form-field'],
        },
        {
          name: 'nz-textarea',
          component: FormlyFieldTextArea,
          wrappers: ['form-field'],
        },
        {
          name: 'nz-sub-chapter',
          component: FormSubChapterComponent,
          wrappers: [],
        },
        {
          name: 'nz-status',
          component: FormStatusTypeComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'nz-radiogroup',
          component: FormNZRadioGroupComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'disclaimer',
          component: FormDisclaimerComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'nz-password',
          component: FormlyFieldPasswordInputComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    NzFormModule,
    NzAvatarModule,
    NzButtonModule,
    NzCollapseModule,
    NzCommentModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzDividerModule,
    NzInputModule,
    NzInputNumberModule,
    NzListModule,
    NzUploadModule,
    NzProgressModule,
    NzSelectModule,
    NzTypographyModule,
    NzMessageModule,
    NzModalModule,
    NzSpinModule,
    NzRadioModule,
    NzTimePickerModule,
    VeoliaAlertComponent,
    VeoliaButtonComponent,
    VeoliaDividerComponent,
    VeoliaIconComponent,
    VeoliaInputComponent,
    VeoliaLinkComponent,
    VeoliaRadioComponent,
    VeoliaRadioGroupComponent,
    VeoliaSelectComponent,
    VeoliaSwitchComponent,
    VeoliaTagComponent,
    VeoliaTextAreaComponent,
    ReactiveFormsModule,
    TranslateModule,
    TruncatePipe,
    FormAssessmentComponent,
    FormGenaiComponent,
    VeoliaModalModule,
    NzToolTipModule,
  ],
  exports: [
    FormBlockButtonComponent,
    FormBlockComponent,
    FormChapterComponent,
    FormSubChapterComponent,
    FormActionsComponent,
    FormActionCommentComponent,
    FormLabelComponent,
    FormActionsFilesComponent,
    FormActionStatusComponent,
    FormFileUploadComponent,
    FormDividerComponent,
    FormToolbarComponent,
  ],
  providers: [
    ChapterCompletionStatusService,
    CommentService,
    DragDirective,
    DropzoneDirective,
    NzModalService,
  ],
})
export class FormLayoutModule {}
