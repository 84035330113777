import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';

import { GenAIStoreState } from './states/gen-ai-store.state';
import { map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { GenAIService } from '../services/genai.service';

@Injectable()
export class GenAIStore extends ComponentStore<GenAIStoreState> {
  private formId$ = this.select(state => state.formId);
  private formName$ = this.select(state => state.formName);
  private formGenAIEnabled$ = this.select(state => state.formGenAIEnabled);
  private data$ = this.select(state => state.data);
  private genAIEnabled$ = this.select(state => state.genAIEnabled);

  constructor(private genAIService: GenAIService) {
    super({
      formId: '',
      formName: '',
      formGenAIEnabled: false,
      data: {},
      genAIEnabled: false,
    });
  }

  vm$ = this.select(
    this.formId$,
    this.formName$,
    this.formGenAIEnabled$,
    this.data$,
    this.genAIEnabled$,
    (formId, formName, formGenAIEnabled, data, genAIEnabled) => ({
      formId,
      formName,
      formGenAIEnabled,
      data,
      genAIEnabled,
    })
  );

  setFormId = this.updater((state, formId: string) => ({
    ...state,
    formId,
  }));

  setFormName = this.updater((state, formName: string) => ({
    ...state,
    formName,
  }));

  setFormGenAIEnabled = this.updater((state, formGenAIEnabled: boolean) => ({
    ...state,
    formGenAIEnabled,
  }));

  setData = this.updater((state, data: Record<string, unknown>) => ({
    ...state,
    data,
  }));

  setGenAIEnabled = this.updater((state, genAIEnabled: boolean) => ({
    ...state,
    genAIEnabled,
  }));

  setCurrentFormNameDatabase = this.effect(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ formName }) => {
        this.genAIService.setFormNameDatabase(formName);
        return of(null);
      })
    );
  });

  getGenAISetting = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        return this.genAIService.observeGenAISettings().subscribe(data => {
          if (data) {
            this.setGenAIEnabled(data.enabled);
          }
        });
      })
    );
  });

  getData = this.effect(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ formId }) => {
        return this.genAIService.observeSuggestions(formId).pipe(
          tapResponse(
            data => {
              if (data) {
                this.setData(data);
              }
              return of(null);
            },
            () => {
              return of(null);
            }
          )
        );
      })
    );
  });

  getSuggestions(): Record<string, any> {
    return this.get().data ?? {};
  }
}
