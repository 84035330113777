<veolia-modal #modal>
  <div veolia-modal-title>
    {{ 'Files for' | translate }}
    <strong *ngIf="this.formName === 'Article 28 Form'"
      >Additional Forms - {{ solution?.solutionName }}</strong
    >
    <strong *ngIf="this.formName !== 'Article 28 Form'"
      >{{ formName }} - {{ solution?.solutionName }}</strong
    >
  </div>
  <div veolia-modal-content>
    <div class="row">
      <veolia-button
        size="default"
        variant="primary"
        htmlType="button"
        icon="file_open"
        (click)="generatePDF()">
        List of uploaded files (pdf)
      </veolia-button>
      <veolia-button
        size="default"
        variant="primary"
        htmlType="button"
        icon="download"
        (click)="downloadAllFiles()">
        Download all uploaded files (zip)
      </veolia-button>
    </div>
    <br />
    <nz-collapse nzAccordion>
      <nz-collapse-panel
        *ngFor="let field of fields(); let i = index"
        [nzHeader]="field | translate">
        <p style="margin: 0">
          <nz-list *ngIf="files">
            <nz-list-item *ngFor="let item of files[field]!">
              <app-file-summary-item [file]="item" />
            </nz-list-item>
          </nz-list>
        </p>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-list-empty
      nzNoResult="No files uploaded"
      *ngIf="fields()!.length === 0"></nz-list-empty>
    <nz-list-empty *ngIf="fields()!.length === 0"></nz-list-empty>
  </div>
</veolia-modal>
