import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  Database,
  child,
  get,
  getDatabase,
  onDisconnect,
  onValue,
  ref,
  set,
  update,
} from '@angular/fire/database';
import { Subject } from 'rxjs';
import { UserPresene } from '../models/user-presence';

@Injectable({
  providedIn: 'root',
})
export class PresenceService {
  sessionId: string;

  private closeTab = new Subject<boolean>();
  toCloseTab = this.closeTab.asObservable();
  private toggleModal = new Subject<boolean>();
  toToggleModal = this.toggleModal.asObservable();

  constructor(
    private auth: Auth,
    private db: Database = getDatabase()
  ) {
    this.sessionId =
      sessionStorage.getItem('sessionId') || this.generateSessionId();
    sessionStorage.setItem('sessionId', this.sessionId);
  }

  private generateSessionId(): string {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  async setPresence() {
    const uid = this.auth.currentUser?.uid;

    if (!uid) {
      return;
    }

    const activeTab = (
      await get(child(ref(this.db), `status/${uid}/tab`))
    ).val();

    const connectionRef = ref(this.db, '.info/connected');

    onValue(connectionRef, async snapshot => {
      if (snapshot.val() == false) {
        return;
      }

      await update(ref(this.db, `status/${uid}/tabs/${this.sessionId}`), {
        tabId: this.sessionId,
      });

      if (activeTab !== this.sessionId) {
        await set(ref(this.db, `status/${uid}/tab`), 'confirming');
      }

      this.listenPresence();

      onDisconnect(
        ref(this.db, `status/${uid}/tabs/${this.sessionId}`)
      ).remove();
    });
  }

  private handleMultipleTabs(presence: UserPresene): void {
    if (Object.keys(presence.tabs).length > 1) {
      if (presence.tab === 'confirming') {
        this.toggleModal.next(true);
      } else {
        if (presence.tab !== this.sessionId) {
          this.closeTab.next(true);
        }
        this.toggleModal.next(false);
      }
    }
  }

  listenPresence() {
    const uid = this.auth.currentUser?.uid;

    if (!uid) {
      return;
    }

    const tabsRef = ref(this.db, `status/${uid}`);

    onValue(tabsRef, data => {
      if (data.exists()) {
        const presence = data.val() as unknown as UserPresene;
        if (presence.tabs) {
          this.handleMultipleTabs(presence);
        }
      }
    });
  }

  setTabActive() {
    const uid = this.auth.currentUser?.uid;

    if (!uid) {
      return;
    }

    set(ref(this.db, `status/${uid}/tab`), this.sessionId);
  }
}
