import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { GenaiSettingsComponent } from './components/genai-settings/genai-settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  declarations: [GenaiSettingsComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzCardModule,
    NzFormModule,
    NzModalModule,
    NzSelectModule,
    NzSwitchModule,
    ReactiveFormsModule,
    VeoliaButtonComponent,
    VeoliaModalModule,
    TranslateModule,
  ],
  providers: [],
  exports: [GenaiSettingsComponent],
})
export class GenaiSettingsModule {}
