import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
} from '@ngx-formly/core';
import { FormlyFieldCheckbox } from '@ngx-formly/ng-zorro-antd/checkbox';
import { FormlyFieldProps } from '@ngx-formly/ng-zorro-antd/form-field';

interface CheckboxProps extends FormlyFieldProps {
  indeterminate?: boolean;
}

export interface FormlyCheckboxFieldConfig
  extends FormlyFieldConfig<CheckboxProps> {
  type: 'nz-checkbox' | Type<FormlyFieldCheckbox>;
}

@Component({
  selector: 'app-form-nz-checkbox',
  template: `
    <nz-checkbox-wrapper style="width: 100%;">
      <div
        *ngFor="
          let option of props.options | formlySelectOptions: field | async;
          let i = index
        ">
        <label
          nz-checkbox
          [disabled]="props.disabled!"
          [nzDisabled]="props.disabled!"
          [nzValue]="option.value"
          [nzChecked]="isChecked(option)"
          [formlyAttributes]="field"
          [ngModel]="isChecked(option)"
          (ngModelChange)="onChange(option.value, $event)">
          {{ option.label }}
        </label>
      </div>
    </nz-checkbox-wrapper>
  `,
  styles: [
    `
      .ant-checkbox-wrapper-checked {
        color: var(
          --components-table-body-table-cell-foreground-default
        ) !important;
      }
      .ant-checkbox-disabled + span {
        color: var(
          --components-table-body-table-cell-foreground-default
        ) !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormNZCheckboxComponent extends FieldType<
  FieldTypeConfig<CheckboxProps>
> {
  onChange(value: any, checked: boolean) {
    this.formControl.markAsDirty();
    if (checked) {
      if (
        this.formControl.value !== undefined &&
        this.formControl.value !== null
      ) {
        this.formControl.patchValue([...this.formControl.value, value]);
      } else {
        this.formControl.patchValue([value]);
      }
    } else {
      this.formControl.patchValue(
        this.formControl.value.filter((v: any) => v !== value)
      );
    }
    this.formControl.markAsTouched();
  }

  isChecked(option: any) {
    const value = this.formControl.value;
    return value && value.indexOf(option.value) !== -1;
  }
}
