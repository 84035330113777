<veolia-button
  nzBlock
  [icon]="'circle'"
  [iconFilled]="selected!"
  [class]="'spacing-v8 table-content-button ' + buttonColor"
  [size]="'large'"
  [shape]="'default'"
  [variant]="selected ? 'primary' : 'secondary'"
  [disabled]="!unlocked"
  [htmlType]="'button'">
  <span class="button-text"> {{ showId ? id + '.' : '' }} {{ title }} </span>
</veolia-button>
