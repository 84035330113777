import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { Observable, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Prompt } from 'src/app/core/models/prompt';
import {
  PromptArticle28FormService,
  PromptArticle32FormService,
  PromptDesignFormService,
  PromptLegalFormService,
} from 'src/app/core/services/prompt.service';
import { groupByReduce } from 'src/app/core/utils/common';

export interface PromptPrompt {
  uid?: string;
  formName: string;
  formChapter: string;
  questionKey: string;
  content: string;
  isActive: boolean;
}

interface ChapterPrompts {
  chapter: string;
  prompts: Prompt[];
}

export interface AdminPromptsPageState {
  currentIndex: number;
  prompts: ChapterPrompts[];
  prompt: Prompt | null;
  isLoading: boolean;
  isSubmitting: boolean;
  isSearching: boolean;
  search: string;
}

@Injectable()
export class AdminPromptsPageStore extends ComponentStore<AdminPromptsPageState> {
  readonly currentIndex$ = this.select(state => state.currentIndex);
  readonly prompt$ = this.select(state => state.prompt);
  readonly isLoading$ = this.select(state => state.isLoading);
  readonly isSubmitting$ = this.select(state => state.isSubmitting);
  readonly isSearching$ = this.select(state => state.isSearching);
  readonly prompts$ = this.select(state => state.prompts);
  readonly search$ = this.select(state => state.search);

  vm$ = this.select(
    this.currentIndex$,
    this.prompt$,
    this.isLoading$,
    this.isSubmitting$,
    this.isSearching$,
    this.prompts$,
    this.search$,
    (
      currentIndex,
      prompt,
      isLoading,
      isSubmitting,
      isSearching,
      prompts,
      search
    ) => ({
      currentIndex,
      prompt,
      isLoading,
      isSubmitting,
      isSearching,
      prompts,
      search,
    })
  );

  constructor(
    private promptDesignFormService: PromptDesignFormService,
    private promptLegalFormService: PromptLegalFormService,
    private promptArticle32FormService: PromptArticle32FormService,
    private promptArticle28FormService: PromptArticle28FormService,
    private messageService: VeoliaMessageService
  ) {
    super({
      currentIndex: 0,
      prompt: null,
      isLoading: false,
      isSubmitting: false,
      isSearching: false,
      prompts: [],
      search: '',
    });
  }

  getCurrentIndex() {
    return this.get().currentIndex;
  }

  getPrompt() {
    return this.get().prompt;
  }

  readonly setIsLoading = this.updater((state, isLoading: boolean) => ({
    ...state,
    isLoading,
  }));

  readonly setIsSubmitting = this.updater((state, isSubmitting: boolean) => ({
    ...state,
    isSubmitting,
  }));

  readonly setIsSearching = this.updater((state, isSearching: boolean) => ({
    ...state,
    isSearching,
  }));

  readonly setCurrentIndex = this.updater((state, currentIndex: number) => ({
    ...state,
    currentIndex,
    isLoading: false,
  }));

  readonly setPrompt = this.updater((state, prompt: Prompt) => ({
    ...state,
    prompt,
    isLoading: false,
  }));

  readonly setPrompts = this.updater((state, prompts: ChapterPrompts[]) => ({
    ...state,
    prompts,
    isLoading: false,
    isSubmitting: false,
  }));

  readonly setSearch = this.updater((state, search: string) => ({
    ...state,
    isSearching: search.length > 0,
    search,
  }));

  readonly resetPrompt = this.updater(state => ({
    ...state,
    prompt: null,
  }));

  private getPromptService(currentIndex: number) {
    switch (currentIndex) {
      case 1:
        return this.promptLegalFormService;
      case 2:
        return this.promptArticle32FormService;
      case 3:
        return this.promptArticle28FormService;
      default:
        return this.promptDesignFormService;
    }
  }

  readonly loadPrompts = this.effect<void>(trigger$ => {
    return trigger$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ currentIndex, search }) => {
        const promptService = this.getPromptService(currentIndex);
        const prompts$ = promptService.observeAll();

        if (search.length > 0) {
          return prompts$.pipe(
            map(prompts =>
              prompts.filter(
                prompt =>
                  prompt.content.includes(search) ||
                  prompt.questionKey.includes(search)
              )
            )
          );
        } else {
          return prompts$;
        }
      }),
      tap(prompts =>
        this.setPrompts(
          Object.entries(groupByReduce(prompts, prompt => prompt.formChapter))
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([chapter, prompts]) => ({
              chapter,
              prompts,
            }))
        )
      )
    );
  });

  readonly changeSearch = this.effect((search$: Observable<string>) => {
    return search$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([search, state]) => ({ search, state })),
      tap(({ search }) => {
        this.setSearch(search);
        this.loadPrompts();
      })
    );
  });

  readonly savePrompt = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => this.setIsSubmitting(true)),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ prompt, currentIndex }) => {
        const promptService = this.getPromptService(currentIndex);
        if (prompt) {
          const saveAction = prompt?.uid
            ? promptService.update(prompt.uid, prompt, false)
            : promptService.create(prompt);

          return of(
            saveAction
              .then(() => {
                this.setIsSubmitting(false);
                this.messageService.create(
                  {
                    title: prompt?.uid
                      ? 'Prompt updated successfully'
                      : 'Prompt created successfully',
                  },
                  { duration: 3000 }
                );
              })
              .catch(() => {
                this.setIsSubmitting(false);
                this.messageService.create(
                  {
                    title:
                      'Oops! An arror occured on prompt ' + prompt?.uid
                        ? 'updation'
                        : 'creation',
                  },
                  { duration: 3000 }
                );
              })
          );
        } else {
          return of(null);
        }
      })
    );
  });

  readonly togglePrompt = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => this.setIsSubmitting(true)),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ prompt, currentIndex }) => {
        if (prompt?.uid) {
          const promptService = this.getPromptService(currentIndex);

          return promptService
            .update(
              prompt?.uid,
              {
                isActive: !prompt?.isActive,
              },
              false
            )
            .then(() => {
              this.messageService.create(
                {
                  title: prompt?.isActive
                    ? 'Prompt disabled successfully'
                    : 'Prompt enabled successfully',
                },
                { duration: 3000 }
              );
            })
            .catch(() => {
              this.messageService.create(
                {
                  title:
                    'Oops! An error occured on prompt' + prompt?.uid
                      ? 'disabling'
                      : 'enabling',
                },
                { duration: 3000 }
              );
            });
        }

        return of(null);
      })
    );
  });

  readonly deletePrompt = this.effect(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ prompt, currentIndex }) => {
        if (prompt?.uid) {
          const promptService = this.getPromptService(currentIndex);

          return promptService
            .remove(prompt?.uid)
            .then(() => {
              this.messageService.create(
                {
                  title: 'Prompt deleted successfully',
                },
                { duration: 3000 }
              );
            })
            .catch(() => {
              this.messageService.create(
                {
                  title: 'Oops! An error occured on deletion',
                },
                { duration: 3000 }
              );
            });
        } else {
          return of(null);
        }
      })
    );
  });
}
