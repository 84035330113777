import { Component, Input } from '@angular/core';
import { FileItem } from 'src/app/core/models/file-item';

@Component({
  selector: 'app-file-summary-item',
  templateUrl: './file-summary-item.component.html',
  styleUrls: ['./file-summary-item.component.scss'],
})
export class FileSummaryItemComponent {
  @Input() file?: FileItem;

  goToLink(file: FileItem) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.open('GET', file.url);
    xhr.send();
  }
}
