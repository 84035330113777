import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VeoliaTabsComponent } from '@veolia.com/vds-angular-components/tabs';
import { Solution } from 'src/app/core/models/solution';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { AuthService } from 'src/app/core/services/auth.service';
import { LogService } from 'src/app/core/services/log.service';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';
import { VeoliaModalService } from '@veolia.com/vds-angular-components/modal';
import { Auth } from '@angular/fire/auth';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-demand-forms-page',
  templateUrl: './demand-forms-page.component.html',
  styleUrls: ['./demand-forms-page.component.scss'],
})
export class DemandFormsPageComponent
  extends DataTableComponent
  implements OnInit, AfterViewInit
{
  forms = ['Design Form', 'Legal Form', 'Article 32 Form', 'Article 28 Form'];
  selectedSolution?: Solution;
  selectedBusinessUnit?: BusinessUnit;
  selectedForm = 'Design Form';
  formTabNames = ['designForm', 'legalForm', 'article32Form', 'article28Form'];
  formName: string | null = null;
  formDataUpdatedDesign?: {
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  };
  formDataUpdatedLegal?: {
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  };
  formDataUpdatedArt28?: {
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  };
  formDataUpdatedArt32?: {
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  };
  currentFormName?: string;
  id: string | null = null;
  userRole = '';
  isDirty = false;

  @ViewChild('formTabs', { static: false })
  formTabs!: VeoliaTabsComponent;

  #service = inject(VeoliaModalService);
  //override authService = inject(AuthService);
  logService = inject(LogService);

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private auth: Auth,
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    this.formName = this.route.snapshot.paramMap.get('formName');
    this.currentFormName =
      this.forms[this.formTabNames.indexOf(this.formName!)];
    this.id = this.route.snapshot.paramMap.get('id');

    this.route.data.subscribe(data => {
      this.selectedSolution = data['solution'] as Solution;
      this.selectedBusinessUnit = {
        evaluators: [],
        ...data['businessUnit'],
      } as BusinessUnit;
    });
  }

  ngAfterViewInit() {
    this.formName = this.route.snapshot.paramMap.get('formName');
    this.currentFormName =
      this.forms[this.formTabNames.indexOf(this.formName!)];
    this.formTabs.selectedIndex = this.formTabNames.indexOf(this.formName!);
  }

  get userId() {
    return this.auth.currentUser?.uid;
  }

  get userIsSoOfSolution() {
    if (this.selectedSolution?.solutionOwner === this.auth.currentUser?.email)
      return true;
    else return false;
  }

  get isBusinessUnitEvaluator() {
    if (
      !this.selectedBusinessUnit?.evaluators?.includes(
        this.auth.currentUser?.email!
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  get userIsVeoliaAuthorized() {
    if (
      !this.selectedSolution?.emailVeoliaAuthorized?.includes(
        this.auth.currentUser?.email!
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  get userIsVendorAuthorized() {
    if (
      !this.selectedSolution?.emailVendorAuthorized?.includes(
        this.auth.currentUser?.email!
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  get isSolutionEvaluator() {
    return this.selectedSolution?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser?.email!
    );
  }

  get hasFullRights() {
    return (
      this.authService.roles?.admin ||
      (this.authService.roles?.evaluator && this.isBusinessUnitEvaluator) ||
      this.isSolutionEvaluator ||
      this.userIsSoOfSolution
    );
  }

  get canShare() {
    return (
      this.hasFullRights ||
      this.userIsVeoliaAuthorized ||
      this.userIsVendorAuthorized
    );
  }

  formChanged(index: number) {
    this.selectedForm = this.forms[index];
    this.formName = this.formTabNames[index];
    this.currentFormName =
      this.forms[this.formTabNames.indexOf(this.formName!)];

    if (index === this.formTabNames.indexOf(this.formName!)) {
      this.location.replaceState(
        `/${this.formName}/${this.selectedSolution!.uid!}`
      );
    }

    this.logService.setLogForAction(
      'Connection',
      this.selectedSolution!.uid!,
      this.selectedForm!,
      {
        action: 'Connection',
        roleUser: this.authService.userRole,
        formName: this.selectedForm,
      }
    );
  }

  formDateUpdate(data: {
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }) {
    if (data.formName === 'Design Form') {
      this.formDataUpdatedDesign = { ...data };
    } else if (data.formName === 'Legal Form') {
      this.formDataUpdatedLegal = { ...data };
    } else if (data.formName === 'Article 28 Form') {
      this.formDataUpdatedArt28 = { ...data };
    } else if (data.formName === 'Article 32 Form') {
      this.formDataUpdatedArt32 = { ...data };
    }
  }

  get formDataUpdated() {
    if (this.formName === 'designForm') {
      return this.formDataUpdatedDesign;
    } else if (this.formName === 'legalForm') {
      return this.formDataUpdatedLegal;
    } else if (this.formName === 'article28Form') {
      return this.formDataUpdatedArt28;
    } else if (this.formName === 'article32Form') {
      return this.formDataUpdatedArt32;
    }
    return;
  }

  notifiedDirty(isDirty: boolean) {
    this.isDirty = isDirty;
  }
}
