import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  Database,
  getDatabase,
  ref,
  onValue,
  update,
  onDisconnect,
  set,
  off,
} from '@angular/fire/database';
import { Subject } from 'rxjs';
import { PresentUser } from './document-presence.component';

export interface UserPresence {
  userId: string;
  displayName: string;
}

export interface FormStatus {
  users: UserPresence[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentPresenceService {
  private formName?: string;
  private documentId?: string;
  presenceListener: any;
  presentUsers: Subject<PresentUser[]> = new Subject();
  presentUsers$ = this.presentUsers.asObservable();

  constructor(
    private auth: Auth,
    private db: Database = getDatabase()
  ) {}

  initializeDocumentPresence(formName?: string, documentId?: string) {
    this.formName = formName;
    this.documentId = documentId;

    this.setPresence();
  }

  setPresence() {
    const uid = this.auth.currentUser?.uid;

    if (!uid) {
      return;
    }

    update(
      ref(
        this.db,
        `documentStatus/${this.documentId}/${this.formName}/users/${uid}`
      ),
      {
        userId: uid,
        displayName: this.auth.currentUser?.displayName,
      }
    );

    onDisconnect(
      ref(
        this.db,
        `documentStatus/${this.documentId}/${this.formName}/users/${uid}`
      )
    ).remove();
  }

  listenPresence() {
    const uid = this.auth.currentUser?.uid;

    if (!uid) {
      return;
    }

    this.presenceListener = onValue(
      ref(this.db, `documentStatus/${this.documentId}/${this.formName}/users`),
      async snapshot => {
        if (snapshot.exists()) {
          this.presentUsers.next(snapshot.val());
        }
      }
    );
  }

  unlistenPresence() {
    off(
      ref(this.db, `documentStatus/${this.documentId}/${this.formName}/users`),
      this.presenceListener
    );
  }

  deletePresence(oldFormName: string) {
    const uid = this.auth.currentUser?.uid;

    if (!uid) {
      return;
    }

    set(
      ref(
        this.db,
        `documentStatus/${this.documentId}/${oldFormName}/users/${uid}`
      ),
      null
    );
  }
}
