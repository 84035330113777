<veolia-button
  [size]="'default'"
  [variant]="'secondary'"
  [icon]="'legend_toggle'"
  (click)="modal.open()">
  Caption
</veolia-button>
<veolia-modal #modal>
  <div veolia-modal-title>Caption</div>
  <div veolia-modal-content>
    <div class="content">
      <div class="column column-space-between">
        <div>
          <veolia-tabs>
            <veolia-tab
              label="Status & Colors"
              *ngIf="
                isHomeRoute() || isEvaluatorRoute() || isAdminSolutionsRoute()
              ">
              <app-form-caption-item
                *ngFor="let status of statuses"
                [title]="status.title">
                <veolia-tag
                  variant="bold"
                  [label]="status.label"
                  [color]="getTagColor(status.label)"></veolia-tag>
              </app-form-caption-item>
            </veolia-tab>

            <veolia-tab label="Status & Colors" *ngIf="isFormsRoute()">
              <app-form-caption-item
                *ngFor="let status of statusesForms"
                [title]="status.title">
                <veolia-tag
                  variant="bold"
                  [label]="status.label"
                  [color]="getTagColor(status.label)"></veolia-tag>
              </app-form-caption-item>
            </veolia-tab>

            <!--Veolia Home page and Vendor-->
            <veolia-tab
              label="Actions"
              *ngIf="isHomeRoute() && userType === 'Vendor'">
              <app-form-caption-item
                title="Check out details about the selected row">
                <veolia-icon
                  [size]="24"
                  [name]="'info'"
                  [filled]="true"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="More options">
                <veolia-icon [size]="24" [name]="'more_vert'"></veolia-icon>
              </app-form-caption-item>
              <p>Under "more options" button you will have:</p>
              <app-form-caption-item title="Share the solution">
                <veolia-icon [size]="24" [name]="'share'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Check out the solution access rights">
                <veolia-icon [size]="24" [name]="'group'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Check out the solution logs">
                <veolia-icon [size]="24" [name]="'list_alt'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Check out the solution informations / summary assessments and reservations">
                <veolia-icon
                  [size]="24"
                  [name]="'event_available'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Refresh the Security Score Card (only if link is set)">
                <veolia-icon [size]="24" [name]="'update'"></veolia-icon>
              </app-form-caption-item>
            </veolia-tab>

            <!--Veolia Home page and Evaluator page and Veolia-->
            <veolia-tab
              label="Actions"
              *ngIf="
                (isHomeRoute() || isEvaluatorRoute()) && userType === 'Veolia'
              ">
              <app-form-caption-item
                title="Check out details about the selected row">
                <veolia-icon [size]="24" [name]="'info'"></veolia-icon
                ><veolia-icon
                  [size]="24"
                  [name]="'info'"
                  [filled]="true"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="More options">
                <veolia-icon [size]="24" [name]="'more_vert'"></veolia-icon>
              </app-form-caption-item>
              <p>Under "more options" button you will have:</p>
              <app-form-caption-item title="Edit the solution">
                <veolia-icon [size]="24" [name]="'edit'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Share the solution">
                <veolia-icon [size]="24" [name]="'share'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Check out the solution access rights">
                <veolia-icon [size]="24" [name]="'group'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Check out the solution logs">
                <veolia-icon [size]="24" [name]="'list_alt'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Check out the solution informations / summary assessments and reservations">
                <veolia-icon
                  [size]="24"
                  [name]="'event_available'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Refresh the Security Score Card (only if link is set)">
                <veolia-icon [size]="24" [name]="'update'"></veolia-icon>
              </app-form-caption-item>
            </veolia-tab>

            <!--Veolia Admin solutions page-->
            <veolia-tab label="Actions" *ngIf="isAdminSolutionsRoute()">
              <app-form-caption-item
                title="Check out details about the selected row">
                <veolia-icon
                  [size]="24"
                  [name]="'info'"
                  [filled]="true"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="More options">
                <veolia-icon [size]="24" [name]="'more_vert'"></veolia-icon>
              </app-form-caption-item>
              <p>Under "more options" button you will have:</p>
              <app-form-caption-item title="Edit the solution">
                <veolia-icon [size]="24" [name]="'edit'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Share the solution">
                <veolia-icon [size]="24" [name]="'share'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Check out the solution access rights">
                <veolia-icon [size]="24" [name]="'group'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Check out the solution logs">
                <veolia-icon [size]="24" [name]="'list_alt'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Check out the solution informations / summary assessments and reservations">
                <veolia-icon
                  [size]="24"
                  [name]="'event_available'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Cancel the solution">
                <veolia-icon [size]="24" [name]="'delete'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Restore the solution">
                <veolia-icon [size]="24" [name]="'restore_page'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Refresh the Security Score Card (only if link is set)">
                <veolia-icon [size]="24" [name]="'update'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Validate the solution">
                <veolia-icon [size]="24" [name]="'verified'"></veolia-icon>
              </app-form-caption-item>
            </veolia-tab>

            <!--Veolia Admin bus page-->
            <veolia-tab label="Actions" *ngIf="isAdminBuRoute()">
              <app-form-caption-item title="More options">
                <veolia-icon [size]="24" [name]="'more_vert'"></veolia-icon>
              </app-form-caption-item>
              <p>Under "more options" button you will have:</p>
              <app-form-caption-item title="Edit the business unit">
                <veolia-icon [size]="24" [name]="'domain_add'"></veolia-icon>
              </app-form-caption-item>
            </veolia-tab>

            <!--Veolia Admin users page-->
            <veolia-tab label="Actions" *ngIf="isAdminUsersRoute()">
              <app-form-caption-item
                title="Check out details about the selected row">
                <veolia-icon
                  [size]="24"
                  [name]="'info'"
                  [filled]="true"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="More options">
                <veolia-icon [size]="24" [name]="'more_vert'"></veolia-icon>
              </app-form-caption-item>
              <p>Under "more options" button you will have:</p>
              <app-form-caption-item title="Edit the user rights and his BU">
                <veolia-icon
                  [size]="24"
                  [name]="'manage_accounts'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Disable the user account">
                <veolia-icon [size]="24" [name]="'person_off'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Enable the user account">
                <veolia-icon [size]="24" [name]="'person_add'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Send reset password email for the user account">
                <veolia-icon [size]="24" [name]="'mail_lock'"></veolia-icon>
              </app-form-caption-item>
            </veolia-tab>

            <!--Forms page and Veolia/Vendor-->
            <veolia-tab label="Actions" *ngIf="isFormsRoute()">
              <p>Actions related to the <strong>form</strong>:</p>
              <app-form-caption-item title="Comments">
                <veolia-icon [size]="24" [name]="'comment'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Files">
                <veolia-icon [size]="24" [name]="'attach_file'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Chapter/Sub-chapter/Form status">
                <nz-tag
                  class="ant-tag veolia-tag light"
                  ng-reflect-ng-class="light"
                  ><span class="veolia-tag-label">Evaluation</span></nz-tag
                >
              </app-form-caption-item>
              <app-form-caption-item title="Chapter completed or not">
                <div
                  class="veolia-switch with-container with-container-selected">
                  <nz-switch
                    ng-reflect-model="true"
                    ng-reflect-nz-disabled="false"
                    ng-reflect-nz-size="small"
                    class="ng-untouched ng-pristine ng-valid"
                    ><button
                      nz-wave=""
                      type="button"
                      class="ant-switch ant-switch-small ant-switch-checked"
                      ng-reflect-nz-wave-extra-node="true">
                      <span class="ant-switch-handle"> </span
                      ><span class="ant-switch-inner"></span>
                      <div
                        class="ant-click-animating-node"></div></button></nz-switch
                  ><label class="veolia-switch-label"> Completed </label>
                </div>
              </app-form-caption-item>
              <app-form-caption-item title="Form fully completed or not">
                <div
                  class="veolia-switch with-container with-container-selected">
                  <nz-switch
                    ng-reflect-model="true"
                    ng-reflect-nz-disabled="false"
                    ng-reflect-nz-size="small"
                    class="ng-untouched ng-pristine ng-valid"
                    ><button
                      nz-wave=""
                      type="button"
                      class="ant-switch ant-switch-small ant-switch-checked"
                      ng-reflect-nz-wave-extra-node="true">
                      <span class="ant-switch-handle"> </span
                      ><span class="ant-switch-inner"></span>
                      <div
                        class="ant-click-animating-node"></div></button></nz-switch
                  ><label class="veolia-switch-label">
                    Mark as completed
                  </label>
                </div>
              </app-form-caption-item>
              <p>Actions related to the <strong>request/solution</strong>:</p>
              <app-form-caption-item title="Edit the solution">
                <veolia-icon [size]="24" [name]="'edit'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Check out the solution informations / summary assessments and reservations">
                <veolia-icon
                  [size]="24"
                  [name]="'event_available'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Share the solution">
                <veolia-icon [size]="24" [name]="'share'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item
                title="Check out the solution access rights">
                <veolia-icon [size]="24" [name]="'group'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Check out the solution logs">
                <veolia-icon [size]="24" [name]="'list_alt'"></veolia-icon>
              </app-form-caption-item>
              <app-form-caption-item title="Help request for the form">
                <veolia-icon [size]="24" [name]="'help'"></veolia-icon>
              </app-form-caption-item>
            </veolia-tab>
          </veolia-tabs>
        </div>
      </div>
    </div>
  </div>
</veolia-modal>
