import { Injectable } from '@angular/core';

import { BaseService } from './base.service';
import { Prompt } from '../models/prompt';

@Injectable()
export class PromptDesignFormService extends BaseService<Prompt> {
  get collectionName(): string {
    return 'genaiPromptsDesignForm';
  }
}

@Injectable()
export class PromptLegalFormService extends BaseService<Prompt> {
  get collectionName(): string {
    return 'genaiPromptsLegalForm';
  }
}

@Injectable()
export class PromptArticle32FormService extends BaseService<Prompt> {
  get collectionName(): string {
    return 'genaiPromptsArticle32Form';
  }
}

@Injectable()
export class PromptArticle28FormService extends BaseService<Prompt> {
  get collectionName(): string {
    return 'genaiPromptsArticle28Form';
  }
}
