import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  signal,
} from '@angular/core';
import { Solution } from 'src/app/core/models/solution';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { Evaluation } from 'src/app/core/models/evalutation';
import { FormName } from 'src/app/core/enums/form-name';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { DesignForm } from 'src/app/core/models/design-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { FileItem } from 'src/app/core/models/file-item';
import { EvaluationSummaryComponent } from '../../../evaluation-summary/components/evaluation-summary/evaluation-summary.component';
import { CommentSummaryComponent } from '../../../comment-summary/components/comment-summary/comment-summary.component';
import { FileSummaryComponent } from '../../../file-summary/components/file-summary/file-summary.component';
import { SolutionInfoSummaryComponent } from '../../../solution-info-summary/components/solution-info-summary.component';
import { Comment } from 'src/app/core/models/comment';
import { AuthService } from 'src/app/core/services/auth.service';
import { Auth } from '@angular/fire/auth';
import { FileApi } from 'src/app/core/apis/file.api';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { Observable, tap } from 'rxjs';
import { GenAIService } from 'src/app/core/services/genai.service';

@Component({
  selector: 'app-form-toolbar',
  templateUrl: './form-toolbar.component.html',
  styleUrls: ['./form-toolbar.component.scss'],
})
export class FormToolbarComponent implements OnInit {
  @Input() solution?: Solution;
  @Input() businessUnit?: BusinessUnit;
  @Input() evaluation?: Evaluation;
  @Input() comments?: Record<string, Comment[]>;
  @Input() files?: Record<string, FileItem[]>;
  @Input() fileList?: FileItem[];
  @Input() currentForm?: Article28Form | Article32Form | DesignForm | LegalForm;
  @Input() formName?: FormName;
  @Input() formCompletionStatus?: boolean;
  @Input() genaiStatus$?: Observable<string | undefined>;
  @Input() formStatus?: string;
  @Input() isDirty = false;
  @Input() isSaving = false;

  @Output() saveAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() markAsComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refreshForm: EventEmitter<void> = new EventEmitter<void>();

  @Output() markAsGenAiEnabled: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  showEvaluationModal = signal(false);
  showCommentsModal = signal(false);
  showFilesModal = signal(false);
  isGenAIEnabled = false;

  @ViewChild('commentSummary')
  commentSummary!: CommentSummaryComponent;

  @ViewChild('evaluationSummary')
  evaluationSummary!: EvaluationSummaryComponent;

  @ViewChild('fileSummary')
  fileSummary!: FileSummaryComponent;

  @ViewChild('solutionInfoSummaryModal')
  solutionInfoSummary!: SolutionInfoSummaryComponent;

  @ViewChild('genaiModal') genaiModal!: VeoliaModalComponent;

  constructor(
    private auth: Auth,
    private authService: AuthService,
    private fileApi: FileApi,
    private genAIService: GenAIService,
    public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.genAIService.observeGenAISettings().subscribe(data => {
      if (data) {
        this.isGenAIEnabled = data.enabled;
      }
    });
  }

  toggleInfoModal() {
    this.solutionInfoSummary.open(this.solution!);
  }

  toggleCommentsModal() {
    this.commentSummary.open();
    this.showCommentsModal.set(!this.showCommentsModal());
  }

  toggleEvaluationModal() {
    this.evaluationSummary.open();
    this.showEvaluationModal.set(!this.showEvaluationModal());
  }

  toggleFilesModal() {
    this.fileSummary.open();
    this.showFilesModal.set(!this.showFilesModal());
  }

  toogleGenaiModal() {
    this.genaiModal.open();
  }

  get isVendor() {
    return (
      this.solution?.emailVendorAuthorized.includes(
        this.auth.currentUser?.email!
      ) && !this.isVendorReadOnly(this.formName!)
    );
  }

  isVendorReadOnly(formName: string) {
    switch (formName) {
      case 'Article 28 Form':
        return this.solution?.emailVendorReadonlyArt28?.includes(
          this.auth.currentUser?.email!
        );
      case 'Article 32 Form':
        return this.solution?.emailVendorReadonlyArt32?.includes(
          this.auth.currentUser?.email!
        );
      case 'Design Form':
        return this.solution?.emailVendorReadonlyDesign?.includes(
          this.auth.currentUser?.email!
        );
      case 'Legal Form':
        return this.solution?.emailVendorReadonlyLegal?.includes(
          this.auth.currentUser?.email!
        );
      default:
        return false;
    }
  }

  get isViewer() {
    return this.authService.roles?.viewer;
  }

  get isAdmin() {
    return this.authService.roles?.admin;
  }

  get userIsSoOfSolution() {
    return this.solution?.solutionOwner === this.auth.currentUser!.email!;
  }

  get isBusinessUnitEvaluator() {
    if (
      this.businessUnit?.evaluators.includes(this.auth.currentUser!.email!) &&
      this.authService.roles?.evaluator
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isSolutionEvaluator() {
    return this.solution?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  get hasFullRights() {
    return (
      this.isAdmin || this.isBusinessUnitEvaluator || this.isSolutionEvaluator
    );
  }

  get isSubmittable() {
    return (
      this.isSolutionEvaluator || this.isAdmin || this.isBusinessUnitEvaluator
    );
  }

  isAssessed(formName: string) {
    switch (formName) {
      case 'Article 28 Form':
        return (
          this.solution?.article28Status === 'Recommended' ||
          this.solution?.article28Status === 'Reservations' ||
          this.solution?.article28Status === 'Not recommended'
        );
      case 'Article 32 Form':
        return (
          this.solution?.article32Status === 'Recommended' ||
          this.solution?.article32Status === 'Reservations' ||
          this.solution?.article32Status === 'Not recommended'
        );
      case 'Design Form':
        return (
          this.solution?.designStatus === 'Recommended' ||
          this.solution?.designStatus === 'Reservations' ||
          this.solution?.designStatus === 'Not recommended'
        );
      case 'Legal Form':
        return (
          this.solution?.legalStatus === 'Recommended' ||
          this.solution?.legalStatus === 'Reservations' ||
          this.solution?.legalStatus === 'Not recommended'
        );
      default:
        return false;
    }
  }

  get canComplete() {
    return (
      (this.isAdmin ||
        (this.isVendor && !this.isVendorReadOnly(this.formName!))) &&
      !this.isAssessed(this.formName!)
    );
  }

  generateFormattedDate(): string {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  generatePDF() {
    this.fileApi
      .exportFormPDF(this.solution?.uid!, {
        formName: this.formName!,
        formNameUrl: this.toCamelCase(this.formName),
      })
      .subscribe({
        next: response => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          let fileName = '';

          if (this.formName === 'Article 28 Form') {
            fileName = 'Additional Forms';
          } else {
            fileName = this.formName!;
          }

          link.download = `${fileName}-${this.solution?.solutionName}-${this.generateFormattedDate()}.pdf`;
          link.click();
          window.URL.revokeObjectURL(url);
        },
        error: error => {
          console.error('Error downloading PDF:', error);
        },
      });
  }

  toCamelCase(str?: string) {
    if (str === undefined) {
      return '';
    }
    // Remove leading and trailing whitespace and split into words
    const words = str.trim().split(/\s+/);

    // Convert the first word to lowercase
    const firstWord = words[0].toLowerCase();

    // Convert the first letter of subsequent words to uppercase
    const restOfWords = words
      .slice(1)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    // Join all parts
    return firstWord + restOfWords.join('');
  }
}
