<div
  *ngIf="!isVendorReadOnly"
  class="dropzone"
  appDropzone
  (hovered)="toggleHover($event)"
  (dropped)="onDrop($event)"
  [class.hovering]="isHovering">
  <veolia-icon [size]="48" [name]="'upload_file'"></veolia-icon>
  <strong>Drop your file here to upload</strong>
  <p><strong>or</strong></p>
  <label class="btn btn-default p-0">
    <veolia-button
      [variant]="'secondary'"
      [htmlType]="'button'"
      (click)="fileInput.click()">
      Select file
    </veolia-button>
    <input #fileInput type="file" (change)="selectFiles($event)" />
  </label>
</div>
<div *ngFor="let f of uploadfiles; let index = index">
  <app-form-file-upload
    [file]="f"
    [fieldId]="fieldId"
    [formName]="formName"
    [solutionId]="solutionId"
    (reloadFiles)="cleanAndReload(index)"></app-form-file-upload>
</div>
<nz-divider></nz-divider>
<h5 nz-typography [ngStyle]="{ margin: '16px 0' }">Files history</h5>
<div *ngFor="let file of files; let i = index" class="file-item spacing-v4">
  <div class="row row-space-between row-vertical-center">
    <div class="row row-vertical-center">
      <div>
        <veolia-icon
          [size]="24"
          [filled]="false"
          name="file_present"></veolia-icon>
      </div>
      <div>
        <div class="file-item__file-name">
          <strong>{{ file.name }}</strong>
        </div>
        <div class="file-item__file-info">
          Posted by
          <a href="mailto:{{ file.postedBy }}">{{ file.postedBy }}</a>
          {{ file.postedAt | date }} -
          <strong>{{ file.postedAt | date: 'HH:mm' }}</strong>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="row">
          <veolia-button
            (click)="deleteFile(file, i)"
            [size]="'small'"
            [shape]="'default'"
            [variant]="'secondary'"
            [htmlType]="'button'"
            [icon]="'delete'">
          </veolia-button>
          <veolia-button
            (click)="goToLink(file.url)"
            [size]="'small'"
            [shape]="'default'"
            [variant]="'secondary'"
            [htmlType]="'button'"
            [icon]="'download'">
          </veolia-button>
          <veolia-button
            *ngIf="
              this.authService.userRole === 'Vendor' && fieldId === 'AR32_0'
            "
            nz-tooltip="Summarize the file with Veolia genAI"
            (click)="generateGenai(file.name)"
            [size]="'small'"
            [shape]="'default'"
            [variant]="'secondary'"
            [htmlType]="'button'"
            [icon]="'flash_on'">
          </veolia-button>
          <simple-spinner 
            style="text-align: center !important"
            *ngIf="isLoading && fileNameGenAi === file.name"></simple-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
