<veolia-tag
  style="cursor: pointer"
  [label]="'genAI'"
  [color]="(genaiStatusColor$ | async) || 'default'"
  variant="light"
  (click)="toogleGenaiModal()"
  [icon]="'smart_toy'">
</veolia-tag>
<veolia-modal #genaiModal [closable]="true">
  <div veolia-modal-title>Generative AI for {{ formName }}</div>
  <div veolia-modal-subtitle>GenAI process</div>
  <div veolia-modal-content>
    <veolia-tabs>
      <veolia-tab label="genAI description">
        <p>
          By validating the Terms of Service, you have authorized Veolia to
          analyze all documents you have uploaded to the platform dla.veolia.com
          on different forms.
        </p>
        <p>
          Only PDF files will be analyzed. The analysis process may take a few
          minutes and will not prevent you from continuing to browse the forms
          and respond to them.
        </p>
        A manual action on your part is required. In fact, response suggestions
        will appear automatically gradually below each question, and you will be
        free to validate, unvalidate, and/or copy them in the response fields of
        questions for which the genAI tool has found an answer.
        <p>
          Once the complete analysis is finished, you will receive a
          notification by email.
        </p>
        <p>
          Please note that if you restart the analysis with new documents, all
          past suggestions will be overwritten (with the exception of what has
          copied/pasted into the response fields).
        </p> 
        <nz-card
          nzType="inner"
          nzTitle="{{ 'genAI analysis status color' | translate }}"
          class="spacing-v16">
          <div class="row">
            <p class="flex">
              <veolia-tag
                [label]="'genAI'"
                [color]="'default'"
                variant="light"
                [icon]="'smart_toy'"></veolia-tag
              >: genAI has not been launched yet
            </p>
            <p class="flex">
              <veolia-tag
                [label]="'genAI'"
                [color]="'warning'"
                variant="light"
                [icon]="'smart_toy'"></veolia-tag
              >: genAI is in progress
            </p>
            <p class="flex">
              <veolia-tag
                [label]="'genAI'"
                [color]="'success'"
                variant="light"
                [icon]="'smart_toy'"></veolia-tag
              >: genAI is completed
            </p>
          </div>
        </nz-card>
        <nz-card
          nzType="inner"
          nzTitle="{{ 'genAI suggestions status color' | translate }}"
          class="spacing-v16">
          <div>
            <nz-collapse
              [ngStyle]="{
                'background-color': '#e6f7ff'
              }">
              <nz-collapse-panel nzHeader="Show genAI response">
                <nz-alert
                  nzShowIcon
                  [nzType]="'info'"
                  [nzDescription]="
                    'genAI generated suggestion: the suggestion has not been checked yet'
                  ">
                  >
                </nz-alert>
              </nz-collapse-panel>
            </nz-collapse>
            <nz-collapse
              [ngStyle]="{
                'background-color': '#fff2f0'
              }">
              <nz-collapse-panel nzHeader="Show genAI response">
                <nz-alert
                  nzShowIcon
                  [nzType]="'error'"
                  [nzDescription]="
                    'genAI generated suggestion: the suggestion has been unvalidated (by vendor manually)'
                  ">
                  >
                </nz-alert>
              </nz-collapse-panel>
            </nz-collapse>
            <nz-collapse
              [ngStyle]="{
                'background-color': '#d9edc0'
              }">
              <nz-collapse-panel nzHeader="Show genAI response">
                <nz-alert
                  nzShowIcon
                  [nzType]="'success'"
                  [nzDescription]="
                    'genAI generated suggestion: the suggestion has been validated (by vendor manually)'
                  ">
                  >
                </nz-alert>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </nz-card>
      </veolia-tab>

      <veolia-tab label="genAI launch">
        <veolia-button
          [disabled]="!canComplete || (genaiStatus$ | async) === 'in progress'"
          [size]="'small'"
          [icon]="'smart_toy'"
          (click)="markAsGenAiEnabled.emit()">
          Launch genAI analysis
        </veolia-button>
        <nz-divider></nz-divider>
        <nz-card
          nzType="inner"
          nzTitle="{{ 'genAI informations' | translate }}"
          class="spacing-v16">
          <div *ngIf="form$ | async as formData">
            <!-- Design Form -->
            <section style="display: grid" *ngIf="formName === 'Design Form'">
              <ng-container
                *ngIf="formData?.genaiStatusDesign; else noGenAIStatus">
                <span nz-tooltip nzTooltipTitle="Current status of analysis">
                  genAI status:
                  <strong>{{ formData.genaiStatusDesign }}</strong> since
                  <strong>{{
                    formData.genaiStatusDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <span nz-tooltip nzTooltipTitle="Last trigger date of analysis">
                  Last triggered on:
                  <strong>{{
                    formData.genaiTriggeredDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <span
                  nz-tooltip
                  nzTooltipTitle="User who launched the last analysis">
                  Last triggered by:
                  <strong>{{ formData.genaiTriggeredBy }}</strong>
                </span>
                <span
                  *ngIf="formData.genaiStatusDesign === 'completed'"
                  nz-tooltip
                  nzTooltipTitle="Last date analysis completion">
                  genAI finished on:
                  <strong>{{
                    formData.genaiCompletedDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <nz-divider></nz-divider>
                <span
                  nz-tooltip
                  nzTooltipTitle="Analyzed documents during the analysis"
                  >{{ 'Analyzed documents' | translate }}:</span
                >
                <p class="row">
                  <veolia-chip
                    *ngFor="let doc of formData.genaiAnalyzedDocs"
                    [mode]="'default'">
                    {{ doc }}
                  </veolia-chip>
                </p>
              </ng-container>
            </section>

            <!-- Legal Form -->
            <section style="display: grid" *ngIf="formName === 'Legal Form'">
              <ng-container
                *ngIf="formData?.genaiStatusLegal; else noGenAIStatus">
                <span nz-tooltip nzTooltipTitle="Current status of analysis">
                  genAI status:
                  <strong>{{ formData.genaiStatusLegal }}</strong> since
                  <strong>{{
                    formData.genaiStatusDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <span nz-tooltip nzTooltipTitle="Last trigger date of analysis">
                  Last triggered on:
                  <strong>{{
                    formData.genaiTriggeredDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <span
                  nz-tooltip
                  nzTooltipTitle="User who launched the last analysis">
                  Last triggered by:
                  <strong>{{ formData.genaiTriggeredBy }}</strong>
                </span>
                <span
                  *ngIf="formData.genaiStatusLegal === 'completed'"
                  nz-tooltip
                  nzTooltipTitle="Last date analysis completion">
                  genAI finished on:
                  <strong>{{
                    formData.genaiCompletedDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <nz-divider></nz-divider>
                <span
                  nz-tooltip
                  nzTooltipTitle="Analyzed documents during the analysis"
                  >{{ 'Analyzed documents' | translate }}:</span
                >
                <p class="row">
                  <veolia-chip
                    *ngFor="let doc of formData.genaiAnalyzedDocs"
                    [mode]="'default'">
                    {{ doc }}
                  </veolia-chip>
                </p>
              </ng-container>
            </section>

            <!-- Article 28 Form -->
            <section
              style="display: grid"
              *ngIf="formName === 'Article 28 Form'">
              <ng-container
                *ngIf="formData?.genaiStatusArticle28; else noGenAIStatus">
                <span nz-tooltip nzTooltipTitle="Current status of analysis">
                  genAI status:
                  <strong>{{ formData.genaiStatusArticle28 }}</strong> since
                  <strong>{{
                    formData.genaiStatusDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <span nz-tooltip nzTooltipTitle="Last trigger date of analysis">
                  Last triggered on:
                  <strong>{{
                    formData.genaiTriggeredDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <span
                  nz-tooltip
                  nzTooltipTitle="User who launched the last analysis">
                  Last triggered by:
                  <strong>{{ formData.genaiTriggeredBy }}</strong>
                </span>
                <span
                  *ngIf="formData.genaiStatusArticle28 === 'completed'"
                  nz-tooltip
                  nzTooltipTitle="Last date analysis completion">
                  genAI finished on:
                  <strong>{{
                    formData.genaiCompletedDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <nz-divider></nz-divider>
                <span
                  nz-tooltip
                  nzTooltipTitle="Analyzed documents during the analysis">
                  {{ 'Analyzed documents' | translate }}:
                </span>
                <p class="row">
                  <veolia-chip
                    *ngFor="let doc of formData.genaiAnalyzedDocs"
                    [mode]="'default'">
                    {{ doc }}
                  </veolia-chip>
                </p>
              </ng-container>
            </section>

            <!-- Article 32 Form -->
            <section
              style="display: grid"
              *ngIf="formName === 'Article 32 Form'">
              <ng-container
                *ngIf="formData?.genaiStatusArticle32; else noGenAIStatus">
                <span nz-tooltip nzTooltipTitle="Current status of analysis">
                  genAI status:
                  <strong>{{ formData.genaiStatusArticle32 }}</strong> since
                  <strong>{{
                    formData.genaiStatusDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <span nz-tooltip nzTooltipTitle="Last trigger date of analysis">
                  Last triggered on:
                  <strong>{{
                    formData.genaiTriggeredDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <span
                  nz-tooltip
                  nzTooltipTitle="User who launched the last analysis">
                  Last triggered by:
                  <strong>{{ formData.genaiTriggeredBy }}</strong>
                </span>
                <span
                  *ngIf="formData.genaiStatusArticle32 === 'completed'"
                  nz-tooltip
                  nzTooltipTitle="Last date analysis completion">
                  genAI finished on:
                  <strong>{{
                    formData.genaiCompletedDate?.toDate() | date: 'medium'
                  }}</strong>
                </span>
                <nz-divider></nz-divider>
                <span
                  nz-tooltip
                  nzTooltipTitle="Analyzed documents during the analysis">
                  {{ 'Analyzed documents' | translate }}:
                </span>
                <p class="row">
                  <veolia-chip
                    *ngFor="let doc of formData.genaiAnalyzedDocs"
                    [mode]="'default'">
                    {{ doc }}
                  </veolia-chip>
                </p>
              </ng-container>
            </section>

            <ng-template #noGenAIStatus>
              <span nz-tooltip nzTooltipTitle="Current status of analysis">
                genAI status:
                <strong>No analysis launched</strong>
              </span>
            </ng-template>
          </div>
        </nz-card>
      </veolia-tab>
    </veolia-tabs>
  </div>

  <div veolia-modal-footer></div>
</veolia-modal>
