import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import {
  VeoliaModalComponent,
  VeoliaModalModule,
} from '@veolia.com/vds-angular-components/modal';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { DesignForm } from 'src/app/core/models/design-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Solution } from 'src/app/core/models/solution';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { EvaluationSummaryModule } from '../../../evaluation-summary/evaluation-summary.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzListModule } from 'ng-zorro-antd/list';
import { CommonModule } from '@angular/common';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCardModule } from 'ng-zorro-antd/card';
import { VeoliaChipComponent } from '@veolia.com/vds-angular-components/chips';
import { Observable, map } from 'rxjs';
import { GenAIService } from 'src/app/core/services/genai.service';
import {
  VeoliaTabComponent,
  VeoliaTabsComponent,
} from '@veolia.com/vds-angular-components/tabs';
import { NzAlertModule } from 'ng-zorro-antd/alert';
type TagColor =
  | 'error'
  | 'default'
  | 'warning'
  | 'success'
  | 'information'
  | 'purple'
  | 'orange'
  | 'lightblue';
type FormType = DesignForm | LegalForm | Article32Form | Article28Form | null;

@Component({
  standalone: true,
  selector: 'app-form-genai',
  templateUrl: './form-genai.component.html',
  styleUrls: ['./form-genai.component.scss'],
  imports: [
    CommonModule,
    EvaluationSummaryModule,
    FormsModule,
    FormlyModule,
    NzCollapseModule,
    NzDividerModule,
    NzFormModule,
    NzListModule,
    NzCardModule,
    NzRadioModule,
    ReactiveFormsModule,
    TranslateModule,
    VeoliaButtonComponent,
    VeoliaTabComponent,
    VeoliaTabsComponent,
    VeoliaModalModule,
    VeoliaChipComponent,
    NzAlertModule,
    VeoliaTagComponent,
  ],
  providers: [TranslateService],
})
export class FormGenaiComponent implements OnInit {
  @Input() form?: Article28Form | Article32Form | DesignForm | LegalForm;
  @Input() formName?:
    | 'Design Form'
    | 'Legal Form'
    | 'Article 28 Form'
    | 'Article 32 Form';
  @Input() solution?: Solution;
  @Input() canComplete?: boolean;
  @Output() refreshForm: EventEmitter<void> = new EventEmitter<void>();
  @Output() markAsGenAiEnabled: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild('genaiModal') genaiModal!: VeoliaModalComponent;

  form$: Observable<FormType>;
  @Input() genaiStatus$?: Observable<string | undefined>;
  genaiStatusColor$: Observable<TagColor> = new Observable<TagColor>();

  constructor(private genAIService: GenAIService) {
    this.form$ = this.genAIService.form$;
  }

  ngOnInit() {
    if (this.genaiStatus$) {
      this.genaiStatusColor$ = this.genaiStatus$.pipe(
        map(status => {
          if (!status) return 'default';
          if (status === 'in progress') return 'warning';
          if (status === 'completed') return 'success';
          return 'default';
        })
      );
    }
  }

  toogleGenaiModal() {
    this.genaiModal.open();
  }
}
