// genai-summary.service.ts
import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  docData,
  Firestore,
  getDoc,
  getDocs,
  query,
} from '@angular/fire/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, from, map, Observable, Subject, take } from 'rxjs';
import { DesignForm } from '../models/design-form';
import { LegalForm } from '../models/legal-form';
import { Article32Form } from '../models/article-32-form';
import { Article28Form } from '../models/article-28-form';

type FormType = DesignForm | LegalForm | Article32Form | Article28Form | null;

@Injectable({
  providedIn: 'root',
})
export class GenAIService {
  currentFormNameDatabase = '';

  private summarySubject = new Subject<string>();
  summary$ = this.summarySubject.asObservable();

  private formSubject = new BehaviorSubject<FormType>(null);
  form$ = this.formSubject.asObservable();

  constructor(
    private _firestore: Firestore,
    private firestore: AngularFirestore
  ) {}

  setFormNameDatabase(formNameDb: string) {
    this.currentFormNameDatabase = formNameDb;
  }

  get formNameDatabase() {
    return this.currentFormNameDatabase;
  }

  getGenaiResponsesForm(): string {
    switch (this.formNameDatabase) {
      case 'designForm':
        return 'genaiResponsesDesignForm';
      case 'legalForm':
        return 'genaiResponsesLegalForm';
      case 'article32Form':
        return 'genaiResponsesArticle32Form';
      case 'article28Form':
        return 'genaiResponsesArticle28Form';
      default:
        return 'error';
    }
  }

  updateSummary(summary: string) {
    this.summarySubject.next(summary);
  }

  //settings
  getGenaiSettings() {
    return from(getDocs(query(collection(this._firestore, 'genaiConfig'))));
  }

  observeGenAISettings() {
    return collectionData(
      query(collection(this._firestore, 'genaiConfig'))
    ).pipe(map(docs => (docs.length > 0 ? docs[0] : null))) as Observable<{
      enabled: boolean;
    }>;
  }

  observeSolutionGenAIStatus(id: string) {
    const solutionRef = doc(this._firestore, 'solutions', id);
    return docData(solutionRef);
  }

  updateGenaiSettings(data: any) {
    return this.firestore
      .collection('genaiConfig')
      .doc('NbSVm8edqLjz9IyVDduh')
      .update(data);
  }

  //Observable
  observeSuggestions(idSol: string) {
    const documentRef = doc(
      this._firestore,
      this.getGenaiResponsesForm(),
      idSol
    );
    return docData(documentRef);
  }

  //Observable
  getGenaiSuggestionValue(idSol: string, questionKey: string) {
    return this.firestore
      .collection(this.getGenaiResponsesForm())
      .doc(idSol)
      .valueChanges()
      .pipe(map((doc: any) => doc[questionKey] || null));
  }

  //Observable
  getGenaiSuggestionStatusValue(idSol: string, questionKey: string) {
    return this.firestore
      .collection(this.getGenaiResponsesForm())
      .doc(idSol)
      .valueChanges()
      .pipe(map((doc: any) => doc[questionKey + '_status'] || null));
  }

  //Observable
  getGenaiAnalyzedDocs(idSol: string) {
    return this.firestore
      .collection(this.formNameDatabase)
      .doc(idSol)
      .valueChanges()
      .pipe(
        take(1),
        map((doc: any) => doc?.['genaiAnalyzedDocs'] || [])
      );
  }

  updateGenaiSuggestionStatus(id: string, data: any) {
    return this.firestore
      .collection(this.getGenaiResponsesForm())
      .doc(id)
      .update(data);
  }

  updateForm(form: FormType) {
    this.formSubject.next(form);
  }
}
