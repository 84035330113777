<div nz-row [nzGutter]="16">
  <div
    nz-col
    class="gutter-row chapters"
    [nzLg]="6"
    [nzMd]="6"
    [nzSm]="6"
    [nzXs]="6">
    <veolia-button variant="primary" (click)="generatePDF()"
      >Download PDF</veolia-button
    >
  </div>
  <div
    nz-col
    class="gutter-row chapters"
    [nzLg]="18"
    [nzMd]="18"
    [nzSm]="18"
    [nzXs]="18">
    <div class="row row-space-between row-vertical-center spacing-b16">
      <div class="row">
        <veolia-link
          size="default"
          icon="check_box"
          [iconFilled]="true"
          (click)="toggleEvaluationModal()"
          >Form Evaluation Overview
        </veolia-link>
        <veolia-link
          *ngIf="comments"
          size="default"
          icon="comment"
          [iconFilled]="true"
          (click)="toggleCommentsModal()"
          >All comments
        </veolia-link>
        <veolia-link
          size="default"
          icon="attach_file"
          [iconFilled]="true"
          (click)="toggleFilesModal()"
          >All files uploaded
        </veolia-link>
      </div>
      <div>
        <div class="row row-vertical-center">
          <div *ngIf="isVendor">
            <veolia-button
              [disabled]="isSaving"
              (click)="saveAll.emit()"
              [size]="'default'"
              [variant]="'primary'"
              [htmlType]="'button'"
              [icon]="'save'">
              Save All
            </veolia-button>
          </div>
          <veolia-switch
            [disabled]="!canComplete"
            [value]="formCompletionStatus!"
            [size]="'small'"
            [label]="
              formCompletionStatus
                ? 'The form is completed'
                : 'Mark as completed'
            "
            [withContainer]="true"
            (valueChange)="markAsComplete.emit()" />
          <app-form-assessment
            *ngIf="currentForm"
            [evaluation]="evaluation"
            [form]="currentForm!"
            [formName]="formName"
            [solution]="solution"
            (refreshForm)="refreshForm.emit()" />
          <app-form-genai
            *ngIf="currentForm && isGenAIEnabled"
            [form]="currentForm!"
            [formName]="formName"
            [solution]="solution"
            [canComplete]="canComplete"
            [genaiStatus$]="genaiStatus$"
            (refreshForm)="refreshForm.emit()"
            (markAsGenAiEnabled)="markAsGenAiEnabled.emit()" />
        </div>
      </div>
    </div>
  </div>
</div>

<app-evaluation-summary
  #evaluationSummary
  [fileList]="fileList"
  [files]="files"
  [isVisible]="showEvaluationModal()"
  [evaluation]="evaluation"
  [currentForm]="currentForm"
  [formName]="formName"
  [solution]="solution"
  [businessUnit]="businessUnit"
  (closeModal)="refreshForm.emit()" />
<app-comment-summary
  #commentSummary
  [isVisible]="showCommentsModal()"
  [comments]="comments"
  [formName]="formName"
  [solution]="solution" />
<app-file-summary
  #fileSummary
  [isVisible]="showFilesModal()"
  [files]="files"
  [formName]="formName"
  [solution]="solution" />
<app-solution-info-summary
  #solutionInfoSummaryModal
  [solution]="solution"
  [formName]="formName" />
