import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AdminPromptsPageStore } from './admin-prompts-page.store';
import { Prompt } from 'src/app/core/models/prompt';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';

@Component({
  selector: 'app-admin-prompts-page',
  templateUrl: './admin-prompts-page.component.html',
  styleUrls: ['./admin-prompts-page.component.scss'],
  providers: [AdminPromptsPageStore],
})
export class AdminPromptsPageComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal!: VeoliaModalComponent;

  vm$ = this.promptStore.vm$;

  forms = ['designForm', 'legalForm', 'article32Form', 'article28Form'];

  formNameOptions = [
    { label: 'Design Form', value: 'designForm' },
    { label: 'Legal Form', value: 'legalForm' },
    { label: 'Article 32 Form', value: 'article32Form' },
    { label: 'Additional Forms', value: 'article28Form' },
  ];

  formChapterOptions: { label: string; value: string; form: string }[] = [
    {
      label: this.translateService.instant('DA_9'),
      value: 'DA_9',
      form: 'designForm',
    },
    {
      label: this.translateService.instant('DA_1'),
      value: 'DA_1',
      form: 'designForm',
    },
    {
      label: this.translateService.instant('DA_2'),
      value: 'DA_2',
      form: 'designForm',
    },
    {
      label: this.translateService.instant('DA_3'),
      value: 'DA_3',
      form: 'designForm',
    },
    {
      label: this.translateService.instant('DA_4'),
      value: 'DA_4',
      form: 'designForm',
    },
    {
      label: this.translateService.instant('DA_5'),
      value: 'DA_5',
      form: 'designForm',
    },
    {
      label: this.translateService.instant('DA_6'),
      value: 'DA_6',
      form: 'designForm',
    },
    {
      label: this.translateService.instant('DA_7'),
      value: 'DA_7',
      form: 'designForm',
    },
    {
      label: this.translateService.instant('DA_8'),
      value: 'DA_8',
      form: 'designForm',
    },
    {
      label: this.translateService.instant('LA_0'),
      value: 'LA_0',
      form: 'legalForm',
    },
    {
      label: this.translateService.instant('LA_1'),
      value: 'LA_1',
      form: 'legalForm',
    },
    {
      label: this.translateService.instant('LA_2'),
      value: 'LA_2',
      form: 'legalForm',
    },
    {
      label: this.translateService.instant('LA_3'),
      value: 'LA_3',
      form: 'legalForm',
    },
    {
      label: this.translateService.instant('LA_4'),
      value: 'LA_4',
      form: 'legalForm',
    },
    {
      label: this.translateService.instant('AR32_00'),
      value: 'AR32_00',
      form: 'article32Form',
    },
    {
      label: this.translateService.instant('AR32_0'),
      value: 'AR32_0',
      form: 'article32Form',
    },
    {
      label: this.translateService.instant('AR32_1'),
      value: 'AR32_1',
      form: 'article32Form',
    },
    {
      label: this.translateService.instant('AR32_2'),
      value: 'AR32_2',
      form: 'article32Form',
    },
    {
      label: this.translateService.instant('AR32_3'),
      value: 'AR32_3',
      form: 'article32Form',
    },
    {
      label: this.translateService.instant('AR32_4'),
      value: 'AR32_4',
      form: 'article32Form',
    },
    {
      label: this.translateService.instant('AR32_5'),
      value: 'AR32_5',
      form: 'article32Form',
    },
    {
      label: this.translateService.instant('AR32_6'),
      value: 'AR32_6',
      form: 'article32Form',
    },
    {
      label: this.translateService.instant('AR32_7'),
      value: 'AR32_7',
      form: 'article32Form',
    },
    {
      label: this.translateService.instant('AR32_8'),
      value: 'AR32_8',
      form: 'article32Form',
    },
    {
      label: this.translateService.instant('AR28_GENAI'),
      value: 'AR28_GENAI',
      form: 'article28Form',
    },
    {
      label: this.translateService.instant('AR28_TOMS'),
      value: 'AR28_TOMS',
      form: 'article28Form',
    },
    {
      label: this.translateService.instant('AR28_DATA'),
      value: 'AR28_DATA',
      form: 'article28Form',
    },
    {
      label: this.translateService.instant('AR28_VWT'),
      value: 'AR28_VWT',
      form: 'article28Form',
    },
  ];

  filteredFormChapterOptions: { label: string; value: string }[] = [];

  promptForm: FormGroup<{
    formName: FormControl<string>;
    formChapter: FormControl<string>;
    questionKey: FormControl<string>;
    content: FormControl<string>;
  }> = this.fb.group({
    formName: ['', [Validators.required]],
    formChapter: ['', [Validators.required]],
    questionKey: ['', [Validators.required]],
    content: ['', [Validators.required]],
  });

  constructor(
    private fb: NonNullableFormBuilder,
    private router: Router,
    private translateService: TranslateService,
    private promptStore: AdminPromptsPageStore
  ) {}

  ngOnInit(): void {
    this.promptForm.get('formName')?.valueChanges.subscribe(formName => {
      this.filterChapters(formName);
    });
    this.promptStore.currentIndex$.subscribe(() => {
      this.promptStore.loadPrompts();
    });
  }

  ngAfterViewInit() {
    const originalClose = this.modal.close.bind(this.modal);
    this.modal.close = () => {
      originalClose();
      this.promptForm.reset();
      this.promptStore.resetPrompt();
    };
  }

  handleIndexChange(index: number) {
    this.promptStore.setCurrentIndex(index);
  }

  onSearch(search: string) {
    this.promptStore.changeSearch(search);
  }

  filterChapters(formName: string) {
    this.filteredFormChapterOptions = this.formChapterOptions
      .filter(option => option.form === formName)
      .map(el => ({ label: el.label, value: el.value }));
  }

  addPrompt() {
    this.promptStore.resetPrompt();
    this.promptForm.controls.formName.setValue(
      this.forms[this.promptStore.getCurrentIndex()]
    );
    this.modal.open();
  }

  editPrompt(prompt: Prompt) {
    this.promptStore.setPrompt(prompt);
    this.promptForm.setValue({
      formName: prompt.formName ?? '',
      formChapter: prompt.formChapter ?? '',
      questionKey: prompt.questionKey,
      content: prompt.content,
    });
    this.modal.open();
  }

  deletePrompt(prompt: Prompt) {
    this.promptStore.setPrompt(prompt);
    this.promptStore.deletePrompt();
  }

  togglePrompt(prompt: Prompt) {
    this.promptStore.setPrompt(prompt);
    this.promptStore.togglePrompt();
  }

  submitForm(): void {
    if (this.promptForm.valid) {
      const prompt = this.promptStore.getPrompt();
      const data = this.promptForm.value as Prompt;

      if (prompt?.uid) {
        data['uid'] = prompt.uid;
      }
      this.modal.close();
      this.promptStore.setPrompt(data);
      this.promptStore.savePrompt();
      this.promptStore.resetPrompt();
    } else {
      Object.values(this.promptForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  resetForm() {
    this.modal.close();
    this.promptForm.reset();
    this.promptStore.resetPrompt();
  }

  backtoAdminHome() {
    this.router.navigate(['/admin']);
  }
}
