import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { DesignForm } from 'src/app/core/models/design-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Solution } from 'src/app/core/models/solution';
import { getTagColor } from 'src/app/core/utils/colors';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { Evaluation } from 'src/app/core/models/evalutation';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { EvaluationSummaryModule } from '../../../evaluation-summary/evaluation-summary.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzListModule } from 'ng-zorro-antd/list';
import { CommonModule } from '@angular/common';
import { NzRadioModule } from 'ng-zorro-antd/radio';

@Component({
  standalone: true,
  selector: 'app-form-assessment',
  templateUrl: './form-assessment.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    EvaluationSummaryModule,
    FormsModule,
    FormlyModule,
    NzCollapseModule,
    NzDividerModule,
    NzFormModule,
    NzListModule,
    NzRadioModule,
    ReactiveFormsModule,
    TranslateModule,
    VeoliaModalModule,
    VeoliaTagComponent,
  ],
  providers: [TranslateService],
})
export class FormAssessmentComponent implements AfterViewInit, OnInit {
  @Input() evaluation?: Evaluation;
  @Input() form?: Article28Form | Article32Form | DesignForm | LegalForm;
  @Input() formName?:
    | 'Design Form'
    | 'Legal Form'
    | 'Article 28 Form'
    | 'Article 32 Form';
  @Input() solution?: Solution;
  @Output() refreshForm: EventEmitter<void> = new EventEmitter<void>();

  evaluationStatus?: string = 'Form Assessment';
  evaluationColor?:
    | 'default'
    | 'warning'
    | 'error'
    | 'success'
    | 'information'
    | 'purple'
    | 'orange'
    | 'lightblue';

  ngOnInit(): void {
    this.evaluationStatus = this.evaluation?.status;
    this.evaluationColor = getTagColor(
      this.evaluation?.status || 'Form assessment'
    );
  }

  ngAfterViewInit(): void {
    this.evaluationStatus = this.evaluation?.status;
    this.evaluationColor = getTagColor(
      this.evaluation?.status || 'Form assessment'
    );
  }
}
