<veolia-table
  [datasource]="solutions"
  size="small"
  [showPagination]="false"
  [hideSelection]="true"
  (columnSorted)="columnSorted($event)">
  <veolia-table-column [width]="34">
    <veolia-table-header></veolia-table-header>
    <veolia-table-cell [withoutBackground]="true">
      <ng-template let-row>
        <veolia-link
          size="small"
          icon="info"
          [iconFilled]="true"
          (click)="launchAction('info', row)">
        </veolia-link>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="showId">
    <veolia-table-header>Uid</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.uid }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortFn]="isSearching ? sortBySolutionName : true"
    sortOrder="ascend"
    key="solutionName"
    [sortPriority]="1"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header> Solution </veolia-table-header>
    <veolia-table-cell *ngIf="solutionType === 0">
      <ng-template let-row>
        <a
          style="display: flex"
          nz-dropdown
          [nzDropdownMenu]="menu"
          *ngIf="row.status !== 'Pending'; else solNameSaas"
          (click)="launchForm(row, 'designForm')">
          {{ row.solutionName }}
          <veolia-icon
            [size]="16"
            [filled]="false"
            [name]="'expand_more'"></veolia-icon>
        </a>
        <ng-template #solNameSaas>
          {{ row.solutionName }}
        </ng-template>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item>
              <veolia-link size="small" (click)="launchForm(row, 'designForm')">
                Design Form
              </veolia-link>
            </li>
            <li nz-menu-item>
              <veolia-link size="small" (click)="launchForm(row, 'legalForm')">
                Legal Form
              </veolia-link>
            </li>
            <li nz-menu-item>
              <veolia-link
                size="small"
                (click)="launchForm(row, 'article32Form')">
                Article 32
              </veolia-link>
            </li>
            <li nz-menu-item>
              <veolia-link
                size="small"
                (click)="launchForm(row, 'article28Form')">
                Additional Forms
              </veolia-link>
            </li>
          </ul>
        </nz-dropdown-menu>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortFn]="isSearching ? sortByRfpName : true"
    *ngIf="solutionType === 0"
    sortOrder="ascend"
    key="rfpName"
    [sortPriority]="2"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header>RFP/Project</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.rfpName }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortFn]="isSearching ? sortByStatus : true"
    sortOrder="ascend"
    key="status"
    [sortPriority]="3"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header>DLA status</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <veolia-tag
          style="cursor: context-menu"
          nz-tooltip
          [nzTooltipTitle]="dateToolTip"
          [label]="row.status"
          [color]="getTagColor(row.status)"
          variant="bold" />
        <ng-template #dateToolTip>
          <strong
            *ngIf="row.status !== 'Canceled' && row.fullyEvaluated === true"
            style="margin-left: 3px">
            {{ getFormattedDate(row.finalEvaluationDate) }}
          </strong>
          <strong *ngIf="row.status === 'Canceled'" style="margin-left: 3px">
            {{ getFormattedDate(row.cancellationDate) }}
          </strong>
        </ng-template>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortFn]="isSearching ? sortByFinalEvaluationDate : true"
    sortOrder="ascend"
    key="finalEvaluationDate"
    [sortPriority]="4"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header>DLA status date</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <strong style="margin-left: 3px">
          {{ getFormattedDate(row.finalEvaluationDate) }}
        </strong>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    *ngIf="solutionType === 0"
    [sortFn]="isSearching ? sortByDesignStatus : true"
    sortOrder="ascend"
    key="designStatus"
    [sortPriority]="5"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header>Design</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <veolia-tag
          [label]="row.designStatus"
          [color]="getTagColor(row.designStatus)"
          variant="bold" />
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    *ngIf="solutionType === 0"
    [sortFn]="isSearching ? sortByLegalStatus : true"
    sortOrder="ascend"
    key="legalStatus"
    [sortPriority]="6"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header>Legal</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <veolia-tag
          [label]="row.legalStatus"
          [color]="getTagColor(row.legalStatus)"
          variant="bold" />
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    *ngIf="solutionType === 0"
    [sortFn]="isSearching ? sortByArticle32Status : true"
    sortOrder="ascend"
    key="article32Status"
    [sortPriority]="7"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header>Art 32</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <veolia-tag
          [label]="row.article32Status"
          [color]="getTagColor(row.article32Status)"
          variant="bold" />
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortFn]="isSearching ? sortByVendorSsc : true"
    sortOrder="ascend"
    key="editorBrand"
    [sortPriority]="8">
    <veolia-table-header> Vendor SSC </veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <app-security-score-card [solution]="row" />
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortFn]="isSearching ? sortByRequestDate : true"
    sortOrder="ascend"
    key="requestDate"
    [sortPriority]="9"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header>Request date</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ getFormattedDate(row.requestDate) }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortFn]="isSearching ? sortByLastUpdateDate : true"
    sortOrder="ascend"
    key="lastUpdate"
    [sortPriority]="10"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header>Last update</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        {{ getFormattedDate(row.lastUpdate) }}
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortFn]="isSearching ? sortBySolutionOwner : true"
    sortOrder="ascend"
    key="solutionOwner"
    [sortPriority]="11"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header>Solution owner</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.solutionOwner }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortFn]="isSearching ? sortByBusinessUnit : true"
    sortOrder="ascend"
    key="businessUnitOfSolution"
    [sortPriority]="12"
    [sortDirections]="['ascend', 'descend']">
    <veolia-table-header>Business Unit</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.businessUnitOfSolution }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column fixedRight [width]="34" *ngIf="actions.length">
    <veolia-table-header></veolia-table-header>
    <veolia-table-cell [withoutBackground]="true">
      <ng-template let-row>
        <a nz-dropdown [nzDropdownMenu]="menu">
          <span nz-icon nzType="more"></span>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item>
              <veolia-link
                *ngIf="hasAction('edit')"
                size="small"
                icon="edit"
                (click)="launchAction('edit', row)">
                Edit
              </veolia-link>
            </li>
            <li
              nz-menu-item
              *ngIf="row.status !== 'Pending' && row.status !== 'Canceled'">
              <veolia-link
                *ngIf="hasAction('share')"
                size="small"
                icon="share"
                (click)="launchAction('share', row)">
                Share
              </veolia-link>
            </li>
            <li nz-menu-item *ngIf="row.status !== 'Pending'">
              <veolia-link
                *ngIf="hasAction('summary')"
                size="small"
                icon="event_available"
                (click)="launchAction('summary', row)">
                Summary
              </veolia-link>
            </li>
            <li nz-menu-item *ngIf="row.status !== 'Pending'">
              <veolia-link
                *ngIf="hasAction('logs')"
                size="small"
                icon="list_alt"
                (click)="launchAction('logs', row)">
                Logs
              </veolia-link>
            </li>
            <li nz-menu-item *ngIf="row.status !== 'Pending'">
              <veolia-link
                *ngIf="hasAction('access')"
                size="small"
                icon="group"
                (click)="launchAction('access', row)">
                Access rights
              </veolia-link>
            </li>
            <li
              nz-menu-item
              *ngIf="row.status !== 'Pending' && row.status !== 'Canceled'">
              <veolia-link
                *ngIf="hasAction('cancel')"
                size="small"
                icon="disabled_by_default"
                (click)="launchCancelRequestAction(row)">
                Abort request
              </veolia-link>
            </li>
            <li
              nz-menu-item
              *ngIf="row.status !== 'Pending' && row.status === 'Canceled'">
              <veolia-link
                *ngIf="hasAction('restore')"
                size="small"
                icon="restore_page"
                (click)="launchUncancelRequestAction(row)">
                Restore request
              </veolia-link>
            </li>
            <li nz-menu-item *ngIf="row.status === 'Pending'">
              <veolia-link
                *ngIf="hasAction('validate')"
                size="small"
                icon="verified"
                (click)="launchValidateRequestModal(row)">
                Validate request
              </veolia-link>
            </li>
            <li nz-menu-item *ngIf="row.link">
              <veolia-link
                size="small"
                icon="refresh"
                (click)="refreshSsc(row)">
                Refresh SCC
              </veolia-link>
            </li>
          </ul>
        </nz-dropdown-menu>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>
</veolia-table>

<app-access-rights #accessRightsModal />

<app-solution-share #solutionShareModal />

<app-solution-info-summary #solutionInfoSummaryModal />

<app-validate-solution #validateSolutionModal />

<veolia-modal #cancelSolModal>
  <div veolia-modal-title>
    <h4 nz-typography>
      {{ 'Cancel the solution ' | translate }}
      <strong>{{ this.solutionName }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ this.solutionId }}</em></span
      >
    </h4>
  </div>
  <div veolia-modal-content>
    <form
      nz-form
      [nzLayout]="'vertical'"
      [formGroup]="cancelSolutionForm!"
      (ngSubmit)="submitCancelSolutionForm()">
      <div class="form">
        <nz-form-item>
          <nz-form-label nzRequired>Comment</nz-form-label>
          <nz-form-control
            nzErrorTip="Please add a comment to cancel the solution">
            <input
              nz-input
              class="light-gray"
              formControlName="cancellationComment" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div>
        <div class="row row-center">
          <button
            [disabled]="!cancelSolutionForm.valid"
            type="submit"
            nz-button
            nzType="primary">
            Abort request
          </button>
        </div>
      </div>
    </form>
  </div>
</veolia-modal>

<veolia-modal #restoreSolModal>
  <div veolia-modal-title>
    <h4 nz-typography>
      {{ 'Restore the solution ' | translate }}
      <strong>{{ this.solutionName }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ this.solutionId }}</em></span
      >
    </h4>
  </div>
  <div veolia-modal-content>
    <form
      nz-form
      [nzLayout]="'vertical'"
      [formGroup]="restoreSolutionForm!"
      (ngSubmit)="submitRestoreSolutionForm()">
      <div class="form">
        <nz-form-item>
          <nz-form-label nzRequired>Comment</nz-form-label>
          <nz-form-control
            nzErrorTip="Please add a comment to restore the solution">
            <input
              nz-input
              class="light-gray"
              formControlName="restoreComment" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div>
        <div class="row row-center">
          <button
            [disabled]="!restoreSolutionForm.valid"
            type="submit"
            nz-button
            nzType="primary">
            Restore request
          </button>
        </div>
      </div>
    </form>
  </div>
</veolia-modal>
