import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  Database,
  child,
  push,
  ref as refDatabase,
  set as setDatabase,
} from '@angular/fire/database';
import {
  Storage,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { uploadBytes } from 'firebase/storage';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { FileApi } from 'src/app/core/apis/file.api';
import { AuthService } from 'src/app/core/services/auth.service';
import { LogService } from 'src/app/core/services/log.service';
import { Solution } from 'src/app/core/models/solution';
import { SolutionService } from 'src/app/core/services/solution.service';

@Component({
  selector: 'app-form-file-upload',
  templateUrl: './form-file-upload.component.html',
  styleUrls: ['./form-file-upload.component.scss'],
})
export class FormFileUploadComponent implements OnInit {
  @Input() file?: File;
  @Input() fieldId?: string;
  @Input() formName?: string;
  @Input() solutionId?: string;
  @Output() reloadFiles: EventEmitter<number> = new EventEmitter();

  solution?: Solution;
  progress = 0;
  error = '';
  hasError = false;

  formNameRoute?: string;

  public _auth: Auth = inject(Auth);
  public authService: AuthService = inject(AuthService);
  private database: Database = inject(Database);
  private storage: Storage = inject(Storage);
  private logService: LogService = inject(LogService);
  private fileApi: FileApi = inject(FileApi);

  constructor(
    private messageService: VeoliaMessageService,
    private solutionService: SolutionService
  ) {}

  ngOnInit(): void {
    this.uploadFile();
    if (this.formName === 'Design Form') {
      this.formNameRoute = 'designForm';
    }
    if (this.formName === 'Legal Form') {
      this.formNameRoute = 'legalForm';
    }
    if (this.formName === 'Article 32 Form') {
      this.formNameRoute = 'article32Form';
    }
    if (this.formName === 'Article 28 Form') {
      this.formNameRoute = 'article28Form';
    }
    this.fetchSolution();
  }

  fetchSolution() {
    if (this.solutionId) {
      this.solutionService.get(this.solutionId).then(doc => {
        if (doc.exists()) {
          this.solution = doc.data() as Solution;
          //this.solutionSignal.set(this.solution);
        }
      });
    }
  }

  uploadFile() {
    if (this.file) {
      const metadata = {
        contentType: this.file?.type,
      };
      const path = `/files/${this.formName}/${this.solutionId}/${this.fieldId}/${this.file?.name}`;
      const storageRef = ref(this.storage, path);
      uploadBytes(storageRef, this.file, metadata)
        .then(snapshot => {
          this.progress = 90;
          getDownloadURL(snapshot.ref).then(downloadURL => {
            const postedAt = new Date(Date.now()).toString();
            getMetadata(storageRef).then(metadata => {
              const uploadedFile = {
                contentType: metadata?.contentType,
                formIs: this.formName,
                idRef: `${this.solutionId}-${this.fieldId}`,
                idSolution: this.solutionId,
                name: this.file?.name,
                url: downloadURL,
                postedAt: postedAt,
                postedBy: this._auth.currentUser?.email,
                reference: this.fieldId,
                solutionName: this.solution?.solutionName,
                userId: this._auth.currentUser?.uid,
              };

              const databasePath = `/files/${this.formName}/${this.solutionId}/${this.fieldId}`;
              const newFileKey = push(
                child(refDatabase(this.database), databasePath)
              ).key;

              setDatabase(
                refDatabase(this.database, `${databasePath}/${newFileKey}`),
                uploadedFile
              );
              this.progress = this.progress + 10;

              this.logService.setLogForAction(
                'File',
                this.solutionId!,
                this.formName!,
                {
                  action: 'File uploaded',
                  chapter: this.fieldId,
                  fileName: this.file?.name,
                  roleUser: this.authService.userRole,
                }
              );
              this.reloadFiles.emit();

              this.messageService.create(
                {
                  icon: 'check',
                  title: 'Success',
                  content: 'File uploaded !',
                  type: 'sucess',
                },
                { duration: 5000 }
              );
            });
          });
        })
        .catch(error => {
          console.log(error);
          this.progress = 100;
          this.hasError = true;
          this.error = 'Oups! An error occured during upload';
        });
    }
  }

  removeErrorfile() {
    this.reloadFiles.emit();
  }
}
