import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'src/app/core/guards/admin.guard';

import { BaseLayoutComponent } from '../../shared/features/layout/components/base-layout/base-layout.component';
import { NgModule } from '@angular/core';

import { AdminHomePageComponent } from './pages/admin-home-page/admin-home-page.component';
import { AdminSolutionsPageComponent } from './pages/admin-solutions-page/admin-solutions-page.component';
import { AdminBusinessUnitsPageComponent } from './pages/admin-business-units-page/admin-business-units-page.component';
import { AdminUsersPageComponent } from './pages/admin-users-page/admin-users-page.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { AdminPromptsPageComponent } from './pages/admin-prompts-page/admin-prompts-page.component';

const routes: Routes = [
  {
    path: 'admin',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard, AdminGuard],
    children: [
      {
        path: '',
        component: AdminHomePageComponent,
      },
      {
        path: 'solutions',
        component: AdminSolutionsPageComponent,
      },
      {
        path: 'business-units',
        component: AdminBusinessUnitsPageComponent,
      },
      {
        path: 'users',
        component: AdminUsersPageComponent,
      },
      {
        path: 'prompts',
        component: AdminPromptsPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
