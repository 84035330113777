<app-layout-page-header>
  <div class="row">
    <app-go-back />
    <app-layout-page-header-title
      title="{{ selectedSolution?.type }} Solution {{
        selectedSolution?.solutionName
      }}, editor ({{ selectedSolution?.editorBrand }}){{
        selectedSolution?.rfpName
          ? ', RFP (' + selectedSolution?.rfpName + ')'
          : ''
      }}"
      subtitle="Last update of the form: {{
        formDataUpdated?.lastUpdate?.toDate
          ? (formDataUpdated?.lastUpdate!.toDate() | date: 'MMMM d, y, h:mm a')
          : 'The vendor did not begin to complete the form'
      }}"
      subtitle_two="Last update by: {{
        formDataUpdated?.lastUpdateBy
          ? formDataUpdated?.lastUpdateBy
          : 'The vendor did not begin to complete the form'
      }}"
      subtitle_three="Current form status: {{
        formDataUpdated?.status
          ? formDataUpdated?.status
          : 'The vendor did not begin to complete the form'
      }}"></app-layout-page-header-title>
    <div class="row">
      <veolia-link
        *ngIf="
          selectedSolution?.status !== 'Canceled' &&
          selectedSolution?.status !== 'Pending' &&
          hasFullRights
        "
        size="small"
        icon="edit"
        (click)="launchAction('edit', selectedSolution!)">
        Edit
      </veolia-link>
      <veolia-link
        size="small"
        icon="event_available"
        (click)="launchAction('summary', selectedSolution!)">
        DLA Evaluation Overview
      </veolia-link>
      <veolia-link
        *ngIf="
          selectedSolution?.status !== 'Canceled' &&
          selectedSolution?.status !== 'Pending' &&
          canShare
        "
        size="small"
        icon="share"
        (click)="launchAction('share', selectedSolution!)">
        Share
      </veolia-link>
      <veolia-link
        size="small"
        icon="group"
        (click)="launchAction('access', selectedSolution!)">
        Access rights
      </veolia-link>
      <veolia-link
        size="small"
        icon="list_alt"
        (click)="launchAction('logs', selectedSolution!)">
        Logs
      </veolia-link>
      <veolia-link
        size="small"
        icon="help"
        (click)="launchAction('help', selectedSolution!)">
        Help
      </veolia-link>
    </div>
    <app-document-presence
      [documentId]="selectedSolution?.uid"
      [formName]="currentFormName" />
  </div>
  <div style="width: auto; float: right" class="buttons row end">
    <veolia-button
      [size]="'default'"
      [variant]="'primary'"
      [htmlType]="'button'"
      [icon]="'info'"
      (click)="launchAction('info', selectedSolution!)">
      About this request
    </veolia-button>
    <app-form-caption></app-form-caption>
    <app-documentation></app-documentation>
  </div>
</app-layout-page-header>
<div class="layout-body">
  <div class="layout-body-content spacing-b16">
    <veolia-tabs #formTabs (tabChanged)="formChanged($event)">
      <veolia-tab
        label="Design Form"
        [disabled]="formName !== 'designForm' && isDirty === true">
        <app-design-form
          *ngIf="formName === 'designForm'"
          [businessUnit]="selectedBusinessUnit"
          [solution]="selectedSolution"
          [solutionId]="selectedSolution?.uid!"
          (updateFormDate)="formDateUpdate($event)"
          (notifyDirty)="notifiedDirty($event)" />
      </veolia-tab>
      <veolia-tab
        label="Legal Form"
        [disabled]="formName !== 'legalForm' && isDirty === true">
        <app-legal-form
          *ngIf="formName === 'legalForm'"
          [businessUnit]="selectedBusinessUnit"
          [solution]="selectedSolution"
          [solutionId]="selectedSolution?.uid!"
          (updateFormDate)="formDateUpdate($event)"
          (notifyDirty)="notifiedDirty($event)" />
      </veolia-tab>
      <veolia-tab
        label="Article 32 Form"
        [disabled]="formName !== 'article32Form' && isDirty === true">
        <app-article-32-form
          *ngIf="formName === 'article32Form'"
          [businessUnit]="selectedBusinessUnit"
          [solution]="selectedSolution"
          [solutionId]="selectedSolution?.uid!"
          (updateFormDate)="formDateUpdate($event)"
          (notifyDirty)="notifiedDirty($event)" />
      </veolia-tab>
      <veolia-tab
        label="Additional Forms"
        [disabled]="formName !== 'article28Form' && isDirty === true">
        <app-article-28-form
          *ngIf="formName === 'article28Form'"
          [businessUnit]="selectedBusinessUnit"
          [solution]="selectedSolution"
          [solutionId]="selectedSolution?.uid!"
          (updateFormDate)="formDateUpdate($event)"
          (notifyDirty)="notifiedDirty($event)" />
      </veolia-tab>
    </veolia-tabs>
  </div>
</div>

<app-access-rights #accessRightsModal [businessUnit]="selectedBusinessUnit" />

<app-solution-share #solutionShareModal />

<app-contact-form #contactFormModal />

<app-solution-info-summary #solutionInfoSummaryModal />
