import { Injectable } from '@angular/core';
import { BaseApi } from './api';
import { Observable } from 'rxjs';

@Injectable()
class GenaiApi extends BaseApi {
  summarizeDocument(id: string, data: any): Observable<any> {
    return this.post(`/api/genai/${id}/summarize_file`, data);
  }

  triggerGenai(id: string, data: any) {
    return this.post(`api/genai/${id}/trigger_genai`, data);
  }
}

export default GenaiApi;
