<div class="spacing-b16">
  <h4 style="text-align: center" [ngClass]="getFormStatusTheme(status!)">
    {{ title! | translate }}
  </h4>
  <table class="table">
    <thead>
      <tr>
        <th>Status</th>
        <th>Comment</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="center">
          <veolia-tag
            *ngIf="status"
            [label]="status!"
            [color]="getFormStatusTheme(status! || 'DLA Assessment')" />
        </td>
        <td class="center" nz-typography>
          <span nz-typography nzCopyable [nzContent]="comment || 'N/A'">
            <code>{{ comment || 'not defined' }}</code>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <nz-divider></nz-divider>
</div>
