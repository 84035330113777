import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FieldWrapper,
  FormlyFieldConfig,
  FormlyFieldProps as CoreFormlyFieldProps,
  FormlyModule,
} from '@ngx-formly/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { Observable } from 'rxjs';
import { GenAIService } from 'src/app/core/services/genai.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { GenAIStore } from 'src/app/core/stores/gen-ai.store';

export interface FormlyFieldProps extends CoreFormlyFieldProps {
  genaiSuggestion?: Observable<string | null>;
  genaiSuggestionStatus?: Observable<string | null>;
  genaiSuggestionQuestionKey?: string | null;
  solutionId?: string | null;
}

@Component({
  standalone: true,
  selector: 'app-form-genai-suggestion-wrapper-field',
  template: `
    <nz-form-item>
      <nz-form-label [nzRequired]="props.required" [nzFor]="id">
        {{ props.label }}
      </nz-form-label>
      <nz-form-control>
        <ng-container #fieldComponent></ng-container>
        <ng-container *ngIf="vm$ | async as vm">
          <ng-container *ngIf="vm.genAIEnabled && vm.formGenAIEnabled">
            <nz-collapse
              *ngIf="suggestionStatus | async"
              [ngStyle]="{
                'background-color':
                  (suggestionStatus | async) === 'true'
                    ? '#d9edc0'
                    : (suggestionStatus | async) === 'false'
                      ? '#fff2f0'
                      : '#e6f7ff'
              }">
              <nz-collapse-panel nzHeader="Show genAI response">
                <nz-alert
                  nzShowIcon
                  [nzType]="
                    !(suggestionStatus | async)
                      ? 'info'
                      : (suggestionStatus | async) === 'true'
                        ? 'success'
                        : 'error'
                  "
                  [nzDescription]="suggestion | async"
                  [nzAction]="actionButtons">
                  >
                </nz-alert>
                <ng-template #actionButtons>
                  <veolia-tag
                    nz-tooltip="Validate"
                    style="cursor: pointer"
                    [color]="'success'"
                    variant="light"
                    [icon]="'check_circle'"
                    (click)="validateGenaiSuggestion()">
                  </veolia-tag>
                  <veolia-tag
                    nz-tooltip="Unvalidate"
                    style="cursor: pointer"
                    [color]="'error'"
                    variant="light"
                    [icon]="'disabled_by_default'"
                    (click)="
                      unvalidateGenaiSuggestion(
                        this.field.props!.genaiSuggestionQuestionKey
                      )
                    ">
                  </veolia-tag>
                  <veolia-tag
                    *ngIf="props.genaiSuggestion | async as text"
                    nz-tooltip="Copy to clipboard"
                    style="cursor: pointer"
                    [color]="'default'"
                    variant="light"
                    [icon]="'add_circle'"
                    (click)="copy(text)">
                  </veolia-tag>
                </ng-template>
              </nz-collapse-panel>
            </nz-collapse>
          </ng-container>
        </ng-container>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      //empty
    `,
  ],
  imports: [
    CommonModule,
    FormlyModule,
    NzFormModule,
    NzTypographyModule,
    NzToolTipModule,
    NzAlertModule,
    NzCollapseModule,
    VeoliaTagComponent,
  ],
})
export class FormWrapperGenaiSuggestionFieldComponent extends FieldWrapper<
  FormlyFieldConfig<FormlyFieldProps>
> {
  vm$ = this.genAIStore.vm$;

  constructor(
    private clipboard: Clipboard,
    private genAIStore: GenAIStore,
    private genaiService: GenAIService,
    private veoliaMessageService: VeoliaMessageService
  ) {
    super();
  }

  suggestion = this.genAIStore.select(
    state => state.data[this.field.key as string]
  );

  suggestionStatus = this.genAIStore.select(
    state => state.data[(this.field.key as string) + '_status']
  );

  validateGenaiSuggestion() {
    const questionKeyDb = (this.field.key as string) + '_status';
    const updatedData = {
      [questionKeyDb]: 'true',
    };

    this.genaiService
      .updateGenaiSuggestionStatus(this.field.props!.solutionId!, updatedData)
      .then(() => {
        //empty
      })
      .catch(error => {
        console.error('Error updating settings:', error);
      });
  }

  unvalidateGenaiSuggestion(questionKey: any) {
    console.log('questionKey', questionKey);
    console.log('this.solution!.uid', this.field.props!.solutionId!);
    const questionKeyDb = (this.field.key as string) + '_status';
    const updatedData = {
      [questionKeyDb]: 'false',
    };

    this.genaiService
      .updateGenaiSuggestionStatus(this.field.props!.solutionId!, updatedData)
      .then(() => {
        //empty
      })
      .catch(error => {
        console.error('Error updating settings:', error);
      });
  }

  copy(text: string | null): void {
    if (text) {
      this.clipboard.copy(text);
      this.veoliaMessageService.create(
        {
          title: 'copied !',
          icon: 'check',
        },
        {
          duration: 1000,
        }
      );
    } else {
      console.error('No text to copy');
    }
  }
}
