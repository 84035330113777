import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import {
  provideFirestore,
  getFirestore,
  connectFirestoreEmulator,
} from '@angular/fire/firestore';
import {
  connectDatabaseEmulator,
  getDatabase,
  provideDatabase,
} from '@angular/fire/database';
import {
  connectStorageEmulator,
  getStorage,
  provideStorage,
} from '@angular/fire/storage';

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthService } from './core/services/auth.service';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { provideAnimations } from '@angular/platform-browser/animations';

import { FIREBASE_OPTIONS } from '@angular/fire/compat';

import { environment } from '../environments/environment';
import { UserService } from './core/services/user.service';
import { bearerTokenInterceptor } from './core/interceptors/token-interceptor';
import UserApi from './core/apis/user.api';
import { AuthenticationModule } from './features/authentication/authentication.module';
import { HomeModule } from './features/home/home.module';
import { AdminModule } from './features/admin/admin.module';
import { SolutionsModule } from './features/solutions/solutions.module';
import { BusinessUnitEvaluatorModule } from './features/evaluator/business-unit-evaluator.module';
import { DemandsModule } from './features/demands/demands.module';
import { CommentApi } from './core/apis/comment.api';
import { FileApi } from './core/apis/file.api';

import { TruncatePipe } from './core/pipes/truncate.pipe';
import { Idle, NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TabService } from './core/services/tab.service';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import GenaiApi from './core/apis/genai.api';

registerLocaleData(en);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://127.0.0.1:9099', {
          disableWarnings: true,
        });
      }
      return auth;
    }),
    provideDatabase(() => {
      const database = getDatabase();
      if (environment.useEmulators) {
        connectDatabaseEmulator(database, '127.0.0.1', 9000);
      }
      return database;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.useEmulators) {
        connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
      }
      return firestore;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        connectStorageEmulator(storage, '127.0.0.1', 5001);
      }
      return storage;
    }),
    AuthenticationModule,
    HomeModule,
    AdminModule,
    SolutionsModule,
    BusinessUnitEvaluatorModule,
    AppRoutingModule,
    NgIdleModule.forRoot(),
    VeoliaModalModule,
    VeoliaButtonComponent,
    DemandsModule,
  ],
  providers: [
    provideNzI18n(en_US),
    provideAnimations(),
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    provideHttpClient(withInterceptors([bearerTokenInterceptor])),
    AuthService,
    TabService,
    UserService,
    UserApi,
    CommentApi,
    FileApi,
    GenaiApi,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
