import { NgModule } from '@angular/core';
import { SolutionInfoSummaryComponent } from './components/solution-info-summary.component';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { TranslateModule } from '@ngx-translate/core';
import { NzCardModule } from 'ng-zorro-antd/card';
import { CommonModule } from '@angular/common';
import { VeoliaChipComponent } from '@veolia.com/vds-angular-components/chips';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { VeoliaLinkComponent } from '@veolia.com/vds-angular-components/link';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';

@NgModule({
  declarations: [SolutionInfoSummaryComponent],
  imports: [
    CommonModule,
    NzCardModule,
    NzDividerModule,
    TranslateModule,
    VeoliaChipComponent,
    VeoliaModalModule,
    VeoliaButtonComponent,
    VeoliaLinkComponent,
    NzIconModule,
    VeoliaIconComponent,
  ],
  exports: [SolutionInfoSummaryComponent],
})
export class SolutionInfoSummaryModule {}
