import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { CaptionModule } from 'src/app/shared/features/caption/caption.module';
import { DocumentationModule } from 'src/app/shared/features/documentation/documentation.module';
import { LayoutModule } from 'src/app/shared/features/layout/layout.module';
import { NavbarModule } from 'src/app/shared/features/navbar/navbar.module';
import { PaginationModule } from 'src/app/shared/features/pagination/pagination.module';
import { SearchInputModule } from 'src/app/shared/features/search-input/search-input.module';
import { AdminRoutingModule } from './admin-routing.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ReactiveFormsModule } from '@angular/forms';
import { SimpleSpinnerModule } from 'src/app/shared/features/simple-spinner/simple-spinner.module';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCardModule } from 'ng-zorro-antd/card';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AdminHomePageComponent } from './pages/admin-home-page/admin-home-page.component';
import { AdminSolutionsPageComponent } from './pages/admin-solutions-page/admin-solutions-page.component';
import { AdminBusinessUnitsPageComponent } from './pages/admin-business-units-page/admin-business-units-page.component';
import { AdminUsersPageComponent } from './pages/admin-users-page/admin-users-page.component';

import { AllSolutionsComponent } from './components/solutions/all-solutions/all-solutions.component';
import { AllEvaluatedSolutionsComponent } from './components/solutions/all-evaluated-solutions/all-evaluated-solutions.component';
import { AllPendingSolutionsComponent } from './components/solutions/all-pending-solutions/all-pending-solutions.component';

import { AllUsersComponent } from './components/users/all-users/all-users.component';
import { DataTableModule } from 'src/app/shared/features/data-table/data-table.module';
import { VeoliaSegmentedComponent } from '@veolia.com/vds-angular-components/segmented';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { VeoliaPageHeaderComponent } from '@veolia.com/vds-angular-components/page-header';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import {
  VeoliaTableComponent,
  VeoliaTableColumnComponent,
  VeoliaTableHeaderComponent,
  VeoliaTableCellComponent,
  VeoliaTableActionComponent,
} from '@veolia.com/vds-angular-components/table';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { VeoliaLinkComponent } from '@veolia.com/vds-angular-components/link';
import { VeoliaSwitchComponent } from '@veolia.com/vds-angular-components/switch';

import { UserSummaryModule } from 'src/app/shared/features/user-summary/user-summary.module';
import { UserManagementModule } from 'src/app/shared/features/user-management/user-management.module';
import { SolutionsTableComponent } from './components/solutions-table/solutions-table.component';
import { UsersTableComponent } from './components/users-table/users-table.component';
import { ValidateSolutionComponent } from './components/solutions/validate-solution/validate-solution.component';
import { AccessRightsModule } from 'src/app/shared/features/access-rights/access-rights.module';
import { AllEvaluatedSolutionsStore } from './components/solutions/all-evaluated-solutions/all-evaluated-solutions.component.store';
import { AllSolutionsStore } from './components/solutions/all-solutions/all-solutions.component.store';
import { AllPendingSolutionsStore } from './components/solutions/all-pending-solutions/all-pending-solutions.component.store';
import { SolutionShareComponent } from 'src/app/shared/features/solution-share/components/solution-share.component';
import { SecurityScoreCardComponent } from 'src/app/shared/features/security-score-card/security-score-card.component';
import { SecurityScoreCardApi } from 'src/app/core/apis/security-score-card.api';
import { SecurityScoreCardService } from 'src/app/core/services/security-score-card.service';
import { ExportCSVButtonComponent } from '../../shared/features/export-csv-button/export-csv-button.component';
import { SolutionInfoSummaryModule } from 'src/app/shared/features/solution-info-summary/solution-info-summary.module';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { GenaiSettingsComponent } from 'src/app/shared/features/genai-settings/components/genai-settings/genai-settings.component';
import { AdminPromptsPageComponent } from './pages/admin-prompts-page/admin-prompts-page.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { FormLayoutModule } from 'src/app/shared/features/form-layout/form-layout.module';
import { VeoliaSelectComponent } from '@veolia.com/vds-angular-components/select';
import {
  VeoliaInputComponent,
  VeoliaTextAreaComponent,
} from '@veolia.com/vds-angular-components/input';
import { AdminPromptsPageStore } from './pages/admin-prompts-page/admin-prompts-page.store';
import {
  PromptArticle28FormService,
  PromptArticle32FormService,
  PromptDesignFormService,
  PromptLegalFormService,
} from 'src/app/core/services/prompt.service';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NgChartsModule } from 'ng2-charts';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { GenaiSettingsModule } from 'src/app/shared/features/genai-settings/genai-settings.module';

@NgModule({
  declarations: [
    AdminHomePageComponent,
    SolutionsTableComponent,
    UsersTableComponent,
    AdminSolutionsPageComponent,
    AdminBusinessUnitsPageComponent,
    AdminUsersPageComponent,
    AllSolutionsComponent,
    AllEvaluatedSolutionsComponent,
    AllPendingSolutionsComponent,
    AllUsersComponent,
    ValidateSolutionComponent,
    AdminPromptsPageComponent,
  ],
  imports: [
    CommonModule,
    VeoliaModalModule,
    ReactiveFormsModule,
    NzAlertModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzListModule,
    NzMenuModule,
    NzModalModule,
    NzPaginationModule,
    NzSelectModule,
    NzBadgeModule,
    NzSkeletonModule,
    NzStatisticModule,
    NzTableModule,
    NzTabsModule,
    NzToolTipModule,
    NzTypographyModule,
    NzCardModule,
    NzPopconfirmModule,
    LayoutModule,
    NavbarModule,
    PaginationModule,
    CaptionModule,
    DocumentationModule,
    SearchInputModule,
    SimpleSpinnerModule,
    DataTableModule,
    VeoliaButtonComponent,
    VeoliaInputComponent,
    VeoliaPageHeaderComponent,
    VeoliaSegmentedComponent,
    VeoliaLinkComponent,
    VeoliaSelectComponent,
    VeoliaSwitchComponent,
    VeoliaTableComponent,
    VeoliaTableColumnComponent,
    VeoliaTableHeaderComponent,
    VeoliaTableColumnComponent,
    VeoliaTableCellComponent,
    VeoliaTableActionComponent,
    VeoliaTagComponent,
    VeoliaTextAreaComponent,
    VeoliaIconComponent,
    AdminRoutingModule,
    UserSummaryModule,
    UserManagementModule,
    TranslateModule,
    AccessRightsModule,
    SolutionShareComponent,
    SecurityScoreCardComponent,
    ExportCSVButtonComponent,
    SolutionInfoSummaryModule,
    FormLayoutModule,
    FormlyNgZorroAntdModule,
    FormlyModule.forChild(),
    NgChartsModule,
    GenaiSettingsModule,
  ],
  providers: [
    TranslateService,
    AllEvaluatedSolutionsStore,
    AllSolutionsStore,
    AllPendingSolutionsStore,
    SecurityScoreCardApi,
    SecurityScoreCardService,
    AdminPromptsPageStore,
    PromptDesignFormService,
    PromptLegalFormService,
    PromptArticle32FormService,
    PromptArticle28FormService,
  ],
})
export class AdminModule {}
