<div class="layout-body">
  <div class="layout-body-content">
    <div id="first_connection_page">
      <div>
        <h3 nz-typography>Your personal data</h3>
      </div>
      <div>
        <form
          nz-form
          [nzLayout]="'vertical'"
          [formGroup]="
            isVeolia ? firstConnectionVeoliaForm : firstConnectionExternalForm
          "
          (ngSubmit)="isVeolia ? onSubmitVeolia() : onSubmitExternal()">
          <div *ngIf="vm$ | async as vm">
            <div *ngIf="isVeolia">
              <nz-form-item>
                <nz-form-label>Your country*</nz-form-label>
                <nz-form-control>
                  <nz-select
                    required
                    class="light-gray"
                    nzPlaceHolder="Choose your country"
                    formControlName="country">
                    <nz-option
                      *ngFor="let country of vm.countries"
                      [nzValue]="country.value"
                      [nzLabel]="country.label"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>Your Business Unit*</nz-form-label>
                <nz-form-control>
                  <nz-select
                    required
                    class="light-gray"
                    nzPlaceHolder="Choose you business unit"
                    formControlName="businessUnit">
                    <nz-option
                      *ngFor="let bussinessUnit of vm.businessUnits"
                      [nzValue]="bussinessUnit.value"
                      [nzLabel]="bussinessUnit.label"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>Your current role</nz-form-label>
                <nz-form-control nzErrorTip="Please input your role!">
                  <input
                    nz-input
                    class="light-gray"
                    formControlName="role"
                    placeholder="Role" />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>Your firstname and lastname</nz-form-label>
                <nz-form-control nzErrorTip="Please input your display name!">
                  <input
                    nz-input
                    class="light-gray"
                    formControlName="displayName"
                    placeholder="Display Name" />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>Your email</nz-form-label>
                <nz-form-control nzErrorTip="Please input your email address!">
                  <input
                    nz-input
                    class="light-gray"
                    formControlName="email"
                    placeholder="E-mail" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div>
            <p translate>cgu_text1</p>
            <ul>
              <li translate>pro_email</li>
              <li translate>first_and_lastname</li>
              <li translate>google_picture</li>
              <li translate>bu</li>
              <li translate>comments</li>
              <li translate>files</li>
              <li translate>logs</li>
            </ul>
            <p translate>cgu_text2</p>
            <p translate>cgu_text3</p>
            <br />

            <p translate>cgu_text4</p>
            <br />

            <p translate>cgu_text5</p>
            <br />

            <p translate>cgu_text6</p>
            <br />

            <p translate>cgu_text7</p>
            <br />

            <p translate>cgu_text8</p>
            <br />

            <p translate>cgu_text9</p>
            <br />

            <p translate>cgu_text10</p>
            <br />

            <p translate>cgu_text11</p>
            <br />

            <p translate>cgu_text12</p>
            <br />

            <p translate>cgu_text13</p>
            <br />

            <p translate>cgu_text14</p>
            <br />

            <p translate>cgu_text15</p>

            Do you agree with terms ?
          </div>
          <nz-form-item>
            <nz-form-control
              nzValidateStatus="error"
              nzHasFeedback
              [nzErrorTip]="terms_RADIOErrorTpl">
              <nz-radio-group formControlName="terms_RADIO">
                <label nz-radio nzValue="Yes">Yes</label>
                <label nz-radio nzValue="No">No</label>
              </nz-radio-group>
              <ng-template #terms_RADIOErrorTpl let-control>
                <ng-container *ngIf="hasTermsAcceptedError()"
                  >The acceptance of terms is required</ng-container
                >
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <button nz-button nzType="primary" type="submit">Submit</button>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
    </div>
  </div>
</div>
