<app-layout-page-header>
  <div class="row row-space-between row-bottom">
    <div>
      <app-layout-page-header-title
        title="Admin Home"></app-layout-page-header-title>
    </div>
    <div>
      <div class="buttons row end">
        <app-genai-settings></app-genai-settings>
        <app-documentation></app-documentation>
      </div>
    </div>
  </div>
</app-layout-page-header>

<div class="layout-body">
  <div class="layout-body-content">
    <div nz-row [nzGutter]="16">
      <div nz-col class="gutter-row" [nzSpan]="4">
        <ul nz-menu nzMode="inline">
          <li nz-menu-item nz-tooltip nzTooltipPlacement="right" nz-selected>
            <veolia-link
              [size]="'default'"
              [icon]="'home'"
              [iconPosition]="'left'"
              (click)="redirectToAdmin()">
              Dashboard
            </veolia-link>
          </li>
          <li nz-menu-item nz-tooltip nzTooltipPlacement="right">
            <veolia-link
              [size]="'default'"
              [icon]="'fact_check'"
              [iconPosition]="'left'"
              (click)="redirectToSolutionsAdmin()">
              Solutions
            </veolia-link>
          </li>
          <li nz-menu-item nz-tooltip nzTooltipPlacement="right">
            <veolia-link
              [size]="'default'"
              [icon]="'apartment'"
              [iconPosition]="'left'"
              (click)="redirectToBusinessUnitsAdmin()">
              Business units
            </veolia-link>
          </li>
          <li nz-menu-item nz-tooltip nzTooltipPlacement="right">
            <veolia-link
              [size]="'default'"
              [icon]="'chevron_right'"
              [iconPosition]="'left'"
              (click)="redirectToPromptsAdmin()">
              Prompts
            </veolia-link>
          </li>
          <li nz-menu-item nz-tooltip nzTooltipPlacement="right">
            <veolia-link
              [size]="'default'"
              [icon]="'supervisor_account'"
              [iconPosition]="'left'"
              (click)="redirectToUsersAdmin()">
              Users
            </veolia-link>
          </li>
        </ul>
      </div>
      <div nz-col class="gutter-row spacing-v8 spacing-b16" [nzSpan]="20">
        <nz-row nz-row nzJustify="space-between">
          <nz-col [nzSpan]="8">
            <nz-card>
              <nz-statistic
                [nzValue]="(allSolutionsCounterSaaS | number)!"
                [nzTitle]="'Solutions'"></nz-statistic>
            </nz-card>
          </nz-col>
          <nz-col [nzSpan]="8">
            <nz-card>
              <nz-statistic
                [nzValue]="(allBusinessUnitsCounter | number)!"
                [nzTitle]="'Business units'"></nz-statistic>
            </nz-card>
          </nz-col>
          <nz-col [nzSpan]="8">
            <nz-card>
              <nz-statistic
                [nzValue]="
                  ((allVeoliaUsersCounter ?? 0) + (allVendorUsersCounter ?? 0)
                    | number)!
                "
                [nzTitle]="'Users'">
              </nz-statistic>
            </nz-card>
          </nz-col>
        </nz-row>
        <div nz-row nzJustify="space-between">
          <!--Solutions section-->
          <nz-card
            nz-col
            [nzXXl]="8"
            [nzXl]="8"
            [nzLg]="12"
            [nzMd]="12"
            [nzSm]="12"
            [nzXs]="24"
            nzTitle="Solutions"
            [nzExtra]="solutions">
            <div class="row center spacing-v16">
              <canvas
                baseChart
                [type]="'pie'"
                [datasets]="solutionPieChartDatasets"
                [labels]="solutionPieChartLabels"
                [options]="pieChartOptions"
                [plugins]="pieChartPlugins"
                [legend]="pieChartLegend">
              </canvas>
            </div>
            <div class="row center">
              <!--SaaS-->
              <ul nz-list nzBordered>
                <nz-list-header class="center">
                  <h3 class="center">SaaS</h3>
                </nz-list-header>
                <hr />
                <li nz-list-item nzNoFlex>
                  Total:<span nz-typography
                    ><kbd>{{ allSolutionsCounterSaaS }}</kbd></span
                  >
                </li>
                <li nz-list-item nzNoFlex>
                  Assessed:<span nz-typography
                    ><kbd>{{ allSolutionsAssessedCounterSaaS }}</kbd></span
                  >
                </li>
                <li nz-list-item nzNoFlex>
                  Pending:<span nz-typography
                    ><kbd>{{ allSolutionsPendingCounterSaaS }}</kbd></span
                  >
                </li>
                <li nz-list-item nzNoFlex>
                  New:<span nz-typography
                    ><kbd>{{ allSolutionsNewCounterSaaS }}</kbd></span
                  >
                </li>
                <li nz-list-item nzNoFlex>
                  In progress:<span nz-typography
                    ><kbd>{{ allSolutionsInProgressCounterSaaS }}</kbd></span
                  >
                </li>
                <li nz-list-item nzNoFlex>
                  Canceled:<span nz-typography
                    ><kbd>{{ allSolutionsCanceledCounterSaaS }}</kbd></span
                  >
                </li>
              </ul>
            </div>
          </nz-card>
          <ng-template #solutions>
            <veolia-icon [size]="24" [name]="'fact_check'"></veolia-icon>
          </ng-template>

          <!--Business units section-->
          <nz-card
            nz-col
            [nzXXl]="8"
            [nzXl]="8"
            [nzLg]="12"
            [nzMd]="12"
            [nzSm]="12"
            [nzXs]="24"
            nzTitle="Business units"
            [nzExtra]="businessUnits">
            <div class="row center spacing-v16">
              <canvas
                baseChart
                [type]="'pie'"
                [datasets]="buPieChartDatasets"
                [labels]="buPieChartLabels"
                [options]="pieChartOptions"
                [plugins]="pieChartPlugins"
                [legend]="pieChartLegend">
              </canvas>
            </div>
            <div class="row center">
              <!--SaaS-->
              <ul nz-list nzBordered>
                <nz-list-header class="center">
                  <h3 class="center">Business Units</h3>
                </nz-list-header>
                <hr />
                <li nz-list-item nzNoFlex>
                  Total:<span nz-typography
                    ><kbd>{{ allBusinessUnitsCounter }}</kbd></span
                  >
                </li>
              </ul>
            </div>
          </nz-card>
          <ng-template #businessUnits>
            <veolia-icon [size]="24" [name]="'apartment'"></veolia-icon>
          </ng-template>

          <!--Users section-->
          <nz-card
            nz-col
            [nzXXl]="8"
            [nzXl]="8"
            [nzLg]="12"
            [nzMd]="12"
            [nzSm]="12"
            [nzXs]="24"
            nzTitle="Users"
            [nzExtra]="users">
            <div class="row center spacing-v16">
              <canvas
                baseChart
                [type]="'pie'"
                [datasets]="userPieChartDatasets"
                [labels]="userPieChartLabels"
                [options]="pieChartOptions"
                [plugins]="pieChartPlugins"
                [legend]="pieChartLegend">
              </canvas>
            </div>

            <div class="row center">
              <!--Veolia users-->
              <ul nz-list nzBordered>
                <nz-list-header class="center">
                  <h3 class="center">Veolia users</h3>
                </nz-list-header>
                <hr />
                <li nz-list-item nzNoFlex>
                  Total:<span nz-typography
                    ><kbd>{{ allVeoliaUsersCounter }}</kbd></span
                  >
                </li>
              </ul>
              <!--Vendor users-->
              <ul nz-list nzBordered>
                <nz-list-header class="center">
                  <h3 class="center">Vendor users</h3>
                </nz-list-header>
                <hr />
                <li nz-list-item nzNoFlex>
                  Total:<span nz-typography
                    ><kbd>{{ allVendorUsersCounter }}</kbd></span
                  >
                </li>
              </ul>
            </div>
          </nz-card>
          <ng-template #users>
            <veolia-icon
              [size]="24"
              [name]="'supervisor_account'"></veolia-icon>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
