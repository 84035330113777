<div class="row row-space-between row-vertical-center">
  <div class="row row-vertical-center row-space-between">
    <div class="row">
      <veolia-icon
        [size]="24"
        [filled]="false"
        name="file_present"></veolia-icon>
      <div>
        <div class="file-item__file-name">
          <strong>{{ file!.name! }}</strong>
        </div>
        <div class="file-item__file-info">
          Posted by
          <a href="mailto:{{ file!.postedBy! }}">{{ file!.postedBy! }}</a>
          {{ file!.postedAt! | date }} -
          <strong>{{ file!.postedAt! | date: 'HH:mm' }}</strong>
        </div>
      </div>
    </div>
    <div>
      <a [href]="file!.url!" target="_blank" class="row">
        <veolia-button
          (click)="goToLink(file!)"
          [size]="'small'"
          [shape]="'default'"
          [variant]="'primary'"
          [htmlType]="'button'"
          [icon]="'download'">
        </veolia-button>
      </a>
    </div>
  </div>
</div>
